import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

type Props = {
    history: any,
};

type State = {
};

class TermsOfService extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: undefined,
        }
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>Termini di servizio</IonTitle> {/* TO LOCALIZE THIS */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="userGrid">
                    <h2>[Aggiornato il 18 settembre 2021 alle 7:00 pm GMT]</h2>
                    <p>
                        App.gedu.it ("GEDU", "Sito", "App", "Estro", "Estro technologies s.r.l.", "Piattaforma", "Software" o "Noi") è lieta di fornirti 
                        l’accesso alla Piattaforma Gedu, software di attività educative.
                        Le funzionalità della Piattaforma vengono qui definite come “Servizi” e possono 
                        essere di volta in volta modificate e/o aggiornate a nostra esclusiva discrezione.<br/>
                        Ti invitiamo a leggere con attenzione i presenti Termini e Condizioni d’uso (il “Contratto” 
                        o “Accordo”) prima di utilizzare i Servizi. Utilizzando i Servizi accetti integralmente le 
                        previsioni del presente Contratto.<br/>
                    </p>
                    <br />

                    <p>
                        LEGGERE ATTENTAMENTE IL PRESENTE CONTRATTO E CONTROLLARE PERIODICAMENTE QUESTI TERMINI E 
                        CONDIZIONI DI UTILIZZO PER VERIFICARE GLI EVENTUALI CAMBIAMENTI CHE VENISSERO EFFETTUATI. 
                        SE NON ACCETTI I PRESENTI TERMINI E CONDIZIONI, NON POTRAI UTILIZZARE I SERVIZI.
                    </p>
                    <br />

                    <p>
                        La nostra Informativa sulla privacy, che spiega come utilizziamo i tuoi dati personali, si 
                        trova all’indirizzo <a href='/legal/privacy-policy'>https://app.gedu.it/legal/privacy-policy</a> ed 
                        è considerata parte integrante e sostanziale del presente Accordo. Utilizzando i Servizi, 
                        accetti che le tue informazioni possano essere raccolte e utilizzate in conformità alla nostra 
                        Informativa sulla privacy ed al presente Accordo.
                    </p>
                    <br/>
                    <br/>

                    <h2>1. Condizioni generali</h2>
                    <h3>1.1.Account.</h3>
                    <p>
                        È necessario un account Gedu per sfruttare appieno i Servizi. L’account può essere 
                        creato direttamente compilando il relativo modulo. Accetti di fornire solo informazioni accurate 
                        e veritiere in relazione alla creazione del tuo account e di aggiornare le tue informazioni se 
                        necessario in modo che rimangano sempre accurate e aggiornate. Non è possibile trasferire il 
                        proprio account a terzi. Sei responsabile di mantenere riservate le informazioni di accesso al 
                        tuo account e di tutte le attività che si verificano sotto il tuo account. Accetti di avvisarci 
                        immediatamente se sospetti o vieni a conoscenza di qualsiasi utilizzo non autorizzato del tuo 
                        account o di altre violazioni della sicurezza con riferimento ai Servizi.
                    </p>
                    <h3>1.2.Accesso.</h3>
                    <p>
                        Possiamo, a nostra esclusiva discrezione, rifiutare di offrire i Servizi a qualsiasi soggetto, 
                        persona fisica o giuridica. In conformità al presente Accordo ed alla Sezione 8 che segue, 
                        possiamo far cessare il diritto di utilizzare i Servizi o parte di essi e bloccare o impedire 
                        l’accesso e l’utilizzo futuri dei Servizi o di parte di essi.
                    </p>

                    <h3>1.3.Minori.</h3>
                    <p>
                        I SERVIZI NON SONO PREVISTI PER I BAMBINI MINORI DI 13 ANNI: i soggetti di età inferiore ai 13 
                        anni non possono registrarsi né utilizzare i Servizi. Dichiari e garantisci di avere più di 18 anni 
                        o, se hai più di 13 anni e meno di 18, dichiari e garantisci di essere un minorenne emancipato, o 
                        di possedere il consenso legale dei genitori o dei tutori, e di essere pienamente in grado e 
                        competente al fine di rispettare e far rispettare i termini, le condizioni, gli obblighi, le 
                        dichiarazioni e le garanzie di cui al presente Accordo. La presente clausola è nulla ove vietata 
                        dalla legge e il diritto di accedere ai Servizi è revocato nelle giurisdizioni ove tale divieto 
                        sia vigente.
                    </p>
                    <br />

                    <h2>2. Acquisti e Rappresentazioni</h2>
                    <p>
                        L’UTENTE RAPPRESENTA E GARANTISCE DI AVERE IL DIRITTO LEGALE DI UTILIZZARE  
                        QUALSIASI CONTO BANCARIO O CARTA DI CREDITO, CHE SI UTILIZZA PER PAGARE I SERVIZI. Accetti 
                        di pagare tutte le spese sostenute da te o da qualsiasi utente del tuo account ai prezzi in 
                        vigore quando tali spese sono sostenute. Sarai inoltre responsabile del pagamento di eventuali 
                        imposte applicabili relative ai tuoi acquisti. La verifica delle informazioni applicabili a 
                        un acquisto può essere richiesta prima della nostra accettazione di qualsiasi ordine.
                    </p>
                    <br />

                    <h2>3. Proprietà del Contenuto di Gedu</h2>
                    <h3>3.1.Contenuti.</h3>
                    <p>
                        Utilizzando i Servizi, fruirai dei "Contenuti di Gedu", che includono tutte le immagini, 
                        testo, informazioni, dati, audio, video, grafica e altro materiale inclusi o resi disponibili 
                        attraverso i Servizi. Tra i contenuti di Gedu vi è anche il “Contenuto dell’Utente”, 
                        che comprende tutti i video, le immagini, l’audio ed il contenuto correlati, nonché i commenti 
                        dell’Utente, dallo stesso caricati, come meglio precisato alla Sezione 4 che segue.
                    </p>
                    <h3>3.2.Proprietà.</h3>
                    <p>
                        L’intero contenuto di Gedu è di proprietà di Estro technologies s.r.l. ed è protetto dalle leggi 
                        applicabili italiane, europee e internazionali sul copyright, sui marchi e brevetti, sulla 
                        proprietà intellettuale e industriale in generale e sul diritto di proprietà. Tra l’Utente, 
                        Gedu ed i suoi eventuali licenziatari, Gedu e/o i suoi licenziatari possiedono e 
                        mantengono, esclusivamente, tutti i diritti, il titolo e l’interesse nei e sui Servizi e su tutti 
                        i Contenuti di Gedu messi a disposizione dell’Utente tramite i Servizi, inclusi, a titolo 
                        esemplificativo e non esaustivo, tutti i diritti d’autore, i diritti sui marchi, i diritti segreti 
                        commerciali, i diritti sui brevetti, i diritti sui database e altri diritti di proprietà 
                        intellettuale e di proprietà. Il marchio "Gedu" e tutti i loghi associati e gli altri 
                        marchi, marchi di servizio, loghi e nomi commerciali visualizzati su, o in connessione con, i 
                        Servizi sono marchi e marchi di servizio registrati e non registrati di Estro technologies s.r.l., o 
                        di eventuali terzi, in Italia e/o in altri paesi.<br />
                        Il tuo utilizzo dei Servizi non garantisce alcuna proprietà su alcun Contenuto di Gedu e, 
                        fatta eccezione per la licenza limitata di utilizzo che ti concediamo ai sensi del Paragrafo 3.3 
                        del presente Accordo, la tua fruizione dei Servizi non comporta alcuna licenza né autorizzazione 
                        in relazione a copyright, marchi, brevetti o altra proprietà intellettuale, o diritti di proprietà 
                        di Estro technologies s.r.l. o di eventuali terzi. Ci riserviamo pertanto di esercitare tutti i diritti 
                        non espressamente concessi all’Utente tramite il presente Accordo.
                    </p>
                    <h3>3.3.Licenza di mero utilizzo e contenuto.</h3>
                    <p>
                        Ti garantiamo una licenza limitata, non esclusiva, non concedibile in sub-licenza e non trasferibile ad alcun 
                        titolo per utilizzare i Servizi così come sono forniti da noi, nei limiti di quanto stabilito dal presente Accordo.
                        Salvo ove espressamente consentito nel presente Accordo, è severamente vietato riprodurre, distribuire, adattare,
                        modificare, tradurre, creare opere derivate, pubblicare o utilizzare in altro modo qualsiasi parte dei Servizi o
                        dei Contenuti di Gedu per qualsivoglia scopo senza l’espressa autorizzazione scritta da parte nostra e/o del titolare
                        dei diritti applicabili. È altresì severamente vietato qualsiasi sfruttamento commerciale dei Servizi e/o dei 
                        Contenuti di Gedu senza l’espressa autorizzazione scritta da parte nostra e/o del titolare dei diritti applicabili.
                    </p>
                    <h3>3.4.Software.</h3>
                    <p>
                        Il software e il software as-a-service (SAAS) utilizzati per, ed in connessione con, i Servizi 
                        (“Software”) sono interamente di proprietà nostra o di terzi e, fatta eccezione esclusivamente 
                        per quanto possa essere richiesto per esercitare la concessione della licenza di utilizzo di cui 
                        al Paragrafo 3.3 che precede, sono espressamente vietate la vendita, la decompilazione, il 
                        reverse engineering, lo smontaggio, la traduzione e la riduzione di tale Software in una 
                        qualsivoglia forma leggibile dall’uomo. Accetti che possiamo aggiornare il Software senza 
                        preavviso, in qualsiasi momento e a nostra esclusiva discrezione, e che il presente Accordo 
                        si applicherà a tutte le versioni aggiornate.
                    </p>
                    <br />

                    <h2>4. Contenuto dell’Utente</h2>
                    <p>
                        Durante l’utilizzo dei Servizi, gli Utenti possono caricare determinati Contenuti dell’Utente, 
                        ivi inclusi video e immagini. L’Utente rimane sempre il proprietario dei suoi contenuti e 
                        Gedu non rivendica alcun diritto di proprietà su di essi. Il Contenuto dell’Utente è 
                        soggetto alle disposizioni della presente Sezione 4. Il presente Accordo si applica a tutti 
                        i Contenuti dell’Utente forniti dall’Utente stesso sul nostro sito Web e/o Applicazioni 
                        Android e/o iOS durante l’intero periodo di validità del presente Accordo.
                    </p>
                    <h3>4.1.In generale.</h3>
                    <p>
                        L’Utente è il solo ed esclusivo responsabile di garantire che i Contenuti dell’Utente inseriti 
                        tramite la Piattaforma siano conformi alle leggi applicabili e non violino diritti di terzi, 
                        tra cui – a mero titolo esemplificativo e non esaustivo - diritti di proprietà intellettuale, 
                        diritti alla privacy e diritti di pubblicità. Accetti che qualsiasi informazione inclusa nel 
                        Contenuto dell’Utente possa essere utilizzata in conformità alla nostra Informativa sulla 
                        privacy. Gedu ha sempre il diritto, a sua esclusiva discrezione, di accettare o rifiutare 
                        qualsiasi Contenuto dell’Utente.
                    </p>
                    <h3>4.2.Licenza di utilizzo dei Contenuti dell’Utente.</h3>
                    <p>Utilizzo da parte nostra.</p>
                    <p>
                        Fruendo dei Servizi, concedi a Gedu il diritto e la licenza di utilizzo in tutto il mondo, 
                        non esclusivi, esenti da royalty, perpetui, irrevocabili, sub-licenziabili (attraverso più livelli) 
                        al fine di riprodurre, visualizzare pubblicamente, distribuire e adattare il Contenuto dell’Utente 
                        per lo svolgimento dei Servizi medesimi e per finalità di sviluppo, miglioramento e promozione degli 
                        stessi. Concedi inoltre a Gedu il diritto di utilizzare il tuo nominativo ed i tuoi marchi, 
                        ove esistenti, nei limiti e nell’ambito delle predette finalità ed in conformità alla normativa 
                        privacy applicabile.
                    </p>
                    <h3>4.3.Dichiarazioni e garanzie dell’Utente.</h3>
                    <p>Inviando i Contenuti dell’Utente, dichiari e garantisci che:</p>
                    <ul>
                        
                        <li>
                            Possiedi o detieni legittimamente in altro modo tutti i diritti sui Contenuti dell’Utente;
                        </li>

                        <li>
                            L’uso dei Contenuti dell’Utente non viola il presente Accordo, la legge applicabile e non viola 
                            né violerà alcun diritto di terzi, né creerà agli stessi alcun pregiudizio;
                        </li>

                        <li>
                            I Contenuti dell’Utente non violano, in particolare, le disposizioni di cui alla Sezione 5 del 
                            presente Accordo;
                        </li>
                    </ul>

                    <h3>4.4.Feedback e segnalazioni dell’Utente.</h3>
                    <p>
                        Apprezziamo il tuo feedback ed i tuoi eventuali suggerimenti sui nostri Servizi, che sono 
                        interamente volontari e che - ove vengano da te forniti - saremo liberi di utilizzare con le 
                        modalità che riterremo opportune e senza alcun obbligo nei tuoi confronti.
                    </p>

                    <h3>4.5.Diritto di monitorare, moderare o rimuovere.</h3>
                    <p>
                        Accetti di assumere tutti i rischi correlati ai Contenuti dell’Utente. L’Utente è il solo 
                        responsabile della salvaguardia del Contenuto dell’Utente e Gedu non ha l’obbligo di 
                        archiviare copie del Contenuto dell’Utente per la futura disponibilità da parte dell’Utente stesso 
                        o di qualsiasi altro utente, salvo ove diversamente previsto dal presente Accordo. Gedu 
                        rimuoverà i Contenuti dell’Utente dai Servizi qualora tali Contenuti dell’Utente violino i diritti 
                        di proprietà intellettuale altrui o qualsivoglia altro diritto nostro o di terzi sancito dalla 
                        normativa applicabile. Ci riserviamo altresì il diritto di rimuovere i Contenuti dell’Utente dai 
                        Servizi, in tutto o in parte, senza preavviso, a nostra esclusiva discrezione. Senza limitare il 
                        nostro diritto di chiudere l’account di un Utente ai sensi della Sezione 8 del presente Accordo, 
                        ci riserviamo il diritto di chiudere l’account di qualsiasi Utente dei Servizi a cui sia stata 
                        attribuita l’attività di violazione.<br />
                        Ci riserviamo, inoltre, il diritto di decidere se il Contenuto dell’Utente sia appropriato e 
                        conforme al presente Accordo anche in caso di violazione di normativa diversa dalla legge sulla 
                        proprietà intellettuale. Ciò consentirà a Gedu di modificare, moderare, riformattare, 
                        cambiare o altrimenti rimuovere tutte o parte delle descrizioni e hashtag che tu e / o terze parti 
                        aggiungerete e / o inserirete in relazione al Contenuto dell’Utente, ogni volta che lo riterremo 
                        appropriato.
                    </p>
                    <br />

                    <h3>5. Limitazioni e obblighi dell’Utente</h3>
                    <p>
                        Per noi è importante che i Servizi vengano utilizzati in modo sicuro e in conformità alla legge, per il 
                        godimento di tutti gli Utenti. Accetti di non utilizzare i Servizi per:
                    </p>
                    <ul>
                        <li>
                            Decompilare, disassemblare, decodificare, copiare, trasferire o altrimenti utilizzare i 
                            Servizi, i Contenuti di Gedu e i Contenuti dell’Utente salvo quanto consentito dal 
                            presente Accordo;
                        </li>

                        <li>
                            Promuovere qualsiasi attività illegale, o sostenere, promuovere o supportare qualsiasi atto 
                            illecito;
                        </li>
                        
                        <li>
                            Violare i diritti legali (compresi i diritti di pubblicità e privacy) di altri o contenere 
                            qualsiasi materiale che possa dar luogo a responsabilità civile o penale ai sensi delle leggi 
                            o dei regolamenti applicabili o che altrimenti potrebbe essere in conflitto con il presente 
                            Accordo e/o la nostra Informativa sulla privacy;
                        </li>

                        <li>
                            Trasmettere qualsiasi materiale o contenuto pornografico, minaccioso, molesto, calunnioso, 
                            orientato all’odio, dannoso, diffamatorio, razzista, xenofobo o, comunque, illegale;
                        </li>

                        <li>
                            Trasmettere qualsiasi materiale o contenuto che tenti di dichiarare falsamente o altrimenti 
                            travisare la tua identità o affiliazione con una persona o entità;
                        </li>

                        <li>
                            Trasmettere materiale o contenuti che promuovono, forniscono o si riferiscono a informazioni 
                            istruttive su attività illegali o promuovono danni fisici a persone o gruppi;
                        </li>
                        
                        <li>
                            Trasmettere o altrimenti rendere disponibile qualsiasi pubblicità non richiesta o non 
                            autorizzata, materiale promozionale, “posta indesiderata”, “spam”, “lettere a catena”, 
                            “schemi piramidali” o qualsiasi altra forma di sollecitazione;
                        </li>

                        <li>
                            Trasmettere o incoraggiare la trasmissione di qualsiasi materiale che possa violare i diritti 
                            di proprietà intellettuale o altri diritti di terzi, inclusi marchi, copyright, brevetti o 
                            diritti di pubblicità, o che costituisce o promuove in altro modo materiali o merci contraffatti;
                        </li>

                        <li>
                            Utilizzare i Servizi in modo tale da:
                        </li>
                        <ul>
                            <li>
                                interrompere, sospendere, rallentare o ostacolare la continuità dei Servizi;
                            </li>

                            <li>
                                costituire un’intrusione o tentare di entrare nei Servizi o nei sistemi informatici 
                                o di rete di Gedu;
                            </li>

                            <li>
                                dirottare le risorse di sistema dei Servizi,
                            </li>

                            <li>
                                esercitare un carico sproporzionato sull’infrastruttura dei Servizi;
                            </li>

                            <li>
                                costituire un attacco alle misure di sicurezza e di autenticazione dei 
                                Servizi o dei sistemi informatici o di rete di Gedu;
                            </li>

                            <li>
                                intrometterti nel sistema informatico di una terza parte, intraprendere qualsiasi 
                                attività che possa danneggiare, controllare, interferire con o intercettare tutto o 
                                parte del, sistema informatico di una terza parte e/o violare la sua integrità o sicurezza, 
                                oppure trasmettere in altro modo materiali o contenuti dannosi per sistemi terzi di 
                                informazione delle parti (inclusi ma non limitati a virus, worm, Trojan);
                            </li>

                            <li>
                                utilizzare i Servizi per scopi diversi da quelli per i quali sono stati progettati e 
                                sviluppati.
                            </li>
                        </ul>
                    </ul>

                    <p>
                        All’Utente non è consentito in alcun modo modificare il design o alterare l’aspetto della UI di 
                        Gedu (ad es. tramite incorporamenti o acquisizioni di schermate), se non utilizzando le 
                        opzioni di personalizzazione fornite da Gedu e in base al piano dell’Utente. La 
                        rimozione, oppure il nascondere o l’alterare la filigrana del logo Gedu è vietata a 
                        tutti gli Utenti.
                    </p>

                    <p>
                        Accetti di informare prontamente Gedu di qualsiasi reclamo o azione legale relativo al 
                        Contenuto dell’Utente che hai caricato utilizzando i Servizi.
                    </p>
                    <br />

                    <h2>6. Responsabilità, indennità e ruolo di Gedu</h2>
                    <h3>6.1.La nostra responsabilità.</h3>
                    <p>
                        Faremo del nostro meglio per assicurare che l'accesso ai Servizi sia fornito senza interruzioni 
                        e che le trasmissioni avvengano senza errori. Tuttavia, a causa della natura di Internet, 
                        l'accesso ininterrotto e l'assenza di errori nella trasmissione non possono essere garantiti. 
                        Inoltre, il tuo accesso ai Servizi potrebbe anche essere occasionalmente sospeso o limitato per 
                        consentire l'effettuazione di lavori di riparazione, manutenzione, o l'introduzione di nuove 
                        attività o servizi. Tenteremo di limitare la frequenza e la durata di queste sospensioni e 
                        limitazioni.<br />
                        Gedu non sarà responsabile: (a) per perdite che non siano conseguenza della nostra violazione 
                        del presente Accordo; (b) per ogni perdita di opportunità commerciale (inclusi eventuali spese 
                        inutilmente sostenute); o (c) qualsiasi altra perdita anche indiretta o conseguente, che non fosse 
                        stata ragionevolmente prevedibile, sia da te che da noi, nel momento in cui hai iniziato ad utilizzare i Servizi.
                    </p>

                    <p>ESCLUSIONE DI GARANZIE.</p>
                    <p>
                    Gedu si impegna a fornire i Servizi diligentemente e professionalmente e non rilascia 
                        garanzie di alcun tipo in merito ai Servizi o al Contenuto Gedu, salvo ove eventualmente 
                        diversamente specificato dal presente Contratto.
                    </p>

                    <p>
                        PRENDI ATTO E CONVIENI ESPRESSAMENTE CHE IL TUO UTILIZZO DEI SERVIZI È A TUO RISCHIO. I SERVIZI 
                        SONO FORNITI “COSÌ COME SONO” E “QUANDO DISPONIBILI” PER IL TUO UTILIZZO, SENZA GARANZIE DI 
                        ALCUNA NATURA, ESPRESSE O IMPLICITE, SALVO OVE TALI GARANZIE SIANO IMPOSTE DALLA LEGGE.
                    </p>

                    <p>
                        Nessun suggerimento o informazione ottenuta da Gedu, o in altro modo attraverso i Servizi, 
                        potrà essere interpretata come una garanzia non espressamente prevista nel presente Accordo.
                    </p>

                    <h3>6.2.Indennità.</h3>
                    <p>
                        Accetti di indennizzare e tenere indenne Gedu, Estro technologies s.r.l. ed i suoi eventuali soci, 
                        licenziatari, dipendenti e agenti da e contro qualsiasi rivendicazione, richiesta, causa, 
                        procedimento, responsabilità, giudizio, perdita, danno, nonché da spese e costi (incluse, senza 
                        limitazione alcuna, le spese legali) preventivate o sostenute da Estro technologies s.r.l. direttamente o 
                        indirettamente, in relazione a, o derivanti da:
                    </p>
                    <ul>
                        <li>
                            Inadempienza al presente Accordo;
                        </li>

                        <li>
                            Violazione degli obblighi previsti dal presente Accordo;
                        </li>

                        <li>
                            Esercizio dei diritti di seguito previsti, inclusi, a titolo esemplificativo, eventuali 
                            reclami da parte di terzi;
                        </li>

                        <li>
                            Violazione di qualsiasi diritto di terzi, inclusi – a mero titolo esemplificativo e non 
                            esaustivo - diritti di proprietà intellettuale, diritto di pubblicità o diritto alla privacy;
                        </li>

                        <li>
                            Qualsivoglia rivendicazione a fronte di danni a terzi causati da un qualsiasi Contenuto 
                            dell’Utente.
                        </li>
                    </ul>

                    <h3>6.3.Il ruolo di Gedu.</h3>
                    <p>
                        Gedu consente alle persone di utilizzare attività digitali educative sulla Piattaforma app.gedu.it. 
                    </p>
                    <br />

                    <h2>7. Segnalazioni sulla proprietà intellettuale</h2>
                    <p>
                        Gedu rispetta i diritti di proprietà intellettuale di terzi e rispetta le disposizioni 
                        normative in materia.
                    </p>
                    <p>
                        Se ritieni che i tuoi diritti di proprietà intellettuale o diritti di terzi siano stati utilizzati 
                        in un modo tale da dare luogo a possibili violazioni, contattaci per effettuare una segnalazione di 
                        violazioni dei diritti di proprietà intellettuale, al seguente indirizzo:
                    </p>
                    <p>
                        Estro technologies s.r.l.<br />
                        Via Savona 123<br />
                        20144 Milano (MI)<br/>
                        Italy
                    </p>

                    <p>
                        Per essere efficace, qualsiasi segnalazione come sopra inviata deve rispettare i requisiti stabiliti dalle disposizioni in materia di copyright, e deve includere sostanzialmente quanto segue:
                    </p>
                    <ul>
                        <li>
                            Una firma fisica o elettronica di una persona autorizzata ad agire per conto del titolare di un 
                            diritto esclusivo presumibilmente violato;
                        </li>

                        <li>
                            Identificazione dell’opera protetta da copyright che si ritiene sia stata violata o, se più 
                            opere protette da copyright in un singolo sito online sono coperte da un’unica notifica, un elenco 
                            rappresentativo di tali opere in quel sito;
                        </li>
                        
                        <li>
                            Identificazione del materiale che si ritiene sia in violazione o che sia oggetto di attività illecita e 
                            che debba essere rimosso o al quale si debba disabilitare l’accesso e informazioni ragionevolmente 
                            sufficienti per consentirci di individuare il materiale;
                        </li>

                        <li>
                            Una dichiarazione in cui si ritiene in buona fede che l’uso del materiale nel modo in cui è stato 
                            contestato non è autorizzato dal titolare del copyright, dal suo agente o dalla legge;
                        </li>

                        <li>
                            Una dichiarazione attestante che le informazioni contenute nella segnalazione sono esatte e, a 
                            pena di falso, che l’Utente sia autorizzato ad agire per conto del titolare di un diritto 
                            esclusivo presumibilmente violato.
                        </li>
                    </ul>
                    
                    <p>
                        Solo dopo aver ricevuto una segnalazione che contenga tutti gli elementi sostanziali sopra elencati, 
                        contatteremo l’Utente che ha pubblicato i Contenuti dell’Utente presumibilmente in violazione, 
                        come indicato nella relativa segnalazione, e rimuoverà tempestivamente il Contenuto Utente 
                        presumibilmente in violazione. È nostra politica documentare tutte le segnalazioni di presunta 
                        violazione su cui agiamo. Una copia della segnalazione può essere inviata a una o più terze parti 
                        che possono renderla disponibile al pubblico. Si informa che è nostra politica chiudere gli account 
                        degli Utenti che violino il presente Accordo e / o la normativa sul Diritto D’Autore.
                    </p>

                    <p>
                        Se sei un Utente e il materiale che hai caricato sulla Piattaforma è stato rimosso o disabilitato, puoi 
                        presentare una contro-segnalazione. Per essere efficace, la contro-segnalazione deve essere costituita 
                        da una comunicazione scritta inviata all’indirizzo sopra riportato, che includa quanto segue:
                    </p>
                    <ul>
                        <li>
                            La tua firma fisica o elettronica;
                        </li>

                        <li>
                            L’identificazione del materiale che è stato rimosso o al quale l’accesso è stato disabilitato e 
                            la posizione in cui il materiale è apparso prima che fosse rimosso o che l’accesso ad esso fosse 
                            disabilitato;
                        </li>
                        
                        <li>
                            Una dichiarazione sotto pena di falso secondo cui si ritenga in buona fede che il materiale sia 
                            stato rimosso o disabilitato a seguito di errore o errata identificazione del materiale da 
                            rimuovere o disabilitare;
                        </li>

                        <li>
                            Se avessi una segnalazione relativa a marchi, brevetti, proprietà intellettuale o qualsiasi altra 
                            questione diversa dal diritto d’autore, ti preghiamo di contattarci all’indirizzo sopra indicato;
                        </li>

                        <li>
                            Informazioni dettagliate e qualsiasi informazione aggiuntiva che potrebbe essere necessaria per 
                            esaminare la questione e intraprendere eventuali azioni in maniera appropriata.
                        </li>
                    </ul>

                    <p>
                        Prendi atto ed accetti che possiamo condividere i dettagli della segnalazione con l’Utente titolare dei 
                        Contenuti dell’Utente oggetto della segnalazione o con le autorità competenti, qualora Gedu ritenesse - 
                        a sua esclusiva discrezione - che tale azione sia necessaria o se fossimo obbligati a farlo per legge.
                    </p>
                    <br />

                    <h2>8. Modifica e cessazione dei Servizi</h2>
                    <h3>8.1.In generale.</h3>
                    <p>
                        In qualsiasi momento Gedu si riserva il diritto di modificare o interrompere l’offerta dei 
                        Servizi in tutto o in parte, a propria discrezione, nel qual caso ti forniremo un (1) mese di 
                        preavviso.
                    </p>
                    <h3>8.2.Per determinati motivi.</h3>
                    <p>
                        Potremmo chiudere il tuo account Utente o far cessare il tuo diritto di accedere a tutti o parte 
                        dei Servizi in qualsiasi momento, senza preavviso, per comportamenti che riteniamo violino il 
                        presente Accordo e / o siano dannosi per altri Utenti, per Gedu, per altri fornitori di 
                        servizi o di informazioni, o per altri soggetti terzi.
                    </p>
                    <h3>8.3.Sopravvivenza.</h3>
                    <p>
                        A seguito della risoluzione (o cessazione per qualsiasi altro motivo) del presente Accordo e/o 
                        della fruizione dei Servizi, continueranno ad avere piena validità ed efficacia:
                    </p>
                    <ul>
                        <li>
                            Tutti gli obblighi ancora in sospeso tra te e noi al momento della risoluzione o cessazione;
                        </li>

                        <li>
                            Tutti i rimedi per violazione del presente Accordo;
                        </li>

                        <li>
                            Le seguenti Sezioni del presente Accordo: 3 (Proprietà del Contenuto Gedu); 4 
                            (Contenuto dell’Utente); 6 (Responsabilità, indennità e ruolo di Gedu); 8 
                            (Modifica e cessazione dei Servizi); 9 (Altri siti Web e servizi); 10 (Legge applicabile e 
                            foro competente); e 11 (Varie).
                        </li>
                    </ul>
                    <br />

                    <h2>9. Altri siti web e Servizi</h2>
                    <p>
                        I Servizi possono contenere collegamenti e funzionalità che consentono di accedere ad altri siti 
                        Web o servizi di terze parti (“Servizi di terze parti”), che non sono di nostra proprietà né sotto 
                        il nostro controllo. Tali servizi di terze parti sono regolati dai rispettivi termini di utilizzo. 
                        Non controlliamo i servizi di terze parti e non siamo responsabili dei contenuti di alcun sito 
                        collegato. Un collegamento non implica in alcun modo l’approvazione, la sponsorizzazione o 
                        l’affiliazione con i servizi di terze parti da parte di Gedu. Si prega di prestare attenzione 
                        prima di procedere con l’utilizzo dei servizi di terze parti o di stipulare transazioni con terze 
                        parti collegate ai Servizi. Gedu non può in alcun caso essere ritenuta responsabile per la 
                        disponibilità tecnica dei servizi di terze parti, per il contenuto, la pubblicità, i prodotti e / o 
                        i servizi disponibili sui servizi di terze parti o per qualsiasi transazione che abbia luogo tra un 
                        Utente e servizi di terze parti cui l’utente sia stato indirizzato tramite i Servizi. Gedu 
                        non può in alcun caso essere parte di controversie tra l’Utente e terze parti relative ai servizi di 
                        tali terze parti.
                    </p>
                    <br />

                    <h2>10. Legge applicabile e foro competente</h2>
                    <p>
                        Il presente Contratto è disciplinato e deve essere interpretato ai sensi delle italiane.<br />
                        Accettando i termini e le condizioni del presente Contratto, prendi atto e concordi espressamente 
                        che qualsiasi controversia relativa all’interpretazione, esecuzione e/o cessazione del presente 
                        Contratto sarà devoluta alla competenza esclusiva e inderogabile del Tribunale di Genova, 
                        con espressa esclusione di qualsivoglia altro Foro.
                    </p>
                    <br />

                    <h2>11. Varie</h2>
                    <p>
                        Il presente Accordo, unitamente all’Informativa sulla privacy, rappresenta l’intero accordo tra 
                        Gedu e l’Utente in relazione alla fornitura dei Servizi e supera e sostituisce le eventuali 
                        intese precedentemente intercorse, scritte o orali, relative all’oggetto dell’Accordo medesimo.<br/>
                        In caso di inadempimento da parte tua al presente Contratto, il mancato esercizio da parte nostra del 
                        diritto di agire nei tuoi confronti, non rappresenta una nostra rinuncia ad agire a seguito della 
                        violazione degli obblighi da te assunti.
                    </p>
                    <p>
                        Come già indicato nel presente Accordo, “incluso” significa “incluso ma non limitato a”.<br />
                        Se una qualsiasi disposizione del presente Accordo venisse giudicata non valida o inapplicabile da 
                        un tribunale della giurisdizione competente, l’Accordo rimarrà in vigore a tutti gli effetti e sarà 
                        modificato per essere valido e applicabile nel pieno rispetto delle intenzioni delle parti con 
                        riferimento alla disposizione giudicata non valida o inapplicabile.
                    </p>
                    <p>
                        Non è consentito all’Utente cedere o trasferire in alcun modo il presente Accordo o qualsiasi 
                        relativo diritto o obbligo a terzi.
                    </p>
                    <p>
                    Gedu può cedere liberamente il presente Accordo.<br/>
                        Accetti che il testo elettronico del presente Accordo costituisca una scrittura privata valida ed 
                        efficace tra le parti ed il tuo consenso ai termini e alle condizioni del presente Contratto 
                        costituisce una “firma” a tutti gli effetti.
                    </p>

                    <br />

                    <p>
                        Acconsenti a ricevere comunicazioni elettroniche da Gedu e accetti a nostra facoltà di comunicare con te tramite e-mail, SMS, WhatsApp o pubblicando avvisi sui Servizi. Accetti che tutti gli accordi, gli avvisi, le segnalazioni, le divulgazioni e le altre comunicazioni che ti forniamo o che ci fornisci, soddisfano elettronicamente qualsiasi requisito legale come se tali comunicazioni fossero scritte.
                        Non siamo responsabili per eventuali filtri automatici che possono essere applicati a qualsiasi avviso e-mail che inviamo all’indirizzo e-mail da te fornito.
                    </p>

                    <p>
                        I Servizi sono di proprietà di Estro technologies s.r.l. e sono dalla stessa società gestiti.<br />
                        Richieste di carattere generale possono essere indirizzate utilizzando uno dei contatti al seguente indirizzo:&nbsp;
                        <a href="https://estro.ai/contacts" target="_blank">contatti</a>.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    
                    
                    
                </IonGrid>
            </IonContent>
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
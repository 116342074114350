import { isPlatform } from "@ionic/react";
import { child, get, push, ref as dbRef, ref, remove, update } from 'firebase/database';
import { database } from "../../../common/firebase";
import { UserData } from "./reducers";
import { Device } from '@ionic-native/device';
import { store } from '../../../common/store';
import { Device as DeviceModel } from './reducers';
import { FCM } from "@capacitor-community/fcm";

export const userServices = {
    getUserData,
    addDevice,
    fetchDevices,
    editDeviceStatus,
    editDeviceName,
    deleteDevice,
};

function addDevice(fcmToken: string) {
    return new Promise<DeviceModel>(async (resolve, reject) => {

        const deviceModelToReturn: DeviceModel = {
            uuid: "temp",
            creationDate: Math.floor(new Date().getTime() / 1000),
            isActive: true,
            name: Device.model,
            registrationToken: fcmToken,
            type: 0,
        };

        if (isPlatform('ios')) {
            deviceModelToReturn.type = 1;
            deviceModelToReturn.deviceId = Device.uuid;

            try {
                const r = await FCM.getToken()
                deviceModelToReturn.registrationToken = r.token;
            }
            catch(e) {
                console.error("[user services] error getting fcm for ios:", e);
            }

        }
        else if (isPlatform('android')) {
            deviceModelToReturn.type = 2;
            deviceModelToReturn.deviceId = Device.uuid;
        }

        const deviceDbObj = {
            a: true,
            c: deviceModelToReturn.creationDate,
            n: deviceModelToReturn.name,
            r: deviceModelToReturn.registrationToken,
            t: deviceModelToReturn.type,
        };

        if (deviceModelToReturn.deviceId) {
            Object.assign(deviceDbObj, { d: deviceModelToReturn.deviceId });
        }

        push(dbRef(database, `d/${store.getState().auth.userData.uid}`), deviceDbObj)
            .then(response => {
                console.log('[addDevice] response', JSON.stringify(response));
                if (response.key) {
                    deviceModelToReturn.uuid = response.key;
                    resolve(deviceModelToReturn);
                }
                else {
                    reject("[user services] Response key is null");
                }
            })
            .catch(err => {
                console.error('[addDevice] error:', JSON.stringify(err));
                reject(err);
            })
    });
}

function fetchDevices(userUuid: string) {
    return new Promise<DeviceModel[]>((resolve, reject) => {
        const devicesArrayToReturn: DeviceModel[] = [];
        get(dbRef(database, `d/${userUuid}`))
            .then(snapshot => {
                if (snapshot.exists()) {
                    const devicesKeys = Object.keys(snapshot.val());
                    for (let i = 0; i < devicesKeys.length; i++) {
                        devicesArrayToReturn.push({
                            uuid: devicesKeys[i],
                            creationDate: snapshot.val()[devicesKeys[i]].c,
                            deviceId: snapshot.val()[devicesKeys[i]].d,
                            isActive: snapshot.val()[devicesKeys[i]].a,
                            name: snapshot.val()[devicesKeys[i]].n,
                            registrationToken: snapshot.val()[devicesKeys[i]].r,
                            type: snapshot.val()[devicesKeys[i]].t,
                        });
                    }
                }
                resolve(devicesArrayToReturn);
            })
            .catch(err => {
                console.error('[fetchDevices] error:', err);
                resolve([]);
            });
    })
}

function editDeviceStatus(deviceUuid: string, active: boolean) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        get(deviceDbRef)
            .then(snapshot => {
                if (snapshot.exists()) {
                    const deviceToReturn: DeviceModel = {
                        uuid: deviceUuid,
                        creationDate: snapshot.val().c,
                        deviceId: snapshot.val().d,
                        isActive: snapshot.val().a,
                        name: snapshot.val().n,
                        registrationToken: snapshot.val().r,
                        type: snapshot.val().t,
                    };

                    update(deviceDbRef, {
                        a: active,
                    })
                        .then(() => {
                            deviceToReturn.isActive = active;
                            resolve(deviceToReturn);
                        })
                        .catch(err => {
                            //console.log('[editDeviceStatus] error updating device status:', err);
                            resolve(deviceToReturn);
                        })
                }
                else {
                    resolve(null);
                }
            })
            .catch(err => {
                console.error('[editDeviceStatus] error getting device from db:', err);
                resolve(null);
            });
    })
}

function editDeviceName(deviceUuid: string, name: string) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        get(deviceDbRef)
            .then(snapshot => {
                if (snapshot.exists()) {
                    const deviceToReturn: DeviceModel = {
                        uuid: deviceUuid,
                        creationDate: snapshot.val().c,
                        deviceId: snapshot.val().d,
                        isActive: snapshot.val().a,
                        name: snapshot.val().n,
                        registrationToken: snapshot.val().r,
                        type: snapshot.val().t,
                    };

                    update(deviceDbRef, {
                        n: name,
                    })
                        .then(() => {
                            deviceToReturn.name = name;
                            resolve(deviceToReturn);
                        })
                        .catch(err => {
                            console.error("[editDeviceName] error updating device on db")
                            resolve(deviceToReturn);
                        })
                }
                else {
                    resolve(null);
                }
            })
            .catch(err => {
                console.error('[editDeviceName] error getting device from db:', err);
                resolve(null);
            });
    })
}

function deleteDevice(deviceUuid: string) {
    return new Promise((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        remove(deviceDbRef)
        .then(() => {
            resolve(true);
        })
        .catch(err => {
            console.error('[deleteDevice] error deleting device from db:', err);
            reject(err);
        });
    })
}


function getUserData(uid: string) {
    return new Promise<UserData>((resolve, reject) => {
        const dbUserDataRef = ref(database);
        get(child(dbUserDataRef, `u/${uid}`))
            .then(data => {
                console.log('[User services] getUserData data:', data);
                if (data.exists()) {
                    resolve({
                        email: data.val()["e"],
                        registrationTimestamp: data.val()["t"],
                        stripeCustomerId: data.val()["f"] && data.val()["f"]["y"] ? data.val()["f"]["y"] : undefined,
                    })
                }
                else {
                    resolve({
                        email: undefined,
                        registrationTimestamp: undefined,
                        stripeCustomerId: undefined
                    })
                }
            })
            .catch(err => {
                console.log('[User services] getUserData error:', err);
                reject(err);
            })
    })
}

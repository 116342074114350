import { httpsCallable } from '@firebase/functions';
import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonButton, IonLoading, isPlatform,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { auth, functions } from '../../../common/firebase';
import { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import "./Purchase.css";
import { store } from '../../../common/storeHandler';
import { purchasesActions } from '../store/actions';

declare class Stripe {
    constructor(...args: any[]);
    redirectToCheckout(...args: any[]): any;
}

//const stripe = new Stripe("pk_test_51Jb6VuJasdj1ggLrhB8O7LCkfa6ivtnkjxXHKarMJgW3YtwiS5ROrOJOMx7yK69XIp4RlNaPzTxgXEFJwpyHeffO00tNIqqz9t");
const stripe = new Stripe("pk_live_51Jb6VuJasdj1ggLr8hyLJtNE1a8fw8WF8AWDK8bvzo333BwBacQ250xwVg06lJPmkbnA4BOU0iIpAgLhV6lAJhJo00wWLJlG6v");

type Props = {
    history: any,

    purchaseError: () => void,
    paymentIsLoading: (isLoading: boolean) => void,
};

type State = {
    isLoadingCheckoutSession: boolean,
    couponId: string | undefined,
    geduFullIapProduct: IAPProduct | null,
};

class Purchase extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingCheckoutSession: false,
            couponId: undefined,
            /* couponId: "orcFfmMx", */
            geduFullIapProduct: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                
            }
            else {

            }
        });

        if (isPlatform("capacitor") || isPlatform("cordova")) {
            const geduFullIapProduct = store.get("it.gedu.app.full.nc");
            this.setState({ geduFullIapProduct: geduFullIapProduct });
        }
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle>
                        Acquista
                    </IonTitle> {/* TO BE LOCALIZED */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="purchaseMainGrid">

                    <h2 className="purchaseCtaTitleText">
                        Acquista Gedu {/* TO BE LOCALIZED */}
                    </h2>
                    {
                        (isPlatform("capacitor") || isPlatform("cordova")) &&
                        <div className="purchasePricingDiv">
                            a {this.state.geduFullIapProduct && this.state.geduFullIapProduct.price ? this.state.geduFullIapProduct.price : "€ 1,99"} {/* TO BE LOCALIZED */}
                        </div>
                    }

                    <p>
                        Con l'acquisto otterrai:
                    </p>
                    <ul>
                        <li>
                            Completa la figura e Conta la frutta 
                        </li>
                        <li>
                            I futuri livelli  dei due giochi
                        </li>
                        <li>
                            Tutti i giochi che verranno rilasciati in seguito nell'app 
                        </li>
                    </ul>

                    {/* <IonCard className="purchaseDiscountCard">
                        <IonCardHeader>
                            <IonCardTitle
                                style={{ textAlign: "center" }}
                            >
                                Per te che hai seguito il webinar
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className='purchaseDiscountDiv'>
                                <p className="purchaseDiscountEmojiParagraph">
                                    👀
                                </p>
                                <p className="purchaseDiscountTextParagraph">
                                    Il prezzo di GEDU è <b>1,99€</b> ma se effettui l'acquisto <u>entro le 21:00 di oggi</u> puoi <b>averlo a 1,49€</b>
                                </p>
                            </div>
                        </IonCardContent>
                    </IonCard> */}

                    <div className="purchaseButtonsAndTermsDiv">
                        <IonButton
                            className="purchaseButton"
                            onClick={() => {
                                if (isPlatform("capacitor") || isPlatform("cordova")) {
                                    store.order("it.gedu.app.full.nc")
                                    .then((e: any) => {
                                        console.log("[Purchase] purchase response:", e);
                                        this.props.paymentIsLoading(true);
                                        this.props.history.push("/processing-payment");
                                    })
                                    .error((e: any) => {
                                        console.error("[Purchase] purchase error:", e);
                                        this.props.purchaseError();
                                        this.props.history.push("/processing-payment");
                                    });
                                }
                                else {
                                    this.setState({ isLoadingCheckoutSession: true });
                                    const createCheckOutSession = httpsCallable(functions, 'createCheckoutSession-createCheckoutSession');
                                    createCheckOutSession({
                                        priceId: "price_1LACNAJasdj1ggLrISOBq2PF",
                                        coupon: this.state.couponId,
                                    })
                                    .then(res => {
                                        console.log('[FeatureManagerPopovers] createCheckOutSession success', res);
                                        console.log('[FeatureManagerPopovers] createCheckOutSession success. Session id:', (res.data as any).sessionId);
                                        stripe.redirectToCheckout({
                                            sessionId: (res.data as any).sessionId
                                        })
                                        .then((data: any) => {
                                            console.log('[FeatureManagerPopovers] checkout success redirection', data);
                                            this.setState({ isLoadingCheckoutSession: false });
                                        })
                                        .catch((err: any) => {
                                            console.log('[FeatureManagerPopovers] Err redirecting to checkout', err);
                                            this.setState({ isLoadingCheckoutSession: false });
                                        });
                                    })
                                    .catch(err => {
                                        console.error('[FeatureManagerPopovers] Error createCheckOutSession:', err);
                                        this.setState({ isLoadingCheckoutSession: false });
                                    });
                                }
                            }}
                        >
                            Acquista GEDU {(!isPlatform("capacitor") && !isPlatform("cordova")) ? "a €1,99" : ""} {/* TO BE LOCALIZED */}
                        </IonButton>

                        {
                            (isPlatform("capacitor") || isPlatform("cordova")) &&
                            <div
                                className="purchaseRestorePurchasesDiv"
                                onClick={() => {
                                    store.refresh();
                                }}
                            >
                                Ripristina acquisti {/* TO BE LOCALIZED */}
                            </div>
                        }

                        <div className="purchaseTermsDiv">
                            <p>
                                Per maggiori dettagli, leggere la nostra <a href="https://estro.notion.site/Gedu-app-payment-policy-238f94975a61496eb18637f9da8f29fc" target="_blank" rel="noreferrer">Payment Policy</a> e la <a href="https://app.gedu.it/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.
                            </p>
                            {
                                (isPlatform("capacitor") || isPlatform("cordova")) &&
                                isPlatform("ios") &&
                                <p>
                                    Payment will be charged to iTunes Account at confirmation of purchase.
                                </p>
                            }

                            {
                                (isPlatform("capacitor") || isPlatform("cordova")) &&
                                isPlatform("android") &&
                                <p>
                                    Payment will be charged to Play Service Account at confirmation of purchase.
                                </p>
                            }
                        </div>
                    </div>
                    
                </IonGrid>
            </IonContent>

            <IonLoading
                isOpen={this.state.isLoadingCheckoutSession}
            />
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        purchaseError: () => {
            dispatch(purchasesActions.error());
        },

        paymentIsLoading: (isLoading: boolean) => {
            dispatch(purchasesActions.loading(isLoading));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
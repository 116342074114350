import { get, ref, remove } from '@firebase/database';
import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { trash } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { featureManagerActions } from '../../../common/featureManager/actions';
import { auth, database } from '../../../common/firebase';
import { Session } from '../store/reducers';
import { fromDbGamesLogsToSessions, fromGameToGameName } from '../store/services';
import "./DataCollectionHome.css";


type Props = {
    history: any,

};

type State = {
    userUuid: string | null,
    sessions: Session[],
    showClearDataCollection: boolean,
};

class DataCollectionHome extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userUuid: null,
            sessions: [],
            showClearDataCollection: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                this.setState({ userUuid: userData.uid });
                get(ref(database, `l/g/${userData.uid}`))
                .then(snapshot => {
                    console.log("[DataCollectionHome] got games logs data:", fromDbGamesLogsToSessions(snapshot.val()));
                    this.setState({ sessions: fromDbGamesLogsToSessions(snapshot.val())})
                })
                .catch(err => {
                    console.error("[DataCollectionHome] error getting games logs:", err);
                });
            }
            else {
                this.setState({ userUuid: null });
            }
        })
    }

    clearCollectedData() {
        remove(ref(database, `l/g/${this.state.userUuid}`))
        .then(() => {
            console.log("[DataCollectionHome] success clearing games logs");
            this.setState({ sessions: []})
        })
        .catch(err => {
            console.error("[DataCollectionHome] error clearing games logs:", err);
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/home' />
                        </IonButtons>
                        <IonTitle>
                            Dati {/* TO BE LOCALIZED */}
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                onClick={() => {
                                    this.setState({ showClearDataCollection: true });
                                }}
                            >
                                <IonIcon
                                    src={trash}
                                />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="dataCollectionHomeMainGrid">
                        {
                            this.state.sessions.length === 0 &&
                            <div
                                className="dataCollectionHomeNoSessionsDiv"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                            >
                                <p>
                                    Nessuna sessione registrata.<br/> {/* TO BE LOCALIZED */}
                                    Inizia a utilizzare GEDU.
                                </p>
                            </div>
                        }
                        <div>
                            {
                                this.state.sessions
                                .reverse()
                                .map(session => { return (
                                    <div
                                        key={session.uuid}
                                        className="dataCollectionHomeSessionDiv"
                                    >
                                        <p className="dataCollectionHomeSessionGameName">
                                            {fromGameToGameName(session.game)}
                                        </p>
                                        <p className="dataCollectionHomeSessionDate">
                                            {(new Date(session.startedAt*1000)).toLocaleString()}
                                        </p>
                                    </div>
                                )})
                            }
                        </div>
                    </IonGrid>
                </IonContent>

                <IonAlert
                    isOpen={this.state.showClearDataCollection}
                    header={`Si desidera eliminare i dati raccolti?`} /* TO BE LOCALIZED */
                    message={`L'operazione non è reversibile.`} /* TO BE LOCALIZED */
                    onDidDismiss={() => {
                        this.setState({ showClearDataCollection: false });
                    }}
                    buttons={[
                        {
                            text: 'Annulla', /* TO BE LOCALIZED */
                            role: 'cancel',
                        },
                        {
                            text: 'Ok', /* TO BE LOCALIZED */
                            handler: () => {
                                this.clearCollectedData();
                            },
                        },
                    ]}
                />

            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCollectionHome);
import mela from "../../../assets/match_cards/mela.png";
import mela1 from "../../../assets/match_cards/mela1.png";
import mela2 from "../../../assets/match_cards/mela2.png";
import appleTtsIt_It60 from "../../../assets/match_cards/tts/it_it/appleTtsIt_It60.mp3";
import mele from "../../../assets/match_cards/tts/it_it_multiple/mele.mp3"
import cesto_mele from "../../../assets/counting_game/filled_basket_images/cesto_mele.svg"

import auto from '../../../assets/match_cards/auto.png';
import auto1 from '../../../assets/match_cards/auto1.png';
import auto2 from '../../../assets/match_cards/auto2.png';
import carTtsIt_It75 from "../../../assets/match_cards/tts/it_it/carTtsIt_It75.mp3";

import mirtilli from '../../../assets/match_cards/mirtilli.png';
import mirtilli1 from '../../../assets/match_cards/mirtilli1.png';
import mirtilli2 from '../../../assets/match_cards/mirtilli2.png';
import blueberriesTtsIt_It60 from "../../../assets/match_cards/tts/it_it/blueberriesTtsIt_It60.mp3";
import mirtillo from "../../../assets/match_cards/tts/it_it_multiple/mirtillo.mp3";
import cesto_mirtilli from "../../../assets/counting_game/filled_basket_images/cesto_mirtilli.svg"

import cane from '../../../assets/match_cards/cane.png';
import cane1 from '../../../assets/match_cards/cane1.png';
import cane2 from '../../../assets/match_cards/cane2.png';
import dogTtsIt_It60 from "../../../assets/match_cards/tts/it_it/dogTtsIt_It60.mp3";

import casa from '../../../assets/match_cards/casa.png';
import casa1 from '../../../assets/match_cards/casa1.png';
import casa2 from '../../../assets/match_cards/casa2.png';
import houseTtsIt_It60 from "../../../assets/match_cards/tts/it_it/houseTtsIt_It60.mp3"

import cavallo from '../../../assets/match_cards/cavallo.png';
import cavallo1 from '../../../assets/match_cards/cavallo1.png';
import cavallo2 from '../../../assets/match_cards/cavallo2.png';
import horseTtsIt_It60 from "../../../assets/match_cards/tts/it_it/horseTtsIt_It60.mp3";

import ciliegie from '../../../assets/match_cards/ciliegie.png';
import ciliegie1 from '../../../assets/match_cards/ciliegie1.png';
import ciliegie2 from '../../../assets/match_cards/ciliegie2.png';
import cherriesTtsIt_It60 from "../../../assets/match_cards/tts/it_it/cherriesTtsIt_It60.mp3";
import ciliegia from "../../../assets/match_cards/tts/it_it_multiple/ciliegia.mp3";
import cesto_ciliegie from "../../../assets/counting_game/filled_basket_images/cesto_ciliegie.svg"

import cupcake from '../../../assets/match_cards/cupcake.png';
import cupcake1 from '../../../assets/match_cards/cupcake1.png';
import cupcake2 from '../../../assets/match_cards/cupcake2.png';
import cupcakeTtsIt_It60 from "../../../assets/match_cards/tts/it_it/cupcakeTtsIt_It60.mp3"

import delfino from '../../../assets/match_cards/delfino.png';
import delfino1 from '../../../assets/match_cards/delfino1.png';
import delfino2 from '../../../assets/match_cards/delfino2.png';
import delfinoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/delfinoTtsIt_It60.mp3"

import forbici from '../../../assets/match_cards/forbici.png';
import forbici1 from '../../../assets/match_cards/forbici1.png';
import forbici2 from '../../../assets/match_cards/forbici2.png';
import forbiciTtsIt_It60 from "../../../assets/match_cards/tts/it_it/forbiciTtsIt_It60.mp3"

import fungo from '../../../assets/match_cards/fungo.png';
import fungo1 from '../../../assets/match_cards/fungo1.png';
import fungo2 from '../../../assets/match_cards/fungo2.png';
import fungoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/fungoTtsIt_It60.mp3"
import funghi from "../../../assets/match_cards/tts/it_it_multiple/funghi.mp3"

import gallina from '../../../assets/match_cards/gallina.png';
import gallina1 from '../../../assets/match_cards/gallina1.png';
import gallina2 from '../../../assets/match_cards/gallina2.png';
import gallinaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/gallinaTtsIt_It60.mp3"

import gatto from '../../../assets/match_cards/gatto.png';
import gatto1 from '../../../assets/match_cards/gatto1.png';
import gatto2 from '../../../assets/match_cards/gatto2.png';
import gattoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/gattoTtsIt_It60.mp3"


import uva from '../../../assets/match_cards/uva.png';
import uva1 from '../../../assets/match_cards/uva1.png';
import uva2 from '../../../assets/match_cards/uva2.png';
import uvaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/uvaTtsIt_It60.mp3"
import uve from "../../../assets/match_cards/tts/it_it_multiple/uve.mp3"
import cesto_uve from "../../../assets/counting_game/filled_basket_images/cesto_uve.svg"


import panino from '../../../assets/match_cards/panino.png';
import panino1 from '../../../assets/match_cards/panino1.png';
import panino2 from '../../../assets/match_cards/panino2.png';
import paninoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/paninoTtsIt_It60.mp3"
import panini from "../../../assets/match_cards/tts/it_it_multiple/panini.mp3"


import limone from '../../../assets/match_cards/limone.png';
import limone1 from '../../../assets/match_cards/limone1.png';
import limone2 from '../../../assets/match_cards/limone2.png';
import limoneTtsIt_It60 from "../../../assets/match_cards/tts/it_it/limoneTtsIt_It60.mp3"
import limoni from "../../../assets/match_cards/tts/it_it_multiple/limoni.mp3"
import cesto_limoni from "../../../assets/counting_game/filled_basket_images/cesto_limoni.svg"

import maiale from '../../../assets/match_cards/maiale.png';
import maiale1 from '../../../assets/match_cards/maiale1.png';
import maiale2 from '../../../assets/match_cards/maiale2.png';
import maialeTtsIt_It60 from "../../../assets/match_cards/tts/it_it/maialeTtsIt_It60.mp3"

import matita from '../../../assets/match_cards/matita.png';
import matita1 from '../../../assets/match_cards/matita1.png';
import matita2 from '../../../assets/match_cards/matita2.png';
import matitaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/matitaTtsIt_It60.mp3"

import orsetto from '../../../assets/match_cards/orsetto.png';
import orsetto1 from '../../../assets/match_cards/orsetto1.png';
import orsetto2 from '../../../assets/match_cards/orsetto2.png';
import orsettoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/orsettoTtsIt_It60.mp3"

import pacco_regalo from '../../../assets/match_cards/pacco_regalo.png';
import pacco_regalo1 from '../../../assets/match_cards/pacco_regalo1.png';
import pacco_regalo2 from '../../../assets/match_cards/pacco_regalo2.png';
import pacco_regaloTtsIt_It60 from "../../../assets/match_cards/tts/it_it/pacco_regaloTtsIt_It60.mp3"

import palloncino from '../../../assets/match_cards/palloncino.png';
import palloncino1 from '../../../assets/match_cards/palloncino1.png';
import palloncino2 from '../../../assets/match_cards/palloncino2.png';
import palloncinoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/palloncinoTtsIt_It60.mp3"

import pera from '../../../assets/match_cards/pera.png';
import pera1 from '../../../assets/match_cards/pera1.png';
import pera2 from '../../../assets/match_cards/pera2.png';
import peraTtsIt_It60 from "../../../assets/match_cards/tts/it_it/peraTtsIt_It60.mp3"
import pere from "../../../assets/match_cards/tts/it_it_multiple/pere.mp3"
import cesto_pere from "../../../assets/counting_game/filled_basket_images/cesto_pere.svg"

/* ***** */
import pesce from '../../../assets/match_cards/pesce.png';
import pesce1 from '../../../assets/match_cards/pesce1.png';
import pesce2 from '../../../assets/match_cards/pesce2.png';
import fishTtsIt_It60 from "../../../assets/match_cards/tts/it_it/fishTtsIt_It60.mp3";

import pulcino from '../../../assets/match_cards/pulcino.png';
import pulcino1 from '../../../assets/match_cards/pulcino1.png';
import pulcino2 from '../../../assets/match_cards/pulcino2.png';
import chickTtsIt_It60 from "../../../assets/match_cards/tts/it_it/chickTtsIt_It60.mp3";

import scarpone from '../../../assets/match_cards/scarpone.png';
import scarpone1 from '../../../assets/match_cards/scarpone1.png';
import scarpone2 from '../../../assets/match_cards/scarpone2.png';
import bootTtsIt_It60 from "../../../assets/match_cards/tts/it_it/bootTtsIt_It60.mp3";

import sedia from '../../../assets/match_cards/sedia.png';
import sedia1 from '../../../assets/match_cards/sedia1.png';
import sedia2 from '../../../assets/match_cards/sedia2.png';
import chairTtsIt_It60 from "../../../assets/match_cards/tts/it_it/chairTtsIt_It60.mp3";

import sole from '../../../assets/match_cards/sole.png';
import sole1 from '../../../assets/match_cards/sole1.png';
import sole2 from '../../../assets/match_cards/sole2.png';
import sunTtsIt_It60 from "../../../assets/match_cards/tts/it_it/sunTtsIt_It60.mp3";

import fragola from '../../../assets/match_cards/fragola.png';
import fragola1 from '../../../assets/match_cards/fragola1.png';
import fragola2 from '../../../assets/match_cards/fragola2.png';
import strawberryTtsIt_It60 from "../../../assets/match_cards/tts/it_it/strawberryTtsIt_It60.mp3";
import fragole from "../../../assets/match_cards/tts/it_it_multiple/fragole.mp3";
import cesto_fragole from "../../../assets/counting_game/filled_basket_images/cesto_fragole.svg"

import tartaruga from '../../../assets/match_cards/tartaruga.png';
import tartaruga1 from '../../../assets/match_cards/tartaruga1.png';
import tartaruga2 from '../../../assets/match_cards/tartaruga2.png';
import turtleTtsIt_It60 from "../../../assets/match_cards/tts/it_it/turtleTtsIt_It60.mp3";

import trenino from '../../../assets/match_cards/trenino.png';
import trenino1 from '../../../assets/match_cards/trenino1.png';
import trenino2 from '../../../assets/match_cards/trenino2.png';
import trainTtsIt_It60 from "../../../assets/match_cards/tts/it_it/trainTtsIt_It60.mp3";

import unicorno from '../../../assets/match_cards/unicorno.png';
import unicorno1 from '../../../assets/match_cards/unicorno1.png';
import unicorno2 from '../../../assets/match_cards/unicorno2.png';
import unicornoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/unicornoTtsIt_It60.mp3"

import anguria from '../../../assets/match_cards/anguria.png';
import anguria1 from '../../../assets/match_cards/anguria1.png';
import anguria2 from '../../../assets/match_cards/anguria2.png';
import anguriaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/anguriaTtsIt_It60.mp3"
import angurie from "../../../assets/match_cards/tts/it_it_multiple/angurie.mp3"
import cesto_angurie from "../../../assets/counting_game/filled_basket_images/cesto_angurie.svg"


import arancia from '../../../assets/match_cards/arancia.png';
import arancia1 from '../../../assets/match_cards/arancia1.png';
import arancia2 from '../../../assets/match_cards/arancia2.png';
import aranciaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/aranciaTtsIt_It60.mp3"
import arance from "../../../assets/match_cards/tts/it_it_multiple/arance.mp3"
import cesto_arance from "../../../assets/counting_game/filled_basket_images/cesto_arance.svg"

import pesca from '../../../assets/match_cards/pesca.png';
import pesca1 from '../../../assets/match_cards/pesca1.png';
import pesca2 from '../../../assets/match_cards/pesca2.png';
import pescaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/pescaTtsIt_It60.mp3"
import pesche from "../../../assets/match_cards/tts/it_it_multiple/pesche.mp3"
import cesto_pesche from "../../../assets/counting_game/filled_basket_images/cesto_pesche.svg"

import mora from '../../../assets/match_cards/mora.png';
import mora1 from '../../../assets/match_cards/mora1.png';
import mora2 from '../../../assets/match_cards/mora2.png';
import moraTtsIt_It60 from "../../../assets/match_cards/tts/it_it/moraTtsIt_It60.mp3"
import more from "../../../assets/match_cards/tts/it_it_multiple/more.mp3"
import cesto_more from "../../../assets/counting_game/filled_basket_images/cesto_more.svg"

import banana from '../../../assets/match_cards/banana.png';
import banana1 from '../../../assets/match_cards/banana1.png';
import banana2 from '../../../assets/match_cards/banana2.png';
import bananaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/bananaTtsIt_It60.mp3"
import banane from "../../../assets/match_cards/tts/it_it_multiple/banane.mp3"
import cesto_banane from "../../../assets/counting_game/filled_basket_images/cesto_banane.svg"

import prugna from '../../../assets/match_cards/prugna.png';
import prugna1 from '../../../assets/match_cards/prugna1.png';
import prugna2 from '../../../assets/match_cards/prugna2.png';
import prugnaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/prugnaTtsIt_It60.mp3"
import prugne from "../../../assets/match_cards/tts/it_it_multiple/prugne.mp3"
import cesto_prugne from "../../../assets/counting_game/filled_basket_images/cesto_prugne.svg"

import aglio from '../../../assets/match_cards/aglio.png';
import aglio1 from '../../../assets/match_cards/aglio1.png';
import aglio2 from '../../../assets/match_cards/aglio2.png';
import aglioTtsIt_It60 from "../../../assets/match_cards/tts/it_it/aglioTtsIt_It60.mp3"
import agli from "../../../assets/match_cards/tts/it_it_multiple/agli.mp3"
import cesto_agli from "../../../assets/counting_game/filled_basket_images/cesto_agli.svg"

import asparagi from '../../../assets/match_cards/asparagi.png';
import asparagi1 from '../../../assets/match_cards/asparagi1.png';
import asparagi2 from '../../../assets/match_cards/asparagi2.png';
import asparagiTtsIt_It60 from "../../../assets/match_cards/tts/it_it/asparagiTtsIt_It60.mp3"
import cesto_asparagi from "../../../assets/counting_game/filled_basket_images/cesto_asparagi.svg"

import avocado from '../../../assets/match_cards/avocado.png';
import avocado1 from '../../../assets/match_cards/avocado1.png';
import avocado2 from '../../../assets/match_cards/avocado2.png';
import avocadoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/avocadoTtsIt_It60.mp3"
import avocadi from "../../../assets/match_cards/tts/it_it_multiple/avocadi.mp3"
import cesto_avocado from "../../../assets/counting_game/filled_basket_images/cesto_avocado.svg"

import broccolo from '../../../assets/match_cards/broccolo.png';
import broccolo1 from '../../../assets/match_cards/broccolo1.png';
import broccolo2 from '../../../assets/match_cards/broccolo2.png';
import broccoloTtsIt_It60 from "../../../assets/match_cards/tts/it_it/broccoloTtsIt_It60.mp3"
import broccoli from "../../../assets/match_cards/tts/it_it_multiple/broccoli.mp3"
import cesto_broccoli from "../../../assets/counting_game/filled_basket_images/cesto_broccoli.svg"

import carciofo from '../../../assets/match_cards/carciofo.png';
import carciofo1 from '../../../assets/match_cards/carciofo1.png';
import carciofo2 from '../../../assets/match_cards/carciofo2.png';
import carciofoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/carciofoTtsIt_It60.mp3"
import carciofi from "../../../assets/match_cards/tts/it_it_multiple/carciofi.mp3"
import cesto_carciofi from "../../../assets/counting_game/filled_basket_images/cesto_carciofi.svg"

import carota from '../../../assets/match_cards/carota.png';
import carota1 from '../../../assets/match_cards/carota1.png';
import carota2 from '../../../assets/match_cards/carota2.png';
import carotaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/carotaTtsIt_It60.mp3"
import carote from "../../../assets/match_cards/tts/it_it_multiple/carote.mp3"
import cesto_carote from "../../../assets/counting_game/filled_basket_images/cesto_carote.svg"

import castagna from '../../../assets/match_cards/castagna.png';
import castagna1 from '../../../assets/match_cards/castagna1.png';
import castagna2 from '../../../assets/match_cards/castagna2.png';
import castagnaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/castagnaTtsIt_It60.mp3"
import castagne from "../../../assets/match_cards/tts/it_it_multiple/castagne.mp3"
import cesto_castagne from "../../../assets/counting_game/filled_basket_images/cesto_castagne.svg"

import cetriolo from '../../../assets/match_cards/cetriolo.png';
import cetriolo1 from '../../../assets/match_cards/cetriolo1.png';
import cetriolo2 from '../../../assets/match_cards/cetriolo2.png';
import cetrioloTtsIt_It60 from "../../../assets/match_cards/tts/it_it/cetrioloTtsIt_It60.mp3"
import cetrioli from "../../../assets/match_cards/tts/it_it_multiple/cetrioli.mp3"
import cesto_cetrioli from "../../../assets/counting_game/filled_basket_images/cesto_cetrioli.svg"

import cipolla from '../../../assets/match_cards/cipolla.png';
import cipolla1 from '../../../assets/match_cards/cipolla1.png';
import cipolla2 from '../../../assets/match_cards/cipolla2.png';
import cipollaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/cipollaTtsIt_It60.mp3"
import cipolle from "../../../assets/match_cards/tts/it_it_multiple/cipolle.mp3"
import cesto_cipolle from "../../../assets/counting_game/filled_basket_images/cesto_cipolle.svg"

import insalata from '../../../assets/match_cards/insalata.png';
import insalata1 from '../../../assets/match_cards/insalata1.png';
import insalata2 from '../../../assets/match_cards/insalata2.png';
import insalataTtsIt_It60 from "../../../assets/match_cards/tts/it_it/insalataTtsIt_It60.mp3"
import insalate from "../../../assets/match_cards/tts/it_it_multiple/insalate.mp3"
import cesto_insalata from "../../../assets/counting_game/filled_basket_images/cesto_insalata.svg"

import melanzana from '../../../assets/match_cards/melanzana.png';
import melanzana1 from '../../../assets/match_cards/melanzana1.png';
import melanzana2 from '../../../assets/match_cards/melanzana2.png';
import melanzanaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/melanzanaTtsIt_It60.mp3"
import melanzane from "../../../assets/match_cards/tts/it_it_multiple/melanzane.mp3"
import cesto_melanzane from "../../../assets/counting_game/filled_basket_images/cesto_melanzane.svg"

import pannocchia from '../../../assets/match_cards/pannocchia.png';
import pannocchia1 from '../../../assets/match_cards/pannocchia1.png';
import pannocchia2 from '../../../assets/match_cards/pannocchia2.png';
import pannocchiaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/pannocchiaTtsIt_It60.mp3"
import pannocchie from "../../../assets/match_cards/tts/it_it_multiple/pannocchie.mp3"
import cesto_pannocchie from "../../../assets/counting_game/filled_basket_images/cesto_pannocchie.svg"

import patata from '../../../assets/match_cards/patata.png';
import patata1 from '../../../assets/match_cards/patata1.png';
import patata2 from '../../../assets/match_cards/patata2.png';
import patataTtsIt_It60 from "../../../assets/match_cards/tts/it_it/patataTtsIt_It60.mp3"
import patate from "../../../assets/match_cards/tts/it_it_multiple/patate.mp3"
import cesto_patate from "../../../assets/counting_game/filled_basket_images/cesto_patate.svg"

import peperoncino from '../../../assets/match_cards/peperoncino.png';
import peperoncino1 from '../../../assets/match_cards/peperoncino1.png';
import peperoncino2 from '../../../assets/match_cards/peperoncino2.png';
import peperoncinoTtsIt_It60 from "../../../assets/match_cards/tts/it_it/peperoncinoTtsIt_It60.mp3"
import peperoncini from "../../../assets/match_cards/tts/it_it_multiple/peperoncini.mp3"
import cesto_peperoncini from "../../../assets/counting_game/filled_basket_images/cesto_peperoncini.svg"

import peperone from '../../../assets/match_cards/peperone.png';
import peperone1 from '../../../assets/match_cards/peperone1.png';
import peperone2 from '../../../assets/match_cards/peperone2.png';
import peperoneTtsIt_It60 from "../../../assets/match_cards/tts/it_it/peperoneTtsIt_It60.mp3"
import peperoni from "../../../assets/match_cards/tts/it_it_multiple/peperoni.mp3"
import cesto_peperoni from "../../../assets/counting_game/filled_basket_images/cesto_peperoni.svg"

import zucca from '../../../assets/match_cards/zucca.png';
import zucca1 from '../../../assets/match_cards/zucca1.png';
import zucca2 from '../../../assets/match_cards/zucca2.png';
import zuccaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/zuccaTtsIt_It60.mp3"
import zucche from "../../../assets/match_cards/tts/it_it_multiple/zucche.mp3"
import cesto_zucche from "../../../assets/counting_game/filled_basket_images/cesto_zucche.svg"

import zucchina from '../../../assets/match_cards/zucchina.png';
import zucchina1 from '../../../assets/match_cards/zucchina1.png';
import zucchina2 from '../../../assets/match_cards/zucchina2.png';
import zucchinaTtsIt_It60 from "../../../assets/match_cards/tts/it_it/zucchinaTtsIt_It60.mp3"
import zucchine from "../../../assets/match_cards/tts/it_it_multiple/zucchine.mp3"
import cesto_zucchine from "../../../assets/counting_game/filled_basket_images/cesto_zucchine.svg"


export interface CardObj {
    name: {
        it_it: {
            sing: string,
            plur: string,
        }
    },
    pronunciation: {
        it_it: string,
    },
    tts: {
        it_it: string,
        it_it_multiple?: string,
    },
    category: string[],
    basket?: string;
    full: string,
    half1: string,
    half2: string
}

export const cardsObj: CardObj[] = [
    {
        name: {
            it_it: { sing: "mela", plur: "mele" },
        },
        pronunciation: {
            it_it: "mela",
        },
        tts: {
            it_it: appleTtsIt_It60,
            it_it_multiple: mele
        },
        category: ['food', 'fruit'],
        basket: cesto_mele,
        full: mela,
        half1: mela1,
        half2: mela2
    },
    {
        name: {
            it_it: { sing: "arancia", plur: "arance" },
        },
        pronunciation: {
            it_it: "arancia",
        },
        tts: {
            it_it: aranciaTtsIt_It60,
            it_it_multiple: arance
        },
        category: ['food', 'fruit'],
        basket: cesto_arance,
        full: arancia,
        half1: arancia1,
        half2: arancia2
    },
    {
        name: {
            it_it: { sing: "aglio", plur: "agli" },
        },
        pronunciation: {
            it_it: "aglio",
        },
        tts: {
            it_it: aglioTtsIt_It60,
            it_it_multiple: agli
        },
        category: ['food', 'vegetable'],
        basket: cesto_agli,
        full: aglio,
        half1: aglio1,
        half2: aglio2
    },
    {
        name: {
            it_it: { sing: "asparago", plur: "asparagi" },
        },
        pronunciation: {
            it_it: "asparagi",
        },
        tts: {
            it_it: asparagiTtsIt_It60,
            it_it_multiple: asparagiTtsIt_It60
        },
        category: ['food', 'vegetable'],
        basket: cesto_asparagi,
        full: asparagi,
        half1: asparagi1,
        half2: asparagi2
    },
    {
        name: {
            it_it: { sing: "avocado", plur: "avocadi" },
        },
        pronunciation: {
            it_it: "avocado",
        },
        tts: {
            it_it: avocadoTtsIt_It60,
            it_it_multiple: avocadi
        },
        category: ['food', 'fruit'],
        basket: cesto_avocado,
        full: avocado,
        half1: avocado1,
        half2: avocado2
    },
    {
        name: {
            it_it: { sing: "broccolo", plur: "broccoli" },
        },
        pronunciation: {
            it_it: "broccolo",
        },
        tts: {
            it_it: broccoloTtsIt_It60,
            it_it_multiple: broccoli
        },
        category: ['food', 'vegetable'],
        basket: cesto_broccoli,
        full: broccolo,
        half1: broccolo1,
        half2: broccolo2
    },
    {
        name: {
            it_it: { sing: "carciofo", plur: "carciofi" },
        },
        pronunciation: {
            it_it: "carciofo",
        },
        tts: {
            it_it: carciofoTtsIt_It60,
            it_it_multiple: carciofi
        },
        category: ['food', 'vegetable'],
        basket: cesto_carciofi,
        full: carciofo,
        half1: carciofo1,
        half2: carciofo2
    },
    {
        name: {
            it_it: { sing: "carota", plur: "carote" },
        },
        pronunciation: {
            it_it: "carota",
        },
        tts: {
            it_it: carotaTtsIt_It60,
            it_it_multiple: carote
        },
        category: ['food', 'vegetable'],
        basket: cesto_carote,
        full: carota,
        half1: carota1,
        half2: carota2
    },
    {
        name: {
            it_it: { sing: "castagna", plur: "castagne" },
        },
        pronunciation: {
            it_it: "castagna",
        },
        tts: {
            it_it: castagnaTtsIt_It60,
            it_it_multiple: castagne
        },
        category: ['food', 'fruit'],
        basket: cesto_castagne,
        full: castagna,
        half1: castagna1,
        half2: castagna2
    },
    {
        name: {
            it_it: { sing: "cetriolo", plur: "cetrioli" },
        },
        pronunciation: {
            it_it: "cetriolo",
        },
        tts: {
            it_it: cetrioloTtsIt_It60,
            it_it_multiple: cetrioli
        },
        category: ['food', 'vegetable'],
        basket: cesto_cetrioli,
        full: cetriolo,
        half1: cetriolo1,
        half2: cetriolo2
    },
    {
        name: {
            it_it: { sing: "cipolla", plur: "cipolle" },
        },
        pronunciation: {
            it_it: "cipolla",
        },
        tts: {
            it_it: cipollaTtsIt_It60,
            it_it_multiple: cipolle
        },
        category: ['food', 'vegetable'],
        basket: cesto_cipolle,
        full: cipolla,
        half1: cipolla1,
        half2: cipolla2
    },
    {
        name: {
            it_it: { sing: "insalata", plur: "insalate" },
        },
        pronunciation: {
            it_it: "insalata",
        },
        tts: {
            it_it: insalataTtsIt_It60,
            it_it_multiple: insalate
        },
        category: ['food', 'vegetable'],
        basket: cesto_insalata,
        full: insalata,
        half1: insalata1,
        half2: insalata2
    },
    {
        name: {
            it_it: { sing: "melanzana", plur: "melanzane" },
        },
        pronunciation: {
            it_it: "melanzana",
        },
        tts: {
            it_it: melanzanaTtsIt_It60,
            it_it_multiple: melanzane
        },
        category: ['food', 'vegetable'],
        basket: cesto_melanzane,
        full: melanzana,
        half1: melanzana1,
        half2: melanzana2
    },
    {
        name: {
            it_it: { sing: "pannocchia", plur: "pannocchie" },
        },
        pronunciation: {
            it_it: "pannocchia",
        },
        tts: {
            it_it: pannocchiaTtsIt_It60,
            it_it_multiple: pannocchie
        },
        category: ['food', 'vegetable'],
        basket: cesto_pannocchie,
        full: pannocchia,
        half1: pannocchia1,
        half2: pannocchia2
    },
    {
        name: {
            it_it: { sing: "patata", plur: "patate" },
        },
        pronunciation: {
            it_it: "patata",
        },
        tts: {
            it_it: patataTtsIt_It60,
            it_it_multiple: patate
        },
        category: ['food', 'vegetable'],
        basket: cesto_patate,
        full: patata,
        half1: patata1,
        half2: patata2
    },
    {
        name: {
            it_it: { sing: "peperoncino", plur: "peperoncini" },
        },
        pronunciation: {
            it_it: "peperoncino",
        },
        tts: {
            it_it: peperoncinoTtsIt_It60,
            it_it_multiple: peperoncini
        },
        category: ['food', 'vegetable'],
        basket: cesto_peperoncini,
        full: peperoncino,
        half1: peperoncino1,
        half2: peperoncino2
    },
    {
        name: {
            it_it: { sing: "peperone", plur: "peperoni" },
        },
        pronunciation: {
            it_it: "peperone",
        },
        tts: {
            it_it: peperoneTtsIt_It60,
            it_it_multiple: peperoni
        },
        category: ['food', 'vegetable'],
        basket: cesto_peperoni,
        full: peperone,
        half1: peperone1,
        half2: peperone2
    },
    {
        name: {
            it_it: { sing: "zucca", plur: "zucche" },
        },
        pronunciation: {
            it_it: "zucca",
        },
        tts: {
            it_it: zuccaTtsIt_It60,
            it_it_multiple: zucche
        },
        category: ['food', 'fruit'],
        basket: cesto_zucche,
        full: zucca,
        half1: zucca1,
        half2: zucca2
    },
    {
        name: {
            it_it: { sing: "zucchina", plur: "zucchine" },
        },
        pronunciation: {
            it_it: "zucchina",
        },
        tts: {
            it_it: zucchinaTtsIt_It60,
            it_it_multiple: zucchine
        },
        category: ['food', 'vegetable'],
        basket: cesto_zucchine,
        full: zucchina,
        half1: zucchina1,
        half2: zucchina2
    },
    {
        name: {
            it_it: { sing: "pesca", plur: "pesche" },
        },
        pronunciation: {
            it_it: "pesca",
        },
        tts: {
            it_it: pescaTtsIt_It60,
            it_it_multiple: pesche
        },
        category: ['food', 'fruit'],
        basket: cesto_pesche,
        full: pesca,
        half1: pesca1,
        half2: pesca2
    },
    {
        name: {
            it_it: { sing: "mora", plur: "more" },
        },
        pronunciation: {
            it_it: "mora",
        },
        tts: {
            it_it: moraTtsIt_It60,
            it_it_multiple: more
        },
        category: ['food', 'fruit'],
        basket: cesto_more,
        full: mora,
        half1: mora1,
        half2: mora2
    },
    {
        name: {
            it_it: { sing: "banana", plur: "banane" },
        },
        pronunciation: {
            it_it: "banana",
        },
        tts: {
            it_it: bananaTtsIt_It60,
            it_it_multiple: banane
        },
        category: ['food', 'fruit'],
        basket: cesto_banane,
        full: banana,
        half1: banana1,
        half2: banana2
    },
    {
        name: {
            it_it: { sing: "prugna", plur: "prugne" },
        },
        pronunciation: {
            it_it: "prugna",
        },
        tts: {
            it_it: prugnaTtsIt_It60,
            it_it_multiple: prugne
        },
        category: ['food', 'fruit'],
        basket: cesto_prugne,
        full: prugna,
        half1: prugna1,
        half2: prugna2
    },
    {
        name: {
            it_it: { sing: "auto", plur: "auto" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "auto"
        },
        tts: {
            it_it: carTtsIt_It75,
        },
        full: auto,
        half1: auto1,
        half2: auto2
    },
    {
        name: {
            it_it: { sing: "mirtillo", plur: "mirtilli" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "mirtilli",
        },
        tts: {
            it_it: blueberriesTtsIt_It60,
            it_it_multiple: blueberriesTtsIt_It60
        },
        basket: cesto_mirtilli,
        full: mirtilli,
        half1: mirtilli1,
        half2: mirtilli2
    },
    {
        name: {
            it_it: { sing: "cane", plur: "cani" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "cane",
        },
        tts: {
            it_it: dogTtsIt_It60,
        },
        full: cane,
        half1: cane1,
        half2: cane2
    },
    {
        name: {
            it_it: { sing: "casa", plur: "case" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "casa",
        },
        tts: {
            it_it: houseTtsIt_It60,
        },
        full: casa,
        half1: casa1,
        half2: casa2
    },
    {
        name: {
            it_it: { sing: "cavallo", plur: "cavalli" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "cavallo",
        },
        tts: {
            it_it: horseTtsIt_It60,
        },
        full: cavallo,
        half1: cavallo1,
        half2: cavallo2
    },
    {
        name: {
            it_it: { sing: "ciliegia", plur: "ciliegie" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "ciliegie",
        },
        tts: {
            it_it: ciliegia,
            it_it_multiple: cherriesTtsIt_It60
        },
        basket: cesto_ciliegie,
        full: ciliegie,
        half1: ciliegie1,
        half2: ciliegie2
    },
    {
        name: {
            it_it: { sing: "cupcake", plur: "cupcake" },
        },
        category: ['food'],
        pronunciation: {
            it_it: "capcheik",
        },
        tts: {
            it_it: cupcakeTtsIt_It60,
            it_it_multiple: cupcakeTtsIt_It60
        },
        full: cupcake,
        half1: cupcake1,
        half2: cupcake2
    },
    {
        name: {
            it_it: { sing: "delfino", plur: "delfini" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "delfino",
        },
        tts: {
            it_it: delfinoTtsIt_It60,
        },
        full: delfino,
        half1: delfino1,
        half2: delfino2
    },
    {
        name: {
            it_it: { sing: "forbici", plur: "forbici" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "forbici",
        },
        tts: {
            it_it: forbiciTtsIt_It60,
        },
        full: forbici,
        half1: forbici1,
        half2: forbici2
    },
    {
        name: {
            it_it: { sing: "fungo", plur: "funghi" },
        },
        category: ['food'],
        pronunciation: {
            it_it: "fungo",
        },
        tts: {
            it_it: fungoTtsIt_It60,
            it_it_multiple: funghi,
        },
        full: fungo,
        half1: fungo1,
        half2: fungo2
    },
    {
        name: {
            it_it: { sing: "gallina", plur: "galline" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "gallina",
        },
        tts: {
            it_it: gallinaTtsIt_It60,
        },
        full: gallina,
        half1: gallina1,
        half2: gallina2
    },
    {
        name: {
            it_it: { sing: "gatto", plur: "gatti" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "gatto",
        },
        tts: {
            it_it: gattoTtsIt_It60,
        },
        full: gatto,
        half1: gatto1,
        half2: gatto2
    },
    {
        name: {
            it_it: { sing: "uva", plur: "uve" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "uva",
        },
        tts: {
            it_it: uvaTtsIt_It60,
            it_it_multiple: uve
        },
        basket: cesto_uve,
        full: uva,
        half1: uva1,
        half2: uva2
    },
    {
        name: {
            it_it: { sing: "panino", plur: "panini" },
        },
        category: ['food'],
        pronunciation: {
            it_it: "panino",
        },
        tts: {
            it_it: paninoTtsIt_It60,
            it_it_multiple: panini
        },
        full: panino,
        half1: panino1,
        half2: panino2
    },
    {
        name: {
            it_it: { sing: "limone", plur: "limoni" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "limone",
        },
        tts: {
            it_it: limoneTtsIt_It60,
            it_it_multiple: limoni
        },
        basket: cesto_limoni,
        full: limone,
        half1: limone1,
        half2: limone2
    },
    {
        name: {
            it_it: { sing: "maiale", plur: "maiali" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "maiale",
        },
        tts: {
            it_it: maialeTtsIt_It60,
        },
        full: maiale,
        half1: maiale1,
        half2: maiale2
    },
    {
        name: {
            it_it: { sing: "matita", plur: "matite" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "matita",
        },
        tts: {
            it_it: matitaTtsIt_It60,
        },
        full: matita,
        half1: matita1,
        half2: matita2
    },
    {
        name: {
            it_it: { sing: "orsetto", plur: "orsetti" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "orsetto",
        },
        tts: {
            it_it: orsettoTtsIt_It60,
        },
        full: orsetto,
        half1: orsetto1,
        half2: orsetto2
    },
    {
        name: {
            it_it: { sing: "pacco regalo", plur: "pacchi regalo" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "pacco regalo",
        },
        tts: {
            it_it: pacco_regaloTtsIt_It60,
        },
        full: pacco_regalo,
        half1: pacco_regalo1,
        half2: pacco_regalo2
    },
    {
        name: {
            it_it: { sing: "palloncino", plur: "palloncini" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "palloncino",
        },
        tts: {
            it_it: palloncinoTtsIt_It60,
        },
        full: palloncino,
        half1: palloncino1,
        half2: palloncino2
    },
    {
        name: {
            it_it: { sing: "pera", plur: "pere" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "péra",
        },
        tts: {
            it_it: peraTtsIt_It60,
            it_it_multiple: pere
        },
        basket: cesto_pere,
        full: pera,
        half1: pera1,
        half2: pera2
    },
    {
        name: {
            it_it: { sing: "pesce", plur: "pesci" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "pesce",
        },
        tts: {
            it_it: fishTtsIt_It60,
        },
        full: pesce,
        half1: pesce1,
        half2: pesce2
    },
    {
        name: {
            it_it: { sing: "pulcino", plur: "pulcini" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "pulcino",
        },
        tts: {
            it_it: chickTtsIt_It60,
        },
        full: pulcino,
        half1: pulcino1,
        half2: pulcino2
    },
    {
        name: {
            it_it: { sing: "scarpone", plur: "scarponi" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "scarpone",
        },
        tts: {
            it_it: bootTtsIt_It60,
        },
        full: scarpone,
        half1: scarpone1,
        half2: scarpone2
    },
    {
        name: {
            it_it: { sing: "sedia", plur: "sedie" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "sedia",
        },
        tts: {
            it_it: chairTtsIt_It60,
        },
        full: sedia,
        half1: sedia1,
        half2: sedia2
    },
    {
        name: {
            it_it: { sing: "sole", plur: "soli" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "sole",
        },
        tts: {
            it_it: sunTtsIt_It60,
        },
        full: sole,
        half1: sole1,
        half2: sole2
    },
    {
        name: {
            it_it: { sing: "fragola", plur: "fragole" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "fragola",
        },
        tts: {
            it_it: strawberryTtsIt_It60,
            it_it_multiple: fragole,
        },
        basket: cesto_fragole,
        full: fragola,
        half1: fragola1,
        half2: fragola2
    },
    {
        name: {
            it_it: { sing: "tartaruga", plur: "tartarughe" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "tartaruga",
        },
        tts: {
            it_it: turtleTtsIt_It60,
        },
        full: tartaruga,
        half1: tartaruga1,
        half2: tartaruga2
    },
    {
        name: {
            it_it: { sing: "trenino", plur: "trenini" },
        },
        category: ['objects'],
        pronunciation: {
            it_it: "trenino",
        },
        tts: {
            it_it: trainTtsIt_It60,
        },
        full: trenino,
        half1: trenino1,
        half2: trenino2
    },
    {
        name: {
            it_it: { sing: "unicorno", plur: "unicorni" },
        },
        category: ['animals'],
        pronunciation: {
            it_it: "unicorno",
        },
        tts: {
            it_it: unicornoTtsIt_It60,
        },
        full: unicorno,
        half1: unicorno1,
        half2: unicorno2
    },
    {
        name: {
            it_it: { sing: "anguria", plur: "angurie" },
        },
        category: ['food', 'fruit'],
        pronunciation: {
            it_it: "anguria",
        },
        tts: {
            it_it: anguriaTtsIt_It60,
            it_it_multiple: angurie
        },
        basket: cesto_angurie,
        full: anguria,
        half1: anguria1,
        half2: anguria2
    }
];
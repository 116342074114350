import { IonButton, IonFab, IonFabButton, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { featureManagerActions } from "../actions";
import { httpsCallable } from "firebase/functions";
import "./FeatureManagerPopovers.css";
import { functions } from "../../firebase";
import { closeCircle, closeOutline } from "ionicons/icons";

declare class Stripe {
    constructor(...args: any[]);
    redirectToCheckout(...args: any[]): any;
}

//const stripe = new Stripe("pk_test_51Jb6VuJasdj1ggLrhB8O7LCkfa6ivtnkjxXHKarMJgW3YtwiS5ROrOJOMx7yK69XIp4RlNaPzTxgXEFJwpyHeffO00tNIqqz9t");
const stripe = new Stripe("pk_live_51Jb6VuJasdj1ggLr8hyLJtNE1a8fw8WF8AWDK8bvzo333BwBacQ250xwVg06lJPmkbnA4BOU0iIpAgLhV6lAJhJo00wWLJlG6v");

type Props = {
    showFreeTrialPopover: boolean,
    showPayPopover: boolean,

    freeTrialPopover: (show: boolean) => void,
    payPopover: (show: boolean) => void,
};

type State = {
    isLoading: boolean

    showLeaveInAppBrowser: boolean,
    showTopFinger: boolean,
    showBottomFinger: boolean,
};

class FeatureManagerPopovers extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,

            showLeaveInAppBrowser: false,
            showTopFinger: false,
            showBottomFinger: false,
        }
    }

    componentDidMount() {

        const userAgent = window.navigator.userAgent.toLowerCase(),
            safari = /safari/.test(userAgent),
            ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
            if (safari) {
                this.setState({ showLeaveInAppBrowser: false });
            } else if (!safari) {
                this.setState({ showLeaveInAppBrowser: true });

                var ua = navigator.userAgent || navigator.vendor; // || window.opera;
                var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
                if(isInstagram) {
                    this.setState({ showTopFinger: true });
                }
                else {
                    this.setState({ showBottomFinger: true });
                }
            };
        } else {
            this.setState({ showLeaveInAppBrowser: false })
        };
    }

    render() {
        return (
            <>
                {/* <IonPopover
                    isOpen={this.props.showFreeTrialPopover}
                    onDidDismiss={() => {
                        this.props.freeTrialPopover(false);
                    }}
                >
                    <IonGrid
                        className="freeTrialPopoverGrid"
                    >
                        <IonRow className="freeTrialPopoverGridRow">
                            <p className="freeTrialPopoverTitleParagraph">
                                Hai ottenuto 7 giorni di prova gratuita 🎉
                            </p>
                        </IonRow>

                        <IonRow className="freeTrialPopoverGridRow">
                            <p className="freeTrialPopoverRowParagraph">
                                Dopo la prova gratuita potrai decidere di acquistare le attività in anteprima.
                            </p>
                        </IonRow>

                        <IonRow className="freeTrialPopoverGridRow">
                            <IonButton
                                className="freeTrialPopoverConfirmButton"
                                onClick={() => {
                                    this.props.freeTrialPopover(false);
                                }}
                            >
                                Ok grazie
                            </IonButton>
                        </IonRow>
                        
                    </IonGrid>
                </IonPopover> */}

                {
                    this.props.showPayPopover &&
                    <div
                        className="featureManagerPayDiv"
                        onClick={() => {
                            this.props.payPopover(false);
                        }}
                    >
                        <div
                            className="featureManagerPayInnerDiv"
                            onClick={(e) => { e.stopPropagation() }}
                        >
                            <IonIcon onClick={() => this.props.payPopover(false)} icon={closeOutline} size='large' style={{ float: 'right' }} />

                            <p className="featureManagerPayTitle">
                                Sblocca tutte le funzionalità e i livelli futuri di <b>Completa la figura</b>
                            </p>
                            <p className="featureManagerPaySubtitle">
                                <i>Con l'acquisto avrai:</i>
                            </p>

                            <div className="featureManagerPayTextDiv">

                                <ul>
                                    <li>Immagini in bianco e nero</li>
                                    <li>Filtro immagini per categoria</li>
                                    <li>2 modalità di gioco (progressiva e personalizzata)</li>
                                </ul>

                                <p className="featureManagerPayFigureParagraph">
                                    1,99€ {/* TO BE LOCALIZED */}
                                </p>
                            </div>

                            <IonButton
                                className="featureManagerPayButton"
                                onClick={(e) => {
                                    this.setState({ isLoading: true });

                                    //const createCheckOutSession = httpsCallable(functions, 'test_createCheckoutSession-test_createCheckoutSession');
                                    const createCheckOutSession = httpsCallable(functions, 'createCheckoutSession-createCheckoutSession');
                                    /* createCheckOutSession({
                                        priceId: 'price_1Jb6kBJasdj1ggLrUT0DJvaH'
                                    }) */
                                    createCheckOutSession({
                                        priceId: "price_1LACNAJasdj1ggLrISOBq2PF"
                                    })
                                        .then(res => {
                                            console.log('[FeatureManagerPopovers] createCheckOutSession success', res);
                                            console.log('[FeatureManagerPopovers] createCheckOutSession success. Session id:', (res.data as any).sessionId);
                                            stripe.redirectToCheckout({
                                                sessionId: (res.data as any).sessionId
                                            })
                                                .then((data: any) => {
                                                    console.log('[FeatureManagerPopovers] checkout success redirection', data);
                                                    this.setState({ isLoading: false });
                                                })
                                                .catch((err: any) => {
                                                    console.log('[FeatureManagerPopovers] Err redirecting to checkout', err);
                                                    this.setState({ isLoading: false });
                                                });
                                        })
                                        .catch(err => {
                                            console.error('[FeatureManagerPopovers] Error createCheckOutSession:', err);
                                            this.setState({ isLoading: false });
                                        });
                                }}
                            >
                                Sblocca l'applicazione
                            </IonButton>
                        </div>

                    </div>
                }

                <IonLoading
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => {
                        this.setState({ isLoading: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={5000}
                />

                {/* <IonPopover
                isOpen={this.props.showPayPopover}
            >
                <div>
                    Ciao, paga.
                </div>
            </IonPopover> */}
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showFreeTrialPopover: state.featureManager.showFreeTrialPopover,
        showPayPopover: state.featureManager.showPayPopover,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        freeTrialPopover: (show: boolean) => {
            dispatch(featureManagerActions.freeTrialPopover(show));
        },
        payPopover: (show: boolean) => {
            dispatch(featureManagerActions.payPopover(show));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureManagerPopovers);
import { settingsConstants } from './constants';
import { Dispatch } from 'redux';

export const settingsActions = {
    colorImages,
    changeCategoryFilter,
    unmute,
    changeInteractionMethod,
    changeNumbersMode,
};

export const actionCreator = {
    colorImages: (color: 'colors' | 'bw') => { return { type: settingsConstants.GRAYSCALE_IMAGES, color } },
    changeCategoryFilter: (filter: string[]) => { return { type: settingsConstants.CHANGE_FILTER, filter } },
    changeInteractionMethod: (interactionMethod: 'click' | 'drag') => { return { type: settingsConstants.CHANGE_INTERACTION_METHOD, interactionMethod } },
    changeNumbersMode: (numbersMode: 'number' | 'letter') => { return { type: settingsConstants.CHANGE_NUMBERS_MODE, numbersMode } },
    unmute: (pressed: boolean) => { return { type: settingsConstants.UNMUTE_PRESSED, pressed } },
};

function colorImages(grayscale: 'colors' | 'bw') {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.colorImages(grayscale));
    };
}

function changeCategoryFilter(filter: string[]) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.changeCategoryFilter(filter));
    };
}

function changeInteractionMethod(interactionMethod: 'click' | 'drag') {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.changeInteractionMethod(interactionMethod));
    };
}

function changeNumbersMode(numbersMode: 'number' | 'letter') {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.changeNumbersMode(numbersMode));
    };
}

function unmute(pressed: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.unmute(pressed));
    };
}
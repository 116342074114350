import it1 from '../../../assets/counting_game/tts/numbers_it/1.mp3';
import it2 from '../../../assets/counting_game/tts/numbers_it/2.mp3';
import it3 from '../../../assets/counting_game/tts/numbers_it/3.mp3';
import it4 from '../../../assets/counting_game/tts/numbers_it/4.mp3';
import it5 from '../../../assets/counting_game/tts/numbers_it/5.mp3';
import it6 from '../../../assets/counting_game/tts/numbers_it/6.mp3';
import it7 from '../../../assets/counting_game/tts/numbers_it/7.mp3';
import it8 from '../../../assets/counting_game/tts/numbers_it/8.mp3';
import it9 from '../../../assets/counting_game/tts/numbers_it/9.mp3';
import it10 from '../../../assets/counting_game/tts/numbers_it/10.mp3';
export function switchNumberTts(numberToCount: number) {
    let numberToPlay
    switch (numberToCount) {
        case 1:
            numberToPlay = it1
            break;
        case 2:
            numberToPlay = it2
            break;
        case 3:
            numberToPlay = it3
            break;
        case 4:
            numberToPlay = it4
            break;
        case 5:
            numberToPlay = it5
            break;
        case 6:
            numberToPlay = it6
            break;
        case 7:
            numberToPlay = it7
            break;
        case 8:
            numberToPlay = it8
            break;
        case 9:
            numberToPlay = it9
            break;
        case 10:
            numberToPlay = it10
            break;

        default:
            break;
    }
    return numberToPlay
}
import { User } from 'firebase/auth';
import { Dispatch } from 'redux';
import { featureManagerConstants } from './constants';

export const featureManagerActions = {
    freeTrialPopover,
    payPopover,
};

export const actionCreator = {
    freeTrialPopover: (show: boolean) => { return { type: featureManagerConstants.FREE_TRIAL_POPOVER, show }},
    payPopover: (show: boolean) => { return { type: featureManagerConstants.PAY_POPOVER, show }},
    
};

function freeTrialPopover(show: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.freeTrialPopover(show));
    };
}

function payPopover(show: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.payPopover(show));
    };
}
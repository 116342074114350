import { matchConstants } from "./constants";

export interface MatchGameStore {
  points: number
}

const initialState: MatchGameStore = {
  points: 0,
}

const MatchGame = (state = initialState, action: any) => {

  switch (action.type) {

    case matchConstants.GET_SCORE:
      return {
        ...state,
        points: state.points
      };
    case matchConstants.ADD_POINT:
      return {
        ...state,
        points: state.points + 1
      };
    case matchConstants.RESET_SCORE:
      return {
        ...state,
        points: 0
      };

    default:
      return state

  }
}

export default MatchGame;

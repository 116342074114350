import { featureManagerConstants } from "./constants";

export interface AuthenticationStore  {
  showFreeTrialPopover: boolean,
  showPayPopover: boolean,
}

const initialState: AuthenticationStore = {
    showFreeTrialPopover: false,
    showPayPopover: false,
}

const FeatureManager = (state = initialState, action: any) => {

  switch (action.type) {

    case featureManagerConstants.FREE_TRIAL_POPOVER:
      return {
        ...state,
        showFreeTrialPopover: action.show
      }

    case featureManagerConstants.PAY_POPOVER:
      return {
        ...state,
        showPayPopover: action.show
      }

    default:
      return state

  }
}

export default FeatureManager;

import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonRow, IonButton, IonSpinner, isPlatform,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { fromQueryToObjOfParams } from '../../../common/queryUtils';
import { httpsCallable } from "firebase/functions";
import { auth, functions } from '../../../common/firebase';
import { featureManagerActions } from '../../../common/featureManager/actions';
import "./PaymentCompleted.css";

type Props = {
    history: any,

    isPaymentSuccessful: boolean,
    payPopover: (show: boolean) => void,
};

type State = {
    paymentCompleted: boolean,
    sessionId: string | undefined,
    isProcessingPurchase: boolean,

    urlNotValid: boolean,
    errorProcessingPurchase: boolean,
};

class PaymentCompleted extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            paymentCompleted: false,
            sessionId: undefined,
            isProcessingPurchase: false,

            urlNotValid: false,
            errorProcessingPurchase: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                if (this.props.history.location.search !== '') {
                    let sessionId = fromQueryToObjOfParams(this.props.history.location.search as string)["session_id"];
                    if (sessionId) {
                        this.setState({
                            sessionId: sessionId,
                            isProcessingPurchase: true,
                        });
                        const retrieveSession = httpsCallable(functions, 'retrieveSession-retrieveSession');
                        retrieveSession({
                            sessionId: sessionId,
                        })
                        .then((res) => {
                            console.log('[PaymentCompleted retrieveSession] success:', res);
                            if ((res.data as any).ok) {
                                this.setState({
                                    paymentCompleted: true,
                                    isProcessingPurchase: false,
                                    errorProcessingPurchase: false,
                                });
                                window.history.pushState({}, 'Gedu', '/payment-completed');
                            }
                            else {
                                console.error("[PaymentCompleted - retrieveSession] error returned by cloud function:", res.data);
                                this.setState({ errorProcessingPurchase: true });
                            }
                        })
                        .catch(err => {
                            console.error('[PaymentCompleted retrieveSession] error', err);
                            this.setState({ errorProcessingPurchase: true });
                        })
                    }
                }
                else {
                    if(!isPlatform("capacitor") && !isPlatform("cordova")) {
                        this.setState({ urlNotValid: true });
                    }
                }
            }
            else {
                // TO DO: authenticate user
            }
        })
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle>Pagamento effettuato</IonTitle> {/* TO LOCALIZE THIS */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="paymentCompletedGrid">

                    {
                        this.state.isProcessingPurchase &&
                        (!this.state.errorProcessingPurchase) &&
                        <div className="paymentCompletedProcessingPurchaseDiv">
                            <IonSpinner />

                            <p className="paymentCompletedProcessingPurchaseText">
                                Stiamo processando il pagamento {/* TO BE LOCALIZED */}
                            </p>
                            
                        </div>
                    }

                    {
                        this.state.errorProcessingPurchase &&
                        <div>
                            <p>
                                C'è stato un errore nel processare il pagamento. {/* TO BE LOCALIZED */}
                            </p>
                            <p>
                                Contatta il supporto al 366 112 6799. {/* TO BE LOCALIZED */}
                            </p>
                        </div>
                    }

                    {
                        (this.state.paymentCompleted ||
                        this.props.isPaymentSuccessful) &&
                        <div className="paymentCompletedMainDiv">
                            <div className="paymentCompletedTextAndButtonDiv">
                                <p className="paymentCompletedTextParagraph">
                                    Pagamento effettuato con successo!<br/>
                                    Il team estro ti ringrazia.
                                </p>

                                <IonButton
                                    className="paymentCompletedButton"
                                    routerLink="/home"
                                >
                                    Utilizza subito l'app
                                </IonButton>
                            </div>
                    
                            <svg className="paymentCompletedCheckmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="paymentCompletedCheckmarkCircle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="paymentCompletedCheckmarkCheck" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                        </div>
                    }

                    {
                        this.state.urlNotValid &&
                        <div>
                            <p>
                                Indirizzo non valido. {/* TO BE LOCALIZED */}
                            </p>
                            <p>
                                Contatta il supporto al <a href="tel:+393661126799">366 112 6799</a>. {/* TO BE LOCALIZED */}
                            </p>
                        </div>
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentSuccessful: state.purchases.paymentSuccess,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        payPopover: (show: boolean) => {
            dispatch(featureManagerActions.payPopover(show));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCompleted);
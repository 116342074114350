import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonButton, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonInput, IonToggle,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../Authentication/store/actions';
import { auth, database } from '../../../common/firebase';
import { ref, get, child } from "firebase/database";
import { fromDbPurchasesToArrayOfPurchases, fromPurchaseIdToName, Purchase } from "../../Purchases/utils";
import "./User.css";
import { userServices } from '../store/services';
import { Device } from '../store/reducers';
import { logoApple, logoAndroid } from 'ionicons/icons';

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    userEmail: string | undefined,
    payedVersion: string | null,
    purchases: Purchase[],
    userDevices: Device[],
    userUuid: string | null,
};

class User extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: undefined,
            payedVersion: null,

            purchases: [],
            userDevices: [],
            userUuid: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
                userServices.fetchDevices(userData.uid)
                    .then(devices => {
                        console.log("[UserSettings] get devices response:", devices)
                        this.setState({ userDevices: devices });
                    })
                    .catch(err => {
                        console.error("[Profile] get devices error:", err)
                    });
                const dbUserDataRef = ref(database);
                get(child(dbUserDataRef, `u/${userData.uid}`))
                    .then(data => {
                        if (data.exists()) {
                            if (data.val()["e"]) {
                                this.setState({
                                    userEmail: data.val()["e"],
                                });
                            }

                            if (data.val()["f"] && data.val()["f"]["y"]) {
                                this.setState({
                                    payedVersion: data.val()["f"]["y"],
                                })
                            }

                            if (data.val()["p"]) {
                                this.setState({ purchases: fromDbPurchasesToArrayOfPurchases(data.val()["p"]) });
                            }
                        }
                    })
                    .catch(err => {
                        console.log("[User] error getting financial data from user:", err);
                    });
            }
            else {
                this.setState({
                    userUuid: null,
                });
            }
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/home" />
                        </IonButtons>
                        <IonTitle>Profilo</IonTitle> {/* TO LOCALIZE THIS */}
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="userGrid">

                        <IonGrid className="userProfileGrid">

                            {
                                this.state.userEmail &&
                                <IonRow className="userDataRow">
                                    <p className="userDataEmailParagraph">
                                        {this.state.userEmail}
                                    </p>
                                </IonRow>
                            }

                            <div>
                                {
                                    this.state.purchases.map((purchase, i) => {
                                        return (
                                            <IonRow
                                                key={i}
                                                className="userPayedVersionInfoRow"
                                            >
                                                <p>
                                                    {fromPurchaseIdToName(purchase)}<br />
                                                    <i>Scade il: {(new Date(purchase.expiryTimestamp * 1000)).toLocaleDateString()}</i> alle {(new Date(purchase.expiryTimestamp * 1000)).toLocaleTimeString()}
                                                </p>
                                            </IonRow>
                                        )
                                    })
                                }
                            </div>

                            {
                                this.state.userDevices.length > 0 &&
                                <IonCard className="manageDevices">
                                    <IonCardHeader>
                                        <IonCardTitle>Dispositivi registrati</IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        {
                                            this.state.userDevices.map(device => {
                                                return (
                                                    <div
                                                        key={device.uuid}
                                                        className="userSettingsDeviceDiv"
                                                    >
                                                        <IonInput
                                                            value={device.name}
                                                            placeholder={"Nome del dispositivo"} /* TO LOCALIZE THIS */
                                                            onIonChange={e => {
                                                                userServices.editDeviceName(device.uuid, e.detail.value ? e.detail.value : "")
                                                                    .then(() => {
                                                                        if (this.state.userUuid) {
                                                                            userServices.fetchDevices(this.state.userUuid)
                                                                                .then(devices => {
                                                                                    //console.log("[UserSettings] get devices response:", devices)
                                                                                    this.setState({ userDevices: devices });
                                                                                })
                                                                                .catch(err => {
                                                                                    console.error("[UserSettings] get devices error:", err)
                                                                                })
                                                                        }
                                                                    })
                                                                    .catch(err => {
                                                                        //console.log("[UserSetting] editing device status error:", err);
                                                                    })
                                                            }}
                                                            debounce={1000}
                                                        />

                                                        {
                                                            device.type === 1 && // ios
                                                            <div className="userSettingsNotificationDeviceOsDiv">
                                                                <IonIcon
                                                                    slot="end"
                                                                    icon={logoApple}
                                                                    className="userSettingsNotificationDeviceOsIcon"
                                                                />
                                                            </div>

                                                        }

                                                        {
                                                            device.type === 2 && // android
                                                            <div className="userSettingsNotificationDeviceOsDiv">
                                                                <IonIcon
                                                                    slot="end"
                                                                    icon={logoAndroid}
                                                                    className="userSettingsNotificationDeviceOsIcon"
                                                                />
                                                            </div>
                                                        }


                                                        <div
                                                            className="userSettingsDeviceActiveToggleDiv"
                                                            slot="end"
                                                        >
                                                            <IonToggle
                                                                checked={device.isActive}
                                                                onIonChange={e => {
                                                                    userServices.editDeviceStatus(device.uuid, e.detail.checked)
                                                                        .then(() => {
                                                                            if (this.state.userUuid) {
                                                                                userServices.fetchDevices(this.state.userUuid)
                                                                                    .then(devices => {
                                                                                        //console.log("[UserSettings] get devices response:", devices)
                                                                                        this.setState({ userDevices: devices });
                                                                                    })
                                                                                    .catch(err => {
                                                                                        console.error("[UserSettings] get devices error:", err);
                                                                                    });
                                                                            }
                                                                        })
                                                                        .catch(err => {
                                                                            //console.log("[UserSetting] editing device status error:", err);
                                                                        })
                                                                }}
                                                            />
                                                            <p className="userSettingsDeviceActiveToggleLabel">
                                                                Notifiche push
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </IonCardContent>
                                </IonCard>
                            }


                            <IonRow className="userLogoutButtonRow">
                                <IonButton
                                    className="userLogoutButton"
                                    color="medium"
                                    fill="outline"
                                    onClick={() => {
                                        this.props.logout();
                                        setTimeout(() => {
                                            this.props.history.replace("/");
                                        }, 1000);
                                    }}
                                >
                                    Logout
                                </IonButton>
                            </IonRow>

                        </IonGrid>

                    </IonGrid>
                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
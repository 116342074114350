import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonList, IonItem, IonLabel, IonInput, IonRow,
    IonButton, IonToast, IonFab, IonFabButton, IonIcon
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth } from '../../../common/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";
import { helpCircleOutline } from 'ionicons/icons';

type LoginProps = {
    history: any,
    isAuthenticated: boolean,

    /* failed: (message: string) => void, */
};

type State = {
    error: boolean,
    email: string,
    password: string,

    errorToastOpen: boolean,
};

class Login extends React.Component<LoginProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            error: true,
            email: '',
            password: '',

            errorToastOpen: false,
        }
    }


    validateEmail(email: string | undefined | null) {
        if (email) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let error = !regex.test(email);
            this.setState({ email, error });
        }
        else {
            this.setState({
                email: '',
                error: true,
            })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Accedi</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="loginGrid">
                        <IonList lines='none'>

                            <IonItem
                                className='businessListItem'
                            >
                                <IonLabel
                                    position='floating'
                                >
                                    Email
                                </IonLabel>
                                <IonInput
                                    inputMode='email'
                                    type='email'
                                    autocomplete='email'
                                    onIonChange={(e) => this.validateEmail(e.detail.value)}
                                />
                            </IonItem>

                            <IonItem
                                className='businessListItem'
                            >
                                <IonLabel
                                    position='floating'
                                >
                                    Password
                                </IonLabel>
                                <IonInput
                                    placeholder='Password (almeno 8 caratteri)'
                                    inputmode='text'
                                    type='password'
                                    onIonChange={(e) => {
                                        if (e) {
                                            this.setState({ password: e.detail.value! })
                                        }
                                    }}
                                />
                            </IonItem>

                        </IonList>

                        <IonGrid>
                            <IonRow className="loginButtonRow">
                                <IonButton
                                    disabled={
                                        this.state.error ||
                                        (this.state.password.length < 8) ||
                                        (this.state.email === '')
                                    }
                                    onClick={() => {
                                        signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                                            .then(data => {
                                                console.log("Signin then:", JSON.stringify(data))
                                            })
                                            .catch(err => {
                                                console.log("Login error:", JSON.stringify(err));
                                                this.setState({ errorToastOpen: true });
                                            })
                                    }}
                                >
                                    Accedi
                                </IonButton>
                            </IonRow>

                            <IonRow className="loginSignupButtonRow">
                                <IonButton
                                    className="loginGoToSignupButton"
                                    fill="clear"
                                    routerLink="/authentication/signup"
                                >
                                    Non hai un account? Registrati
                                </IonButton>
                            </IonRow>

                            <IonRow className="loginSignupButtonRow">
                                <IonButton
                                    className="loginGoToSignupButton"
                                    fill="clear"
                                    color="medium"
                                    routerLink="/authentication/password-reset"
                                >
                                    Ho dimenticato la password
                                </IonButton>
                            </IonRow>
                            <IonRow className="loginSignupButtonRow">
                                <IonButton
                                    className="loginSupportButton"
                                    fill="clear"
                                    color="medium"
                                    routerLink="/support"
                                >
                                    Aiuto
                                    <IonIcon slot='start' size='large' icon={helpCircleOutline} />
                                </IonButton>
                            </IonRow>

                        </IonGrid>
                    </IonGrid>

                </IonContent>

                <IonToast
                    isOpen={this.state.errorToastOpen}
                    onDidDismiss={() => {
                        this.setState({ errorToastOpen: false });
                    }}
                    message="Errore. Password errata o account inesistente." /* TO LOCALIZE THIS */
                    duration={1500}
                />

                {
                    this.props.isAuthenticated && <Redirect to="/home" />
                }
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
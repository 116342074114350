import {
    IonIcon, IonPage,
} from '@ionic/react';
import React from "react";
import { chevronBack, notifications } from "ionicons/icons";
import Card from '../components/Card';
import { matchActions } from '../store/actions';
import { connect } from 'react-redux';
import { settingsActions } from '../../Settings/store/actions';
import { auth, database } from '../../../common/firebase';
import { ref, get, child, push } from "firebase/database";
import { CardObj, cardsObj } from '../components/cardsObj';
import { christmasCardsObj } from '../components/christmasCardsObj';
import "./MatchingCards.css";
import { fromGameToGameUuid } from '../../DataCollection/store/services';

interface CardChoice {
    card: CardObj,
    isCorrect: boolean,
}

type Props = {
    history: any,
    points: number,
    showColors: 'colors' | 'bw',
    categoryFilter: string[],
    unmutePressed: boolean,
    interactionMethod: 'click' | 'drag',


    colorImages: (color: 'colors' | 'bw') => void,
    getScore: () => void,
    addPoint: () => void,
    unmute: (pressed: boolean) => void,
}

type State = {
    draggableImages: CardChoice[],
    answerImage: CardObj | null,
    correctAnswerProvided: boolean,
    showTargetName: boolean,

    payedVersion: boolean
}

class MatchingCards extends React.Component<Props, State> {
    moving: any;

    constructor(props: any) {
        super(props);
        this.state = {
            draggableImages: [],
            answerImage: null,
            correctAnswerProvided: false,
            showTargetName: false,

            payedVersion: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                const dbUserDataRef = ref(database);
                get(child(dbUserDataRef, `u/${userData.uid}`))
                .then(data => {
                    if (data.exists() && data.val()["f"] && data.val()["f"]["y"]) {
                        this.setState({ payedVersion: true });
                    }
                    if (data.exists() && data.val()["p"]) {
                        let purchasesKeys = Object.keys(data.val()["p"]);
                        for (let i = 0; i < purchasesKeys.length; i++) {
                            let purchaseValue = Object.values(data.val()['p'])[i] as any
                            console.log(purchaseValue)
                            if (purchaseValue.e > Date.now() / 1000) {
                                this.setState({ payedVersion: true });
                            }
                        }
                        this.setState({ payedVersion: true });
                    }

                    this.generateStage();
                })
                .catch(err => {
                    console.error("[Matching Cards] error getting financial data from user:", err);
                })

                push(ref(database, `l/g/${userData.uid}`), {
                    g: fromGameToGameUuid("matching-cards"),
                    s: Math.floor((new Date()).getTime()/1000),
                })
                .then(ref => {
                    console.log("[MatchingCards] new game session key:", ref.key);
                })
                .catch(err => {
                    console.error("[MatchingCards] error pushing new game session:", err);
                })
            }
        });
    }

    generateStage(numberOfChoices: number = 3) {
        if (this.props.history.location.state) {
            if (this.props.history.location && this.props.history.location.state && (this.props.history.location.state.mode === 'progressive')) {
                switch (true) {
                    case this.props.points <= 5:
                        numberOfChoices = 3
                        break;
                    case this.props.points > 5 && this.props.points <= 10:
                        numberOfChoices = 4
                        break;
                    case this.props.points > 10 && this.props.points <= 15:
                        numberOfChoices = 5
                        break;
                    case this.props.points > 15 && this.props.points <= 20:
                        numberOfChoices = 6
                        break;
                    case this.props.points > 20:
                        numberOfChoices = 9
                        break;
                    default:
                        break;
                }
            }
            else {
                numberOfChoices = this.props.history.location.state.choices
            }
        }
        if (numberOfChoices === 4 || ((this.props.history.location && this.props.history.location.state && (this.props.history.location.state.mode === 'custom')) && this.props.history.location.state.choices === 4)) {
            document.getElementById('matchingCardsMovableElementsDiv')!.style.gridTemplateRows = 'repeat(2,auto)';
        }
        else {
            const cardMovableElementsDiv = document.getElementById('matchingCardsMovableElementsDiv');
            if (cardMovableElementsDiv) {
                cardMovableElementsDiv.style.gridTemplateRows = 'repeat(3,auto)';
            }
        }

        //let filteredCards = christmasCardsObj.filter((card) => {
        let filteredCards = cardsObj.filter((card) => {
            return this.props.categoryFilter.includes(card.category[0])
        })
        //let randomMax = Math.trunc(filteredCards.length / 6);
        let randomMax = 10;
        if (this.state.payedVersion) {
            randomMax = filteredCards.length;
        }

        const arrayOfCards = filteredCards.slice(0);
        arrayOfCards.splice(0, arrayOfCards.length - randomMax);

        const firstCardIndex = Math.floor(Math.random() * randomMax);
        const firstCard = arrayOfCards[firstCardIndex];
        arrayOfCards.splice(firstCardIndex, 1);
        randomMax -= 1;

        const draggableImages: CardChoice[] = [];
        draggableImages.push({
            card: firstCard,
            isCorrect: true,
        });

        // add other x random cards
        for (let i = 0; i < (numberOfChoices - 1); i++) {
            const cardIndex = Math.floor(Math.random() * randomMax);
            const selectedCard = arrayOfCards[cardIndex];
            arrayOfCards.splice(cardIndex, 1);
            randomMax -= 1;

            draggableImages.push({
                card: selectedCard,
                isCorrect: false,
            });
        }

        // SHUFFLE ARRAY
        for (let i = draggableImages.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            const temp = draggableImages[i]
            draggableImages[i] = draggableImages[j]
            draggableImages[j] = temp
        }

        for (let i = 0; i < draggableImages.length; i++) {
            this.preloadImage(draggableImages[i].card.full);
            this.preloadImage(draggableImages[i].card.half1);
            this.preloadImage(draggableImages[i].card.half2);
        }

        this.setState({
            draggableImages: draggableImages,
            answerImage: firstCard,
            correctAnswerProvided: false,
        });

    }

    pickup(e: any) {
        this.moving = e.target;
        this.moving.style.height = this.moving.clientHeight + 'px';
        this.moving.style.width = this.moving.clientWidth + 'px';
        this.moving.style.position = 'fixed';
        this.moving.style.zIndex = '-10';
    }

    move(e: any) {
        if (this.moving) {
            if (e.clientX) {
                // mousemove
                this.moving.style.left = e.clientX - this.moving.clientWidth + 'px';
                this.moving.style.top = e.clientY - this.moving.clientHeight / 2 + 'px';
            } else {
                // touchmove - assuming a single touchpoint
                this.moving.style.left = e.changedTouches[0].clientX - this.moving.clientWidth + 'px';
                this.moving.style.top = e.changedTouches[0].clientY - this.moving.clientHeight / 2 + 'px';
            }
        }

    }

    drop(e: any) {
        if (this.moving) {
            var x, y;
            if (e.clientX) {
                x = e.clientX;
                y = e.clientY;
            }
            else {
                x = e.changedTouches[0].clientX;
                y = e.changedTouches[0].clientY;
            }

            var hoveredElement = document.elementFromPoint(x, y);
            if (
                (hoveredElement?.id === 'matchingCardsAnswerImage') &&
                this.moving.parentElement?.getAttribute('id') === 'true'
            ) {
                const targetImg = document.getElementById("matchingCardsAnswerImage") as HTMLImageElement;
                if (targetImg) {
                    targetImg.style.marginRight = "0";
                }
                this.setState({ correctAnswerProvided: true });

                // pronounce image name
                if (this.state.answerImage) {
                    this.playAudio(this.state.answerImage.tts.it_it);
                }

                this.props.addPoint();
                this.setState({ showTargetName: true });


                setTimeout(() => {
                    targetImg.style.marginRight = "-35%";
                    this.generateStage();
                    this.setState({ showTargetName: false });
                }, 1500);
            }
            // reset our element
            this.moving.style.left = "unset";
            this.moving.style.top = "unset";
            /* this.moving.style.height = "";
            this.moving.style.width = ""; */
            this.moving.style.position = "unset";
            this.moving.style.zIndex = "unset";

            this.moving = null;
        }
    }

    preloadImage(url: string) {
        var img = new Image();
        img.src = url;
    }

    playAudio(audioUri: string) {
        const audioElement = (document.getElementById("matchingCardsAudioElement") as HTMLAudioElement);
        audioElement.src = audioUri;
        audioElement.playbackRate = 1.25; // TO FIX THIS
        audioElement.play();
    }

    handleClick(e: any) {
        if (e.target.parentElement?.getAttribute('id') === 'true') {
            e.target.style.pointerEvents = 'none';
            const targetImg = document.getElementById("matchingCardsAnswerImage") as HTMLImageElement;
            if (targetImg) {
                targetImg.style.marginRight = "0";
            }
            this.setState({ correctAnswerProvided: true });

            // pronounce image name
            if (this.state.answerImage) {
                this.playAudio(this.state.answerImage.tts.it_it);
            }

            this.props.addPoint();
            this.setState({ showTargetName: true });

            setTimeout(() => {
                targetImg.style.marginRight = "-35%";
                this.generateStage();
                e.target.style.pointerEvents = 'all'
                this.setState({ showTargetName: false });
            }, 1500);
        }

    }

    render() {
        return (
            <IonPage
                className="matchingCardsMainContainer"
                onMouseUp={(e) => {
                    this.drop(e)
                }}
                onTouchEnd={(e) => {
                    this.drop(e);
                }}
            >
                <audio
                    id="matchingCardsAudioElement"
                    autoPlay
                />
                <div className="matchingCardsHeaderDiv">
                    <div
                        className="matchingCardsBackDiv"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >
                        <IonIcon
                            icon={chevronBack}
                        />
                    </div>

                    {
                        this.props.unmutePressed &&
                        <p className="matchingCardsCallToActionParagraph">
                            Trascina la figura giusta<br />e completa l'immagine {/* TO LOCALIZE THIS */}
                        </p>
                    }

                    {
                        this.props.unmutePressed &&
                        <div className="matchingCardsPointsDiv">
                            <b>{this.props.points}</b>
                        </div>
                    }
                </div>

                <div
                    className="matchingCardsGameContainer"
                    onMouseMove={(e) => {
                        this.move(e)
                    }}
                    onTouchMove={(e) => {
                        this.move(e)
                    }}
                >
                    <div id='matchingCardsMovableElementsDiv' className="matchingCardsMovableElementsDiv">

                        {
                            this.state.draggableImages.map((image: CardChoice, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        id="matchingCardsMovableElement"
                                        className="matchingCardsMovableElement"
                                        onMouseUp={(e) => {
                                            this.drop(e);
                                        }}
                                        onTouchEnd={(e) => {
                                            this.drop(e)
                                        }}
                                    >
                                        <Card
                                            key={key}
                                            showColors={this.props.showColors}
                                            onMouseDown={(e) => {
                                                if (this.props.interactionMethod === 'drag') {
                                                    this.pickup(e)
                                                }
                                                else {
                                                    this.handleClick(e)
                                                }
                                            }
                                            }
                                            onTouchStart={(e) => this.pickup(e)}
                                            id={image.card.half1}
                                            src={image.card.half1}
                                            isCorrect={image.isCorrect}
                                        />
                                    </div>
                                )
                            })
                        }

                    </div>

                    {
                        this.state.answerImage &&
                        <div className='matchingCardsTargetCol'>

                            <div
                                id="matchingCardsTargetDiv"
                                className="matchingCardsTargetDiv"
                                onMouseUp={(e) => {
                                    this.drop(e)
                                }}
                                onTouchEnd={(e) => {
                                    this.drop(e);
                                }}
                            >
                                <img
                                    id="matchingCardsAnswerImage"
                                    className="matchingCardsAnswerImage"
                                    src={this.state.correctAnswerProvided ? this.state.answerImage.full : this.state.answerImage.half2}
                                    style={{
                                        filter: this.props.showColors ? '' : 'grayscale(1)',
                                    }}
                                />
                                {
                                    this.state.showTargetName &&
                                    <p className="matchingCardsTargetCardName">
                                        {this.state.answerImage.name.it_it.sing} {/* TO LOCALIZE THIS */}
                                    </p>
                                }
                            </div>

                        </div>
                    }
                </div>
                <div>
                </div>

                {
                    !this.props.unmutePressed &&
                    <div
                        className="matchingCardsUnmuteButtonDiv"
                        onClick={() => {
                            const audioElement = (document.getElementById("matchingCardsAudioElement") as HTMLAudioElement);
                            audioElement.muted = false;
                            this.props.unmute(true);
                        }}
                    >
                        <p className="matchingCardsUnmuteButtonParagraph">
                            Attiva l'audio
                        </p>
                        <IonIcon
                            className="matchingCardsUnmuteButtonIcon"
                            icon={notifications}
                        />
                    </div>
                }
            </IonPage>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        points: state.matchGame.points,
        showColors: state.settings.showColors,
        categoryFilter: state.settings.categoryFilter,
        unmutePressed: state.settings.unmutePressed,
        interactionMethod: state.settings.interactionMethod,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getScore: () => {
            dispatch(matchActions.getScore());
        },

        addPoint: () => {
            dispatch(matchActions.addPoint());
        },

        colorImages: (color: 'colors' | 'bw') => {
            dispatch(settingsActions.colorImages(color));
        },

        unmute: (pressed: boolean) => {
            dispatch(settingsActions.unmute(pressed));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchingCards);
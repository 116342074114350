import React from "react";
import '../pages/MatchingCards.css'


type Props = {
    isCorrect: boolean,
    src: string,
    id: string,
    showColors: 'colors' | 'bw',
    gameMode?: string,
    onMouseDown: (e: any) => void,
    onTouchStart: (e: any) => void,
}

type State = {
}

export default class Card extends React.Component<Props, State> {

    render() {
        return (
            <div
                className="cardCardDiv"
                onMouseDown={(e) => this.props.onMouseDown(e)}
                onTouchStart={(e) => this.props.onTouchStart(e)}
                id={this.props.isCorrect ? 'true' : 'false'}
            >
                <img
                    style={this.props.showColors === 'bw' ? { objectFit: this.props.gameMode === 'custom' ? 'unset' : 'scale-down', filter: 'grayscale(1)' } : this.props.gameMode === 'custom' ? { objectFit: 'unset' } : {}}
                    className='cardDraggableImage'
                    draggable='false'
                    id={this.props.id}
                    src={this.props.src}
                />
            </div>
        );
    }
};
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonPage, IonPopover, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { PushNotifications } from '@capacitor/push-notifications';
import { onValue, ref } from '@firebase/database';
import { Device } from '@ionic-native/device';
import { isPlatform } from '@ionic/core';
import { helpCircleOutline, lockClosed, person as personIcon } from 'ionicons/icons';
import completeThePicture from "../../../assets/menu/completeThePicture.png";
import controlKnobs from "../../../assets/menu/controlKnobs.png";
import fruitBasket from "../../../assets/menu/fruit_basket.svg";
import increasingChart from "../../../assets/icons/increasingChart.png";
import { featureManagerActions } from '../../../common/featureManager/actions';
import { auth, database } from '../../../common/firebase';
import { userServices } from '../../Profile/store/services';
import { fromDbPurchasesToArrayOfPurchases, isThereAFreeDemoVersion, isThereAnActivePayedLicense } from '../../Purchases/utils';
import { Player } from "@lottiefiles/react-lottie-player";
import notificationBell from "../../../assets/animations/notificationBell.json";
import "./Home.css";


type Props = {
    history: any,
    payPopover: (show: boolean) => void,

};

type State = {
    showMatchingCardsPopover: boolean,
    showCountingGamePopover: boolean,
    choices: number,
    payedVersion: boolean,
    demoVersion: boolean,
    userUuid: string | null,
    addDeviceBannerVisible: boolean,
};

class Home extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showMatchingCardsPopover: false,
            showCountingGamePopover: false,
            choices: 2,
            payedVersion: false,
            demoVersion: false,
            userUuid: '',
            addDeviceBannerVisible: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });

                const userProductsDbRef = ref(database, `u/${userData.uid}/p`)
                onValue(userProductsDbRef, async (data) => {
                    if (data.val()) {
                        const purchases = fromDbPurchasesToArrayOfPurchases(data.val());
                        this.setState({
                            payedVersion: isThereAnActivePayedLicense(purchases),
                            demoVersion: isThereAFreeDemoVersion(purchases),
                        });
                    }
                });
                if ((isPlatform('cordova') || isPlatform('capacitor'))) {
                    userServices.fetchDevices(userData.uid)
                        .then(devices => {
                            const filteredDevices = devices.filter(device => { return device.deviceId === Device.uuid })
                            if (filteredDevices.length < 1) {
                                this.setState({ addDeviceBannerVisible: true });
                            }
                        })
                        .catch(err => {
                            console.error("[TasksList] fetch devices error:", err); // TO REMOVE
                        });
                }
            }
            else {
                this.setState({ userUuid: null });
            }
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Home {/* TO BE LOCALIZED */}
                        </IonTitle>

                        <IonButtons slot='end'>

                            <IonButton
                                routerLink={`/profile`}
                            >
                                <IonIcon
                                    icon={personIcon}
                                />
                            </IonButton>

                            {
                                !this.state.payedVersion &&
                                <IonButton
                                    className="homeLockHeaderButton"
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        this.props.history.push("/purchase");
                                    }}
                                >
                                    <IonIcon
                                        icon={lockClosed}
                                    />
                                </IonButton>
                            }

                            {/* {
                                !this.state.payedVersion &&
                                <IonButton
                                    className="homeDiscountPurchaseHeaderButton"
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        this.props.history.push("/purchase");
                                    }}
                                >
                                    <IonIcon
                                        icon={discountIcon}
                                    />
                                </IonButton>
                            } */}

                        </IonButtons>
                    </IonToolbar>

                </IonHeader>

                <IonContent fullscreen>
                    {
                        this.state.addDeviceBannerVisible &&
                        <div>
                            <IonGrid
                                class='homeNotificationsAlertGrid'
                                onClick={() => {
                                    PushNotifications.requestPermissions().then(result => {
                                        if (result.receive === 'granted') {
                                            // Register with Apple or Google to receive push via APNS/FCM
                                            PushNotifications.register()
                                            .then(() => {
                                                console.log("[FilesManager] register push notification devices pressed");
                                                this.setState({ addDeviceBannerVisible: false });
                                            })
                                            .catch(err => {
                                                console.error("[FilesManager] error registering device:", err);
                                            });
                                        }
                                        else {
                                            console.error(`[pushNotificationHandler - requestPermission] not granted: ${JSON.stringify(result)}`);
                                        }
                                    })
                                        .catch(error => {
                                            console.error('[push notification requestPermission] error:', error)
                                        });
                                }}
                            >
                                <div className="homeNotificationsAlertPlayerDiv">
                                    <Player
                                        autoplay
                                        loop
                                        src={notificationBell}
                                    />
                                </div>
                                <IonRow>
                                    <IonText className="homeNotificationsAlertCTAText">
                                        <p>Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.</p> {/* TO BE LOCALIZED */}
                                    </IonText>
                                </IonRow>
                            </IonGrid>
                        </div>
                    }
                    <IonGrid className="homeMainGrid">

                        <div className="homeMenuDiv">
                            <div
                                className="homeMenuCard"
                                onClick={() => {
                                    if (this.state.demoVersion || this.state.payedVersion)
                                        this.setState({ showMatchingCardsPopover: true })
                                    else {
                                        this.props.history.push({
                                            pathname: "/matching-cards",
                                            state: {
                                                mode: 'custom',
                                                choices: 3
                                            },
                                        });
                                    }

                                }}

                                style={{ background: "lightblue" }}
                            >
                                <IonImg
                                    src={completeThePicture}
                                    className="homeMenuCardImg"
                                />

                                <p
                                    className="homeMenuCardTitle"
                                >
                                    Completa la figura {/* TO BE LOCALIZED */}
                                </p>

                            </div>
                            <div
                                className="homeMenuCard"
                                onClick={() => {
                                    if (this.state.demoVersion || this.state.payedVersion) {
                                        this.setState({ showCountingGamePopover: true })
                                    }
                                    else {
                                        this.props.history.push({
                                            pathname: "/counting",
                                            state: {
                                                mode: 'classic'
                                            },
                                        });
                                    }
                                }}

                                style={{ background: "#FF7A03A6" }}
                            >
                                <IonImg
                                    src={fruitBasket}
                                    className="homeMenuCardImg"
                                />

                                <p
                                    className="homeMenuCardTitle"
                                >
                                    Conta la frutta {/* TO BE LOCALIZED */}
                                </p>

                            </div>

                            <div
                                className="homeMenuCard"
                                onClick={() => {
                                    this.props.history.push("/settings");
                                }}
                                style={{ background: "lightgray" }}
                            >
                                <IonImg
                                    src={controlKnobs}
                                    className="homeMenuCardImg"
                                />

                                <p
                                    className="homeMenuCardTitle"
                                >
                                    Configurazioni {/* TO BE LOCALIZED */}
                                </p>

                            </div>

                            <div
                                className="homeMenuCard"
                                onClick={() => {
                                    this.props.history.push("/data-collection");
                                }}
                                style={{ background: "palegreen" }}
                            >
                                <IonImg
                                    src={increasingChart}
                                    className="homeMenuCardImg"
                                />

                                <p
                                    className="homeMenuCardTitle"
                                >
                                    Analisi {/* TO BE LOCALIZED */}
                                </p>

                            </div>
                        </div>

                        {/* CON HTTP E DA DESKTOP NON FUNZIONA, È NORMALE */}
                        {
                            !isPlatform("desktop") &&
                            <IonButton
                                className="homeMenuShareButton"
                                fill="clear"
                                onClick={() => {
                                    navigator.share({
                                        title: "GEDU",
                                        text: "Prova i giochi dell'app GEDU, pensata per persone con BES.",
                                        url: "https://gedu.page.link/app"
                                    })
                                }}
                            >
                                Condividi l'app {/* TO BE LOCALIZED */}
                            </IonButton>
                        }
                        <IonButton
                            className="loginSupportButton"
                            fill="clear"
                            color="medium"
                            routerLink="/support"
                        >
                            Aiuto {/* TO BE LOCALIZED */}
                            <IonIcon
                                slot='start'
                                size='large'
                                icon={helpCircleOutline}
                            />
                        </IonButton>
                    </IonGrid>
                </IonContent>

                {/* ---------- MATCHING CARDS -- MODE SELECT ----------*/}
                <IonPopover
                    isOpen={this.state.showMatchingCardsPopover}
                    onDidDismiss={() => {
                        this.setState({ showMatchingCardsPopover: false })
                    }}
                    cssClass='modeSelectionPopover'
                >
                    <h1 className='modeSelectionPopoverTitle'>
                        Seleziona la modalità di gioco
                    </h1>
                    <div className='modeSelectionPopoverMainDiv'>
                        <IonRow>
                            <IonCol className='modeSelectionPopoverCol'>
                                <div className='modeSelectionPopoverColContent'>
                                    <h1>Progressiva</h1>
                                    <ul>
                                        <li>La difficoltà aumenta mentre prosegui nel gioco</li>
                                        <br />
                                        <li>Il numero di opzioni disponibili aumenterà in base ai punti</li>
                                        <br />

                                        <li>Puoi resettare i punti nel menu <b>Impostazioni</b></li>
                                    </ul>
                                </div>
                                <IonButton
                                    fill='outline'
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: "/matching-cards",
                                            state: {
                                                mode: 'progressive',
                                                choices: 0
                                            },
                                        });
                                    }}
                                >
                                    Gioca
                                </IonButton>
                            </IonCol>
                            <IonCol className='modeSelectionPopoverCol'>
                                <div className='modeSelectionPopoverColContent'>
                                    <h1>Personalizzata</h1>
                                    <IonText>Scegli il numero di opzioni per il gioco</IonText>
                                    <div className='modeSelectionPopoverNumbersDiv'>
                                        {
                                            Array.from(Array(8), (e, i) => {
                                                return (
                                                    <IonSegment
                                                        key={i}
                                                        value={this.state.choices.toString()}
                                                    >
                                                        <IonSegmentButton value={(i + 2).toString()} onClick={() => { this.setState({ choices: i + 2 }) }} >
                                                            <IonLabel style={{ fontSize: '16px' }}>
                                                                {i + 2}
                                                            </IonLabel>
                                                        </IonSegmentButton>
                                                    </IonSegment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <IonButton
                                    fill='outline'
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: "/matching-cards",
                                            state: {
                                                mode: 'custom',
                                                choices: this.state.choices
                                            },
                                        });
                                    }}
                                >
                                    Gioca
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </IonPopover>

                {/* ---------- COUNTING GAME -- MODE SELECT ----------*/}
                <IonPopover
                    isOpen={this.state.showCountingGamePopover}
                    onDidDismiss={() => {
                        this.setState({ showCountingGamePopover: false })
                    }}
                    cssClass='modeSelectionPopover'
                >
                    <h1 className='modeSelectionPopoverTitle'>
                        Seleziona la modalità di gioco
                    </h1>
                    <div className='modeSelectionPopoverMainDiv'>
                        <IonRow>
                            <IonCol className='modeSelectionPopoverCol'>
                                <div className='modeSelectionPopoverColContent'>
                                    <h1>Classica</h1>
                                    <ul>
                                        <li>Le immagini con cui interagire sono uguali tra loro</li>
                                        <br />
                                        <li>Il numero di opzioni disponibili sarà casuale</li>
                                        <br />
                                    </ul>
                                </div>
                                <IonButton
                                    fill='outline'
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: "/counting",
                                            state: {
                                                mode: 'classic',
                                            },
                                        });
                                    }}
                                >
                                    Gioca
                                </IonButton>
                            </IonCol>
                            <IonCol className='modeSelectionPopoverCol'>
                                <div className='modeSelectionPopoverColContent'>
                                    <h1>Personalizzata</h1>
                                    <h2>Le immagini con cui interagire saranno diverse tra loro</h2>
                                    <IonText>Scegli quante opzioni (immagini) mostrare</IonText>
                                    <div className='modeSelectionPopoverNumbersDiv'>
                                        {
                                            Array.from(Array(5), (e, i) => {
                                                return (
                                                    <IonSegment
                                                        key={i}
                                                        value={this.state.choices.toString()}
                                                    >
                                                        <IonSegmentButton value={(i + 2).toString()} onClick={() => { this.setState({ choices: i + 2 }) }} >
                                                            <IonLabel style={{ fontSize: '16px' }}>
                                                                {i + 2}
                                                            </IonLabel>
                                                        </IonSegmentButton>
                                                    </IonSegment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <IonButton
                                    fill='outline'
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: "/counting",
                                            state: {
                                                mode: 'custom',
                                                choices: this.state.choices
                                            },
                                        });
                                    }}
                                >
                                    Gioca
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </IonPopover>

            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        payPopover: (show: boolean) => {
            dispatch(featureManagerActions.payPopover(show));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { Dispatch } from 'redux';
import { matchConstants } from './constants';

export const matchActions = {
    getScore,
    addPoint,
    resetScore,
};

export const actionCreator = {
    getScore: () => { return { type: matchConstants.GET_SCORE } },
    addPoint: () => { return { type: matchConstants.ADD_POINT } },
    resetScore: () => { return { type: matchConstants.RESET_SCORE } },
};

function getScore() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.getScore());
    };
}
function addPoint() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.addPoint());
    };
}
function resetScore() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.resetScore());
    };
}
import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

type Props = {
    history: any,
};

type State = {
};

class PrivacyPolicy extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: undefined,
        }
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>Privacy policy</IonTitle> {/* TO LOCALIZE THIS */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="userGrid">
                    <p>
                        Privacy Policy per fruizione servizi offerti da <i>app.gedu.it</i> (di seguito ”Sito”, “App”, 
                        “Piattaforma”, “Software”, "Estro", "Estro technologies srl"): software di attività educative.
                    </p>
                    <br />


                    <h2>Trattamento dei Dati personali</h2> Riferimenti legali
                    <p>
                        In conformità alle previsioni del Regolamento Europeo (UE) 2016/679 e del D.Lgs. 196/2003 e 
                        s.m.i., i Dati forniti dagli Utenti verranno trattati nel rispetto dei principi di correttezza, 
                        liceità, trasparenza, limitazione delle finalità e della conservazione, minimizzazione, 
                        esattezza, integrità e di tutela della riservatezza dei Dati stessi.
                    </p>
                    <p>
                        Ove non diversamente specificato, la presente informativa privacy riguarda esclusivamente <i>app.gedu.it</i>.
                    </p>
                    <br />


                    <h2>
                        Titolare del Trattamento Dati
                    </h2>
                    <p>
                        Titolare: Estro technologies srl<br/>
                        CF e PI del Titolare: 10964560964<br/>
                        Indirizzo del Titolare: Via Savona 123, 20144 Milano (MI).
                    </p>
                    <p>
                        Per eventuali richieste di informazione/aggiornamento/cancellazione dei Dati da parte degli 
                        Utenti contattare Estro ad uno dei seguenti indirizzi: <a href="https://estro.ai/contacts" target="_blank">contatti</a>.
                    </p>
                    <br />


                    <h3>1. Obbligo di Comunicazione</h3>
                    <p>
                        Con riferimento alle finalità di utilizzo evidenziate ai punti 2, 3 e 4
                        che seguono, si evidenzia che il conferimento dei Dati Personali è 
                        obbligatorio. Un eventuale rifiuto dell’Utente e/o il conferimento di 
                        informazioni inesatte e/o incomplete impedirebbe la prenotazione del 
                        bene/servizio tramite la Piattaforma.
                    </p>
                    <br />

                    <h3>2. Utilizzo dei dati di registrazione</h3>
                    <p>
                        I dati di registrazione (email) saranno utilizzati nella fase 
                        di accesso, in modo tale che la persona possa avere un'area riservata e 
                        sicura con cui utilizzare i servizi offerti dalla piattaforma.
                    </p>
                    <br />


                    <h3>3. Durata di conservazione dei dati</h3>
                    <p>
                        I dati relativi alla prenotazione di un bene/servizio saranno conservati dalla 
                        Piattaforma per 3 anni.<br />
                        I dati forniti dall’azienda relativi alla fatturazione per 
                        l’acquisto dell’abbonamento mensile, condizione necessaria per la 
                        pubblicazione dei beni/servizi sulla Piattaforma, saranno conservati per 10 
                        anni.
                    </p>
                    <br />

                    <h3>4. API di Terze Parti</h3>
                    <p>
                        Il pagamento online per l’acquisto dei servizi offerti dalla Piattaforma 
                        sarà effettuato tramite <a href='https://stripe.com/it' target='_blank' rel="noopener noreferrer">Stripe</a>.
                    </p>
                    <br />

                    <h3>5. Navigazione Sito web</h3>
                    <h4>5.1.Redirect verso siti esterni.</h4>
                    <p>
                        Il Sito potrebbe utilizzare i cosiddetti social plug-in, speciali strumenti 
                        che permettono di incorporare le funzionalità dei social network, come la 
                        funzione "mi piace" di Facebook, direttamente all’interno del Sito stesso.<br/>
                        Gli eventuali social plug-in presenti nel Sito sono contrassegnati dal rispettivo 
                        logo di proprietà della piattaforma di social network (ad es. Facebook, Google, Twitter, 
                        Linkedin).<br/>
                        Quando si visita una pagina del Sito e si interagisce con il plug-in (ad es. 
                        cliccando il pulsante "Mi piace") o si decide di lasciare un commento, le corrispondenti 
                        informazioni vengono trasmesse dal browser direttamente alla piattaforma di social network 
                        e da questo memorizzate.<br />
                        Per informazioni sulle finalità, il tipo e le modalità di raccolta, elaborazione, utilizzo 
                        e conservazione dei dati personali da parte della piattaforma di social network, nonché 
                        per le modalità attraverso cui esercitare i propri diritti, si prega di consultare la 
                        privacy policy adottata dal singolo social network medesimo.
                    </p>
                    <h4>5.2.Collegamento verso/da siti terzi.</h4>
                    <p>
                        Dal presente Sito è possibile collegarsi, mediante appositi link, verso altri siti web 
                        delle aziende, ovvero è possibile da siti web di terzi connettersi al nostro 
                        Sito.<br />
                        Il Titolare declina qualsiasi responsabilità in merito all’eventuale richiesta e/o rilascio 
                        di Dati Personali a siti terzi e in ordine alla gestione delle credenziali di autenticazione 
                        fornite da soggetti terzi.
                    </p>


                    <br/>
                    <br/>
                    <br/>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
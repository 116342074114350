import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonList, IonItem, IonLabel, IonInput, IonRow,
    IonButton, IonToast, IonButtons, IonBackButton
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth } from '../../../common/firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import "./Login.css";
import "./PasswordReset.css"

type LoginProps = {
    history: any,
    isAuthenticated: boolean,
};

type State = {
    error: boolean,
    email: string,

    errorToastOpen: boolean,
    resetPasswordEmailSent: boolean,
};

class PasswordReset extends React.Component<LoginProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            error: true,
            email: '',

            errorToastOpen: false,
            resetPasswordEmailSent: false,
        }
    }


    validateEmail(email: string | undefined | null) {
        if(email) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let error = !regex.test(email);
            this.setState({ email, error });
        }
        else {
            this.setState({
                email: '',
                error: true,
            })
        }
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref='/' />
                    </IonButtons>
                    <IonTitle>Password reset</IonTitle> {/* TO LOCALIZE THIS */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="passwordResetGrid">
                    <IonList lines='none'>

                        <IonItem
                            className='businessListItem'
                        >
                            <IonLabel
                                position='floating'
                            >
                                Email
                            </IonLabel>
                            <IonInput
                                inputMode='email'
                                type='email'
                                autocomplete='email'
                                onIonChange={(e) => this.validateEmail(e.detail.value)}
                            />
                        </IonItem>
                    </IonList>

                    <IonGrid>
                        <IonRow className="passwordResetResetButtonRow">
                            <IonButton
                                disabled={
                                    this.state.error ||
                                    this.state.email === ''
                                }
                                onClick={() => {
                                    sendPasswordResetEmail(auth, this.state.email)
                                    .then(() => {
                                        console.log("[PasswordReset] reset email sent.");
                                        this.setState({ resetPasswordEmailSent: true });
                                    })
                                    .catch(e => {
                                        console.error("[PasswordReset] error sending reset email:", e);
                                        this.setState({ errorToastOpen: true });
                                    })
                                }}
                            >
                                Reset password {/* TO LOCALIZE THIS */}
                            </IonButton>
                        </IonRow>

                        <IonRow className="loginSignupButtonRow">
                            <IonButton
                                className="loginGoToSignupButton"
                                fill="clear"
                                routerLink="/authentication/login"
                            >
                                Accedi
                            </IonButton>
                        </IonRow>

                        <IonRow className="loginSignupButtonRow">
                            <IonButton
                                className="loginGoToSignupButton"
                                fill="clear"
                                routerLink="/authentication/signup"
                            >
                                Creo un nuovo account
                            </IonButton>
                        </IonRow>

                    </IonGrid>
                </IonGrid>
            
            </IonContent>

            <IonToast
                isOpen={this.state.errorToastOpen}
                onDidDismiss={() => {
                    this.setState({ errorToastOpen: false });
                }}
                message="Errore. Account inesistente." /* TO LOCALIZE THIS */
                duration={1500}
            />

            <IonToast
                isOpen={this.state.resetPasswordEmailSent}
                onDidDismiss={() => {
                    this.setState({ resetPasswordEmailSent: false });
                }}
                message="Reset password inviato ✅" /* TO LOCALIZE THIS */
                duration={1500}
            />
            
            {
                this.props.isAuthenticated && <Redirect to="/home" /> 
            }
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
import { purchasesConstants } from './constants';
import { Dispatch } from 'redux';

export const purchasesActions = {
    loading,
    processing,
    canceled,
    error,
    clear,
};

export const actionCreator = {
    loading: (loading: boolean) => { return { type: purchasesConstants.PURCHASE_LOADING, loading }},
    processing: (processing: boolean) => { return { type: purchasesConstants.PURCHASE_PROCESSING, processing }},
    success: () => { return { type: purchasesConstants.PURCHASE_SUCCESS } },
    canceled: () => { return { type: purchasesConstants.PURCHASE_CANCELED } },
    error: () => { return { type: purchasesConstants.PURCHASE_ERROR } },
    clear: () => { return { type: purchasesConstants.CLEAR } },
};

function loading(isLoading: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.loading(isLoading));
    };
}

function processing(isProcessing: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.processing(isProcessing));
    };
}

function canceled() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.canceled());
    };
}

function error() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.error());
    };
}

function clear() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.clear());
    };
}

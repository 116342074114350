export interface Purchase {
    id: string,
    expiryTimestamp: number,

    from: "web" | "ios" | "android" | "free" | "transfer" | "pos" | "first-week-from-signup",

}

export function fromDbPurchasesToArrayOfPurchases(dbData: any): Purchase [] {
    const purchasesToReturn: Purchase[] = [];
    if(dbData) {
        const purchasesKeys = Object.keys(dbData);
        for (let i = 0; i < purchasesKeys.length; i++) {
            purchasesToReturn.push({
                id: dbData[purchasesKeys[i]].p,
                expiryTimestamp: dbData[purchasesKeys[i]].e,
                from: fromNumberToPlatform(dbData[purchasesKeys[i]].f),
            });            
        }
    }
    return purchasesToReturn;
}

export function isThereAnActivePayedLicense(purchases: Purchase[]): boolean {
    let filteredPurchases = purchases.filter(product => product.from !== "first-week-from-signup");
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    filteredPurchases = filteredPurchases.filter(purchase => purchase.expiryTimestamp > nowTimestamp);
    if(filteredPurchases.length > 0) {
        return true;
    }
    return false;
}

export function isThereAFreeDemoVersion(purchases: Purchase[]): boolean {
    let filteredPurchases = purchases.filter(product => product.from === "first-week-from-signup");
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    filteredPurchases = filteredPurchases.filter(purchase => purchase.expiryTimestamp > nowTimestamp);
    if(filteredPurchases.length > 0) {
        return true;
    }
    return false;
}

export function isMatchingCardsActive(purchases: Purchase[]): boolean {
    let filteredPurchases = purchases.filter(product => product.id === "it.gedu.app.matchingcards");
    filteredPurchases = filteredPurchases.concat(purchases.filter(product => product.id === "it.gedu.app.full"));
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    filteredPurchases = filteredPurchases.filter(purchase => purchase.expiryTimestamp > nowTimestamp);
    if(filteredPurchases.length > 0) {
        return true;
    }
    return false;
}

export function fromPurchaseIdToName(purchase: Purchase): string { /* TO BE LOCALIZED */
    let prefix = "";
    if(purchase.from === "first-week-from-signup") {
        prefix = "Demo - ";
    }
    switch (purchase.id) {
        case "it.gedu.app.matchingcards":
            return prefix + "Completa la figura";

        case "it.gedu.app.full":
            return prefix + "Licenza completa";

        case "it.gedu.app.full.nc":
            return prefix + "Licenza completa";
    
        default:
            return prefix + "";
    }
}

export function fromPlatformToNumber(platform: "web" | "ios" | "android" | "free" | "pos" | "transfer" | "first-week-from-signup"): number {
    switch (platform) {
        case "web":
            return 0; 
        case "ios":
            return 1;
        case "android":
            return 2;
        case "free":
            return 3;
        case "transfer":
            return 4;
        case "pos":
            return 5;
        case "first-week-from-signup":
            return -1;
            
        default:
            return 4;
    }
}

export function fromNumberToPlatform(platformNumber: number): "web" | "ios" | "android" | "free" | "transfer" | "pos" | "first-week-from-signup" {
    switch (platformNumber) {
        case 0:
            return "web";
        case 1:
            return "ios";
        case 2:
            return "android";
        case 3:
            return "free";
        case 4:
            return "transfer";
        case 5:
            return "pos";
        case -1:
            return "first-week-from-signup"
    
        default:
            return "transfer";
    }
}
import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonInput, IonRow, IonButton, IonList, IonItem,
    IonLabel,
    IonToggle,
    IonToast,
    IonIcon,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createUserWithEmailAndPassword } from "firebase/auth"
import { auth } from '../../../common/firebase';
import "./Signup.css";
import { helpCircleOutline } from 'ionicons/icons';

type LoginProps = {
    history: any,
    isAuthenticated: boolean,

    /* failed: (message: string) => void, */
};

type State = {
    error: boolean,
    email: string,
    password: string,

    termsAccepted: boolean,

    errorToastOpen: boolean,

    isWebview: boolean,
};

class Signup extends React.Component<LoginProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            error: true,
            email: '',
            password: '',

            termsAccepted: false,

            errorToastOpen: false,
            isWebview: false,
        }
    }
    componentDidMount() {
        let userAgent = window.navigator.userAgent.toLowerCase(),
            safari = /safari/.test(userAgent),
            ios = /iphone|ipod|ipad/.test(userAgent);
        if (ios) {
            if (safari) {
                console.log('browser')
            }
            else if (!safari) {
                this.setState({ isWebview: true })
            }
        }
    }

    validateEmail(email: string | undefined | null) {
        if (email) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let error = !regex.test(email);
            this.setState({ email, error });
        }
        else {
            this.setState({
                email: '',
                error: true,
            })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Registrati</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {!this.state.isWebview &&

                    <IonContent fullscreen>
                        <IonGrid className="signupGrid">
                            <IonList
                                lines="none"
                            >

                                <IonItem
                                >
                                    <IonLabel
                                        position='floating'
                                    >
                                        Email
                                    </IonLabel>
                                    <IonInput
                                        inputMode='email'
                                        type='email'
                                        autocomplete='email'
                                        onIonChange={(e) => this.validateEmail(e.detail.value)}
                                    />
                                </IonItem>

                                <IonItem>
                                    <IonLabel
                                        position='floating'
                                    >
                                        Password
                                    </IonLabel>
                                    <IonInput
                                        placeholder='Password (almeno 8 caratteri)'
                                        inputmode='text'
                                        type='password'
                                        onIonChange={(e) => {
                                            if (e) {
                                                this.setState({ password: e.detail.value! })
                                            }
                                        }}
                                    />
                                </IonItem>

                            </IonList>

                            <IonGrid>

                                <IonRow className="signupTermsAndToggleRow">
                                    <p className="signupTermsParagraph">
                                        Ho letto e accetto la <a href="/legal/privacy-policy">privacy policy</a><br />e i <a href="/legal/terms-of-service">termini di servizio</a>
                                    </p>
                                    <IonToggle
                                        className="signupTermsToggle"
                                        checked={this.state.termsAccepted}
                                        onIonChange={(e) => {
                                            this.setState({
                                                termsAccepted: e.detail.checked
                                            })
                                        }}
                                    />
                                </IonRow>

                                <IonRow className="signupButtonRow">
                                    <IonButton
                                        disabled={
                                            this.state.error ||
                                            (this.state.password.length < 8) ||
                                            (this.state.email === '') ||
                                            !this.state.termsAccepted
                                        }
                                        onClick={() => {
                                            createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
                                                .then(data => {
                                                    console.log("Signin then:", JSON.stringify(data))
                                                })
                                                .catch(err => {
                                                    console.error("[Signup] create account error:", JSON.stringify(err));
                                                    this.setState({ errorToastOpen: true });
                                                })
                                        }}
                                    >
                                        Crea un nuovo account
                                    </IonButton>
                                </IonRow>

                                <IonRow className="signupLoginLinkRow">
                                    <IonButton
                                        fill="clear"
                                        routerLink="/authentication/login"
                                        className="signupGoToLoginButton"
                                    >
                                        Hai già un account? Accedi
                                    </IonButton>
                                </IonRow>
                                <IonRow className="loginSignupButtonRow">
                                    <IonButton
                                        className="loginSupportButton"
                                        fill="clear"
                                        color="medium"
                                        routerLink="/support"
                                    >
                                        Aiuto
                                        <IonIcon slot='start' size='large' icon={helpCircleOutline} />
                                    </IonButton>
                                </IonRow>
                            </IonGrid>
                        </IonGrid>

                    </IonContent>
                }
                {this.state.isWebview &&
                    <div>
                        <p>Accedi dal tuo browser: app.gedu.it</p>
                        <IonButton onClick={() => window.open('http://app.gedu.it')}>Vai a Gedu</IonButton>
                    </div>
                }
                <IonToast
                    isOpen={this.state.errorToastOpen}
                    onDidDismiss={() => {
                        this.setState({ errorToastOpen: false });
                    }}
                    message="Errore. Password troppo corta o account già esistente." /* TO LOCALIZE THIS */
                    duration={1500}
                />

                {
                    this.props.isAuthenticated && <Redirect to="/home" />
                }
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
import {
	IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader,
	IonCardTitle, IonContent, IonGrid, IonHeader, IonIcon, IonButton, IonPage,
	IonRow, IonText, IonToolbar, IonTitle
} from '@ionic/react';
import { call, logoWhatsapp, mail } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import './Support.css';

type Props = {
	history: any,
}

type State = {
}

class Support extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
		}
	}
	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref='/' />
						</IonButtons>

						<IonTitle>Assistenza</IonTitle>
					</IonToolbar>
				</IonHeader>


				<IonContent fullscreen>

					<IonGrid className='supportGrid'>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Contattaci</IonCardTitle>
							</IonCardHeader>

							<IonCardContent>
								<IonGrid className='profileSupportGrid'>
									<IonRow
										onClick={() => { window.open('tel:+393661126799') }}
										className='supportRow'
									>
										<IonButton fill='clear'>
											<IonIcon
												icon={call}
												size='large'
												className='supportIcon'
											/>
											<IonText className='supportLabel'>
												Telefono
											</IonText>
										</IonButton>
									</IonRow>

									<IonRow
										onClick={() => { window.open('mailto:support@estro.ai'); }}
										className='supportRow'
									>
										<IonButton fill='clear'>
											<IonIcon
												icon={mail}
												size='large'
												className='supportIcon'
											/>
											<IonText className='supportLabel'>
												Email
											</IonText>
										</IonButton>
									</IonRow>

									<IonRow
										onClick={() => { window.open('https://wa.me/393661126799'); }}
										className='supportRow'
									>
										<IonButton fill='clear'>
											<IonIcon
												icon={logoWhatsapp}
												size='large'
												className='supportIcon'
											/>
											<IonText className='supportLabel'>
												WhatsApp
											</IonText>
										</IonButton>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonGrid>



				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
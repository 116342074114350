import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';

import Login from './modules/Authentication/pages/Login';
import Signup from './modules/Authentication/pages/Signup';
import Home from './modules/Home/pages/Home';
import MatchingCards from './modules/MatchingCards/pages/MatchingCards';
import User from './modules/Profile/pages/User';
import TermsOfService from './modules/Legal/pages/TermsOfService';
import PrivacyPolicy from './modules/Legal/pages/PrivacyPolicy';
import Settings from './modules/Settings/pages/Settings';
import PasswordReset from './modules/Authentication/pages/PasswordReset';
import Support from './modules/Support/pages/Support';
import GetStarted from './modules/Authentication/pages/GetStarted';
import Counting from './modules/CountingGame/pages/Counting';
import Purchase from './modules/Purchases/pages/Purchase';
import PaymentProcessing from './modules/Purchases/pages/PaymentProcessing';
import PaymentCompleted from './modules/Purchases/pages/PaymentCompleted';
import DataCollectionHome from './modules/DataCollection/pages/DataCollectionHome';

function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    /* console.log("isAuthenticated", isAuthenticated); */
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/authentication/get-started" />}
        />
    );
}

function Router() {
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>
                    <Route exact path="/authentication/get-started" component={GetStarted} />
                    <Route path="/authentication/signup" component={Signup} />
                    <Route path="/authentication/login" component={Login} />
                    <Route path="/authentication/password-reset" component={PasswordReset} />

                    <Route path="/legal/terms-of-service" component={TermsOfService} />
                    <Route path="/legal/privacy-policy" component={PrivacyPolicy} />

                    <Route path="/support" component={Support} exact={true} />

                    <PrivateRoute
                        exact path="/home"
                        render={(routeProps: any) => <Home  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/matching-cards"
                        render={(routeProps: any) => <MatchingCards  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/counting"
                        render={(routeProps: any) => <Counting  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/data-collection"
                        render={(routeProps: any) => <DataCollectionHome  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <User {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/settings"
                        render={(routeProps: any) => <Settings {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/purchase"
                        render={(routeProps: any) => <Purchase  {...routeProps} />}
                    />

                    <PrivateRoute 
                        exact path="/processing-payment"
                        render={(routeProps: any) => <PaymentProcessing  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/payment-completed"
                        render={(routeProps: any) => <PaymentCompleted  {...routeProps} />}
                    />

                    <Redirect to="/home" />

                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;
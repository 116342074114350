import { isPlatform } from "@ionic/core";
import { AppStoreReceipt, IAPProduct, InAppPurchase2, PlayStoreReceipt } from '@awesome-cordova-plugins/in-app-purchase-2';
import { store as reduxStore } from "../common/store";
import { actionCreator } from "../modules/Purchases/store/actions";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const store = InAppPurchase2;

function storeHandler() {

	if (isPlatform('capacitor') || isPlatform('cordova')) {
		store.register({
			id: "it.gedu.app.full.nc",
			type: store.NON_CONSUMABLE,
		});

		store.when("it.gedu.app.full.nc").registered((product: IAPProduct) => {
			console.log('[storeHandler] Registered:', JSON.stringify(product));
		});

		store.when("it.gedu.app.full.nc").approved((product: IAPProduct) => {
			//console.log("[storeHandler] transaction Approved", JSON.stringify(product));
			//console.log("[storeHandler] transaction approved");
			product.finish();
		});

		store.when("it.gedu.app.full.nc").finished((product: IAPProduct) => {
			console.log("[storeHandler] transaction finished. Type:", product.transaction?.type);
			/* if(product.transaction.type === "ios-appstore") {
				const transaction = (product.transaction as AppStoreReceipt);
				//console.log("[storeHandler] transaction finished:", product);
			}
			else if(product.transaction.type === "android-playstore") {
				const transaction = (product.transaction as PlayStoreReceipt);
				const androidReceipt = httpsCallable(functions, "androidReceipt-androidReceipt");
				androidReceipt({
					transaction: transaction,
					receipt: transaction.receipt
				})
				.then(response => {
					//console.log("[storeHandler] androidReceipt cloud functions response:", response.data);
				})
				.catch(err => {
					console.error("[storeHandler] androidReceipt cloud functions error:", err);
				});
			} */
		});

		store.when("it.gedu.app.full.nc").owned((product: IAPProduct) => {
			console.log("[storeHandler] User owns gedu full");

			if (product.transaction?.type === "ios-appstore") {
				const transaction = (product.transaction as AppStoreReceipt);
				reduxStore.dispatch(actionCreator.processing(true));
				console.log("[storeHandler] owned receipt", transaction.appStoreReceipt);
				const appleReceipt = httpsCallable(functions, "appleReceipt-appleReceipt");
				appleReceipt({
					receipt: transaction.appStoreReceipt,
				})
					.then(response => {
						reduxStore.dispatch(actionCreator.success());
						console.log("[storeHandler] appleReceipt cloud functions response:", response.data); // TO REMOVE
						if ((response.data as any).newPurchases) {
							//window.location.replace(`/payment-completed`);
						}
					})
					.catch(err => {
						console.error("[storeHandler] appleReceipt cloud functions error:", err);
						reduxStore.dispatch(actionCreator.processing(false));
						reduxStore.dispatch(actionCreator.error());
					})
			}
			else if (product.transaction?.type === "android-playstore") {
				const transaction = (product.transaction as PlayStoreReceipt);
				//console.log("[storeHandler] Purchase token:", transaction.purchaseToken);
				reduxStore.dispatch(actionCreator.processing(true));
				//console.log("[storeHandler] owned receipt:", transaction.receipt);
				const androidReceipt = httpsCallable(functions, "androidReceipt-androidReceipt");
				androidReceipt({
					transaction: transaction,
					receipt: transaction.receipt
				})
					.then(response => {
						reduxStore.dispatch(actionCreator.success());
						//console.log("[storeHandler] androidReceipt cloud functions response:", response.data);
						if ((response.data as any).newPurchases) {
							// window.location.replace(`/payment-completed`);
						}
					})
					.catch(err => {
						console.error("[storeHandler] androidReceipt cloud functions error:", err);
						reduxStore.dispatch(actionCreator.processing(false));
						reduxStore.dispatch(actionCreator.error());
					});
			}
		})
       

		// Updated
		store.when("it.gedu.app.full.nc").updated((product: IAPProduct) => {
			console.log("[storeHandler] Updated event:", JSON.stringify(product));
			//console.log("[storeHandler] Updated event. Transaction type:", product.transaction.type);
		});

		// User closed the native purchase dialog
		store.when("it.gedu.app.full.nc").cancelled((product: IAPProduct) => {
			console.error('[storeHandler] Purchase was Canceled', product);
			reduxStore.dispatch(actionCreator.canceled());
		});

		store.when("it.gedu.app.full.nc").error((product: IAPProduct) => {
			console.error('[storeHandler] Purchase error:', product);
			reduxStore.dispatch(actionCreator.error());
		});

		// Track all store errors
		store.error((err: any) => {
			console.error('Store Error ' + JSON.stringify(err));
		});

		// Run some code only when the store is ready to be used
		store.ready(() => {
			//console.log('[storeHandler] Store is ready.');
			//console.log("[storeHandler] Store get gedu product");
			store.get("it.gedu.app.full.nc");
			// store.autoFinishTransactions = true; // NOT FOR PRODUCTION
		});

		store.refresh();
	}
}

export default storeHandler;
export function positionElement(clickedElement: any, basket: any) {
    let basketPosition = basket.getBoundingClientRect();
    clickedElement.style.position = 'absolute'
    if (window.screen.width < window.screen.height) {
        clickedElement.style.width = "12vw"
        clickedElement.style.height = "12vw"
        clickedElement.style.left = (basketPosition.left + basket.offsetWidth / 2) - clickedElement.offsetWidth + (Math.ceil(Math.random() * (50 + 150)) - 50) + 'px'
        clickedElement.style.top = (basketPosition.top + basket.offsetHeight / 2) - clickedElement.offsetHeight + (Math.ceil(Math.random() * (50 + 50)) - 50) + 'px'
    }
    else {
        clickedElement.style.width = "8vw"
        clickedElement.style.height = "8vw"
        clickedElement.style.left = (basketPosition.left + basket.offsetWidth / 2) - clickedElement.offsetWidth + (Math.ceil(Math.random() * (100 + 200)) - 100) + 'px'
        clickedElement.style.top = (basketPosition.top + basket.offsetHeight / 2) - clickedElement.offsetHeight + (Math.ceil(Math.random() * (100 + 100)) - 100) + 'px'
        // clickedElement.style.left = (Math.random() * (basketPosition.right - basketPosition.left) + basketPosition.left - 50) + "px"
        // clickedElement.style.top = (Math.random() * (basketPosition.top + 20 - basketPosition.bottom - 50) + basketPosition.bottom) + "px"
    }

    clickedElement.style.zIndex = "100"
}
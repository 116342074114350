import { Plugins } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import {
    FirebaseDynamicLinks,
    LinkConfig,
} from '@pantrist/capacitor-firebase-dynamic-links';

import { isSignInWithEmailLink, parseActionCodeURL, signInWithEmailLink } from "firebase/auth";
import { auth } from "./firebase";

const CapacitorFirebaseDynamicLinks = FirebaseDynamicLinks;

function magicLinkHandler() {
    if (isPlatform('capacitor') || isPlatform('cordova')) {
        CapacitorFirebaseDynamicLinks.addListener('deepLinkOpen', (data: { url: string }) => {
            console.log("[magicLinkHandler] dynamic link:", JSON.stringify(data));

            console.log("isSignInWithEmailLink?", isSignInWithEmailLink(auth, data.url)); // TO REMOVE

            if (isSignInWithEmailLink(auth, data.url)) {
                let email = window.localStorage.getItem('emailForSignIn');
                console.log("emailForSignIn", email);

                console.log("parseActionCodeURL(data.url)", JSON.stringify(parseActionCodeURL(data.url)))

                if (email)
                    signInWithEmailLink(auth, email, data.url)
                        .then((result) => {
                            // Clear email from storage.
                            console.log("[magicLinkHandler] signin result:", result);
                            window.localStorage.removeItem('emailForSignIn');
                            window.location.replace("/home");
                        })
                        .catch((error) => {
                            //console.log("[magicLinkHandler] error signin in wih email link", error.code, error.message);
                        });
            }
            else {

                // TO DO: protect urls redirects

                //console.log("[magicLinkHandler] standard url");
                let url = new URL(data.url);

                if (url && url.toString().startsWith('https://app.gedu.it')) {
                    window.location.replace(url.pathname + url.search);
                }
                else {
                    window.open(url.toString(), '_system');
                }

                //console.log("[magicLinkHandler] url path name:", url.pathname);
                //window.location.replace(url.pathname);  // TO EDIT
            }
        })
    }
}

export default magicLinkHandler;
import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, User} from 'firebase/auth';
import { getDatabase, ref, get, update, push } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { authActions } from "../modules/Authentication/store/actions";
import { featureManagerActions } from "./featureManager/actions";
import { appVersion } from "../appVersion";
import { isPlatform } from "@ionic/react";

export const firebaseConfig = {
	apiKey: "AIzaSyB9XM4jecJmjG8-rmIh2r18QX5oO1xSkfo",
	authDomain: "estro-gedu.firebaseapp.com",
	databaseURL: "https://estro-gedu-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "estro-gedu",
	storageBucket: "estro-gedu.appspot.com",
	messagingSenderId: "851021838122",
	appId: "1:851021838122:web:9486602e8189447dc91053",
	measurementId: "G-57NJFYY5L1"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const auth =
	isPlatform("ios") ?
		initializeAuth(app, {
			persistence: indexedDBLocalPersistence
		}) :
		getAuth();
export const database = getDatabase();
export const functions = getFunctions(undefined, "europe-west1");


type Props = {
	authenticationSuccess: (userData: User) => void,
	logout: () => void,

	/* getUserData: (uid: string) => void, */
	freeTrialPopover: (show: boolean) => void,
	payPopover: (show: boolean) => void,
};

type State = {
};

class Authentication extends React.Component<Props, State> {

	componentDidMount() {
		auth.onAuthStateChanged(async (userData) => {
			if (userData) {
				this.props.authenticationSuccess(userData);
				
				// get user data 
				get(ref(database, `u/${userData.uid}`))
				.then(data => {
					const nowTimestamp = Math.round(new Date().getTime()/1000);
					if(data.exists()) {
						// console.log("Existing user data:", data.val());
						if(data.val()["t"]) {
							const signupDate = new Date(data.val()["t"]*1000);
							const nowDate = new Date();
							const dayBetweenSignupAndNow = (nowDate.valueOf() - signupDate.valueOf())/(1000 * 60 * 60 * 24)
							if(dayBetweenSignupAndNow > 1) {
								if(data.val()["f"] && data.val()["f"]["y"]) {
									//console.log("Ha pagato quindi sblocca");
								}
							}
							else {
								//console.log("Ancora nel periodo di prova")
								this.props.freeTrialPopover(true);
							}
						}

						if(!data.val()["e"]) {
							update(ref(database, `u/${userData.uid}`), {
								e: userData.email,
							})
							.catch(err => {
								console.error("[firebase] error updating user email:", err);
							});
						}
					}
					else {
						update(ref(database, `u/${userData.uid}`), {
							t: nowTimestamp,
							e: userData.email,
						})
						.catch(err => {
							console.error("[firebase] updating user email and signup timestamp:", err);
						})
						this.props.freeTrialPopover(true);
					}

					push(ref(database, `l/a/${userData.uid}`), {
						t: nowTimestamp,
						v: appVersion,
					})
					.catch(err => {
						console.error("[firebase] error l:", err);
					})

				})
				.catch(err => {
					console.error("Error getting user data:", err);
				});

			}
			else {
				console.log("[Firebase] We did not authenticate.");
				this.props.logout();
			}
		});
	}

	render() {
		return (
			<></>
		);
	}
}


const mapDispatchToProps = (dispatch: any) => {
	return {
		authenticationSuccess: (userData: User) => {
			dispatch(authActions.authenticationSuccess(userData));
		},

		logout: () => {
			dispatch(authActions.logout());
		},

		freeTrialPopover: (show: boolean) => {
			dispatch(featureManagerActions.freeTrialPopover(show));
		},

		payPopover: (show: boolean) => {
			dispatch(featureManagerActions.payPopover(show));
		}
	}
}

export default connect(null, mapDispatchToProps)(Authentication);
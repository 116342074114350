import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
/* import logger from 'redux-logger'; // NOT FOR PRODUCTION */

import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import Authentication from '../modules/Authentication/store/reducers';
import FeatureManager from './featureManager/reducers';
import MatchGame from '../modules/MatchingCards/store/reducers';
import Settings from '../modules/Settings/store/reducers';
import Purchases from '../modules/Purchases/store/reducers';

/* const middlewares = [thunk, logger]; */
const middlewares = [thunk];

const rootReducer = combineReducers({
    auth: Authentication,
    matchGame: MatchGame,
    featureManager: FeatureManager,
    settings: Settings,
    purchases: Purchases,
});

const migrations = {
    0: (state: any) => {
        return {
            ...state,
            purchases: {
                isLoadingPayment: false,
                isProcessingPayment: false,
                paymentSuccess: false,
                paymentCanceled: false,
                paymentError: false,
            }
        }
    }
}  

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["featureManager", "purchases"],
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor }
import {
    IonButton,
    IonIcon, IonPage,
} from '@ionic/react';
import React from "react";
import { chevronBack, notifications } from "ionicons/icons";
import { connect } from 'react-redux';
import { settingsActions } from '../../Settings/store/actions';
import { auth, database } from '../../../common/firebase';
import { ref, get, child, push } from "firebase/database";
import "./Counting.css";
import basket from '../../../assets/counting_game/basket.png'
import { CardObj, cardsObj } from '../../MatchingCards/components/cardsObj';
import Card from '../../MatchingCards/components/Card';
import { matchActions } from '../../MatchingCards/store/actions';

import trascina from '../../../assets/counting_game/tts/trascina.mp3';
import cesto from '../../../assets/counting_game/tts/nel_cesto.mp3';
import ReactCanvasConfetti from 'react-canvas-confetti';
import confetti from 'canvas-confetti';
import { switchNumberTts } from '../utils/switchNumberTts';
import { numberToLetters } from '../utils/numberToLetters';
import { positionElement } from '../utils/positionElement';
import { isMobile } from 'react-device-detect';
import { fromGameToGameUuid } from '../../DataCollection/store/services';

interface CardChoice {
    isCorrect: boolean,
    card: CardObj,
}

type Props = {
    history: any,
    points: number,
    showColors: 'colors' | 'bw',
    categoryFilter: string[],
    interactionMethod: 'click' | 'drag',
    unmutePressed: boolean,
    numbersMode: 'number' | 'letters',

    colorImages: (color: 'colors' | 'bw') => void,
    getScore: () => void,
    addPoint: () => void,
    unmute: (pressed: boolean) => void,
}

type State = {
    draggableImages: CardChoice[],
    correctAnswerProvided: boolean,
    answerImage: CardObj | null,

    payedVersion: boolean,
    numberToCount: number,
    currentCount: number,

    movedElements: any[]
}

class Counting extends React.Component<Props, State> {
    moving: any;

    constructor(props: any) {
        super(props);
        this.state = {
            draggableImages: [],
            correctAnswerProvided: false,
            answerImage: null,

            payedVersion: false,
            numberToCount: 1,
            currentCount: 0,

            movedElements: []
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                const dbUserDataRef = ref(database);
                get(child(dbUserDataRef, `u/${userData.uid}`))
                .then(data => {
                    // console.log(data.val()['p']);

                    if (data.exists() && data.val()["f"] && data.val()["f"]["y"]) {
                        this.setState({ payedVersion: true });
                    }
                    if (data.exists() && data.val()["p"]) {
                        let purchasesKeys = Object.keys(data.val()["p"]);
                        for (let i = 0; i < purchasesKeys.length; i++) {
                            let purchaseValue = Object.values(data.val()['p'])[i] as any
                            // console.log(purchaseValue)
                            if (purchaseValue.e > Date.now() / 1000) {
                                this.setState({ payedVersion: true });
                            }
                        }
                        this.setState({ payedVersion: true });
                    }
                    this.generateStage();
                })
                .catch(err => {
                    console.error("[Counting] error getting financial data from user:", err);
                });

                push(ref(database, `l/g/${userData.uid}`), {
                    g: fromGameToGameUuid("counting-game"),
                    s: Math.floor((new Date()).getTime()/1000),
                })
                .then(ref => {
                    console.log("[Counting] new game session key:", ref.key);
                })
                .catch(err => {
                    console.error("[Counting] error pushing new game session:", err);
                })
            }
        });
    }

    async generateStage(numberOfChoices: number = 10) {
        this.setState({ currentCount: 0, numberToCount: 0, draggableImages: [] })
        let numberToCount = Math.floor(Math.random() * (10 - 1 + 1) + 1)
        // console.log("TARGET NUMBER", numberToCount);

        if (this.props.history.location.state.mode && this.props.history.location.state.mode === 'classic') {
            /* console.log("------------------------");
                    console.log("GENERATING NEW STAGE");
                    console.log("------------------------"); */
            numberOfChoices = Math.floor(Math.random() * (10 - numberToCount + 1) + numberToCount);
            // console.log("CHOICES NUMBER", numberOfChoices);

            if (numberOfChoices === 4 || ((this.props.history.location && this.props.history.location.state && (this.props.history.location.state.mode === 'custom')) && this.props.history.location.state.choices === 4)) {
                document.getElementById('matchingCardsMovableElementsDiv')!.style.gridTemplateRows = 'repeat(2,auto)';
            }
            else {
                const cardMovableElementsDiv = document.getElementById('matchingCardsMovableElementsDiv');
                if (cardMovableElementsDiv) {
                    cardMovableElementsDiv.style.gridTemplateRows = 'repeat(3,auto)';
                }
            }

            //let filteredCards = christmasCardsObj.filter((card) => {
            let filteredCards = cardsObj.filter((card) => {
                return card.category.includes('fruit') || card.category.includes('vegetable')
            })
            // console.log("filtered cards", filteredCards);

            //let randomMax = Math.trunc(filteredCards.length / 6);
            // let randomMax = 10;
            let randomMax = filteredCards.length;
            // if (this.state.payedVersion) {
            //     randomMax = filteredCards.length;
            // }

            const arrayOfCards = filteredCards
            // arrayOfCards.splice(0, arrayOfCards.length - randomMax);

            // console.log("array of cards", arrayOfCards);

            const firstCardIndex = Math.floor(Math.random() * randomMax);
            const firstCard = arrayOfCards[firstCardIndex];
            // console.log("FIRST CARD", firstCard);
            arrayOfCards.splice(firstCardIndex, 1);

            let draggableImages: CardChoice[] = [];


            // add other x random cards
            for (let i = 0; i < numberOfChoices; i++) {
                draggableImages.push({
                    card: firstCard,
                    isCorrect: true,
                });
            }
            // console.log("DRAGGABLE IMAGES", draggableImages);
            this.setState({
                draggableImages: draggableImages,
                answerImage: firstCard,
                correctAnswerProvided: false,
                numberToCount: numberToCount
            });
            let numberToPlay = switchNumberTts(numberToCount)
            this.playStageInstructions([trascina, numberToPlay, numberToCount == 1 ? firstCard.tts.it_it : firstCard.tts.it_it_multiple, cesto])
        }
        else if (this.props.history.location.state.mode && this.props.history.location.state.choices && this.props.history.location.state.mode === 'custom') {
            let numberOfBaskets = this.props.history.location.state.choices;

            if (numberOfBaskets === 4) {
                document.getElementById('matchingCardsMovableElementsDiv')!.style.gridTemplateRows = 'repeat(2,auto)';
            }
            else {
                const cardMovableElementsDiv = document.getElementById('matchingCardsMovableElementsDiv');
                if (cardMovableElementsDiv) {
                    cardMovableElementsDiv.style.gridTemplateRows = 'repeat(3,auto)';
                }
            }
            let filteredCards = cardsObj.filter((card) => {
                return card.category.includes('fruit') || card.category.includes('vegetable')
            })
            // console.log("filtered cards", filteredCards);

            //let randomMax = Math.trunc(filteredCards.length / 6);
            // let randomMax = 10;
            let randomMax = filteredCards.length;
            // if (this.state.payedVersion) {
            //     randomMax = filteredCards.length;
            // }
            const arrayOfCards = filteredCards
            // arrayOfCards.splice(0, arrayOfCards.length - randomMax);
            // console.log("array of cards", arrayOfCards);
            const cardToCountIndex = Math.floor(Math.random() * randomMax);
            const cardToCount = arrayOfCards[cardToCountIndex];

            // console.log("FIRST CARD", firstCard);
            arrayOfCards.splice(cardToCountIndex, 1);
            randomMax = arrayOfCards.length

            let draggableImages: CardChoice[] = [];
            draggableImages.push({
                card: cardToCount,
                isCorrect: true
            })
            // add other x random cards
            for (let i = 0; i < (numberOfBaskets - 1); i++) {
                const cardIndex = Math.floor(Math.random() * randomMax);
                const selectedCard = arrayOfCards[cardIndex];
                await arrayOfCards.splice(cardIndex, 1);
                randomMax -= 1;

                draggableImages.push({
                    card: selectedCard,
                    isCorrect: false,
                });
            }

            for (let i = draggableImages.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1))
                const temp = draggableImages[i]
                draggableImages[i] = draggableImages[j]
                draggableImages[j] = temp
            }
            this.setState({
                draggableImages: draggableImages,
                answerImage: cardToCount,
                correctAnswerProvided: false,
                numberToCount: numberToCount
            });
            let numberToPlay = switchNumberTts(numberToCount)
            this.playStageInstructions([trascina, numberToPlay, numberToCount == 1 ? cardToCount.tts.it_it : cardToCount.tts.it_it_multiple, cesto]);
            if (!isMobile) {
                switch (numberOfBaskets) {
                    case 2:
                        for (let i = 0; i < numberOfBaskets; i++) {
                            (document.getElementsByClassName('cardDraggableImage')[i] as HTMLElement).style.width = '30vw';
                        }
                        break;
                    case 3:
                        for (let i = 0; i < numberOfBaskets; i++) {
                            (document.getElementsByClassName('cardDraggableImage')[i] as HTMLElement).style.width = '30vw';
                        }
                        break;
                    case 4:
                        for (let i = 0; i < numberOfBaskets; i++) {
                            (document.getElementsByClassName('cardDraggableImage')[i] as HTMLElement).style.width = '25vw';
                        }
                        break;
                    case 5:
                        for (let i = 0; i < numberOfBaskets; i++) {
                            (document.getElementsByClassName('cardDraggableImage')[i] as HTMLElement).style.width = '25vw';
                        }
                        break;
                    case 6:
                        for (let i = 0; i < numberOfBaskets; i++) {
                            (document.getElementsByClassName('cardDraggableImage')[i] as HTMLElement).style.width = '20vw';
                        }
                        break;
                    default:
                        break;
                }
            }

        }

    }

    pickup(e: any, card: CardObj) {
        if (this.props.history.location.state && this.props.history.location.state.mode === 'custom') {
            this.moving = document.createElement('img')
            this.moving.id = e.target.parentElement.getAttribute('id');
            this.moving.src = card.full
            e.target.parentElement.appendChild(this.moving)
            this.state.movedElements.push(this.moving)
            this.moving.style.height = '100px';
            this.moving.style.width = '100px';
            if (isMobile) {
                this.moving.style.height = '15vw';
                this.moving.style.width = '15vw';
            }
        }
        else {
            this.moving = e.target;
            this.moving.style.height = this.moving.clientHeight + 'px';
            this.moving.style.width = this.moving.clientWidth + 'px';
        }
        this.moving.style.left = e.clientX - this.moving.clientWidth / 2 + 'px';
        this.moving.style.top = e.clientY - this.moving.clientHeight / 2 + 'px';
        this.moving.style.position = 'fixed';
        this.moving.style.zIndex = '-10';
    }

    move(e: any) {
        if (this.moving) {
            if (e.clientX) {
                // mousemove
                this.moving.style.left = e.clientX - this.moving.clientWidth / 2 + 'px';
                this.moving.style.top = e.clientY - this.moving.clientHeight / 2 + 'px';

            } else {
                // touchmove - assuming a single touchpoint
                this.moving.style.left = e.changedTouches[0].clientX - this.moving.clientWidth + 'px';
                this.moving.style.top = e.changedTouches[0].clientY - this.moving.clientHeight / 2 + 'px';
            }
        }
    }

    drop(e: any) {
        if (this.moving) {
            var x, y;
            if (e.clientX) {
                x = e.clientX;
                y = e.clientY;
            }
            else {
                x = e.changedTouches[0].clientX;
                y = e.changedTouches[0].clientY;
            }
            let basket = document.getElementById('matchingCardsAnswerImage')
            if (this.props.history.location.state && this.props.history.location.state.mode === 'custom' && this.moving.getAttribute('id') === 'false') {
                this.moving.remove()
                return
            }

            if (basket) {
                let basketPosition = basket.getBoundingClientRect();
                if (x < basketPosition.right && x > basketPosition.x) {
                    if (y > basketPosition.top && y < basketPosition.bottom && this.moving.getAttribute('id')) {
                        // this.moving.parentElement.parentElement.hidden = true
                        this.moving.style.position = "absolute"
                        this.moving.style.left = e.clientX - this.moving.clientWidth / 2 + 'px';
                        this.moving.style.top = e.clientY - this.moving.clientHeight / 2 + 'px';
                        this.moving.style.zIndex = 10;
                        if (this.props.history.location.state && this.props.history.location.state.mode === 'classic')
                            this.moving.parentElement.parentElement.style.pointerEvents = 'none';
                        else
                            this.moving.style.pointerEvents = 'none'
                        this.moving = null;

                        const targetImg = document.getElementById("matchingCardsAnswerImage") as HTMLImageElement;
                        if (targetImg) {
                            targetImg.style.marginRight = "0";
                        }
                        if (this.state.currentCount === this.state.numberToCount - 1) {
                            //var myCanvas = document.createElement('canvas');
                            // document.body.appendChild(myCanvas);
                            //const confetti = require('canvas-confetti');
                            // var myConfetti = confetti.create(myCanvas, {
                            //     resize: true,
                            //     useWorker: true
                            // });

                            for (let i = 0; i < document.getElementsByClassName('countingGameMovableElement').length; i++) {
                                const element = document.getElementsByClassName('countingGameMovableElement')[i] as HTMLElement;
                                console.log(element);
                                element.style.pointerEvents = 'none';

                            }
                            confetti({
                                particleCount: 300,
                                spread: 100,
                                origin: { y: 0.6 }
                            });

                            setTimeout(() => {
                                this.props.addPoint();
                                this.state.movedElements.forEach(el => {
                                    el.remove()
                                })
                                //this.setState({ draggableImages: [], answerImage: null, showTargetName: false, currentCount: 0, numberToCount: 0 })
                                this.generateStage();
                                //this.setState({ showTargetName: false, answerImage: null, currentCount: 0, numberToCount: 0, });
                            }, 1500);

                        }
                        this.setState({ currentCount: this.state.currentCount + 1 });

                        // pronounce image name
                        if (this.state.answerImage) {
                            let audioToPlay = switchNumberTts(this.state.currentCount + 1)
                            this.playAudio(audioToPlay);
                        }
                    }
                    else {
                        if (this.props.history.location && this.props.history.location.state.mode === 'custom')
                            this.moving.remove()
                        this.moving.style.left = "unset";
                        this.moving.style.top = "unset";
                        /* this.moving.style.height = "";
                        this.moving.style.width = ""; */
                        this.moving.style.position = "unset";
                        this.moving.style.zIndex = "unset";
                        this.moving = null;
                    }
                }
                else {
                    if (this.props.history.location && this.props.history.location.state.mode === 'custom') {
                        this.moving.remove()
                        this.moving = null
                        return
                    }
                    this.moving.style.left = "unset";
                    this.moving.style.top = "unset";
                    /* this.moving.style.height = "";
                    this.moving.style.width = ""; */
                    this.moving.style.position = "unset";
                    this.moving.style.zIndex = "unset";
                    this.moving = null;
                }
            }
        }
    }

    preloadImage(url: string) {
        var img = new Image();
        img.src = url;
    }

    playAudio(audioUri: string) {
        const audioElement = (document.getElementById("matchingCardsAudioElement") as HTMLAudioElement);
        audioElement.src = audioUri;
        audioElement.playbackRate = 1.5; // TO FIX THIS
        audioElement.play();
    }

    playStageInstructions(audioUris: string[]) {
        const audioElement = (document.getElementById("matchingCardsAudioElement") as HTMLAudioElement);
        audioElement.src = audioUris[0];
        audioElement.playbackRate = 1.5; // TO FIX THIS
        audioElement.play();
        audioElement.onended = () => {
            audioElement.src = audioUris[1];
            audioElement.onended = () => {
                audioElement.src = audioUris[2];
                audioElement.onended = () => {
                    audioElement.src = audioUris[3];
                    audioElement.onended = () => { }
                }
            }
        }
    }

    handleClick(e: any, card: CardObj) {
        if (e.target.parentElement?.getAttribute('id') === 'true') {
            console.log(e.target);
            let clickedElement;
            let basket = document.getElementById('matchingCardsAnswerImage');
            if (this.props.history.location.state.mode === 'custom') {
                if (basket) {
                    clickedElement = document.createElement('img')
                    clickedElement.src = card.full
                    document.body.appendChild(clickedElement)
                    this.state.movedElements.push(clickedElement)
                    positionElement(clickedElement, basket)
                }
            }
            else {
                if (basket) {
                    clickedElement = e.target as HTMLElement;
                    positionElement(clickedElement, basket)
                    clickedElement.parentElement!.parentElement!.style.pointerEvents = 'none';
                }
            }

            this.setState({ currentCount: this.state.currentCount + 1 })
            if (this.state.currentCount === this.state.numberToCount - 1) {
                //var myCanvas = document.createElement('canvas');
                // document.body.appendChild(myCanvas);
                //const confetti = require('canvas-confetti');
                // var myConfetti = confetti.create(myCanvas, {
                //     resize: true,
                //     useWorker: true
                // });
                confetti({
                    particleCount: 300,
                    spread: 100,
                    origin: { y: 0.6 }
                });

                setTimeout(() => {
                    this.props.addPoint();
                    this.state.movedElements.forEach((element) => {
                        element.remove()
                    })
                    //this.setState({ draggableImages: [], answerImage: null, showTargetName: false, currentCount: 0, numberToCount: 0 })
                    this.generateStage();
                    //this.setState({ showTargetName: false, answerImage: null, currentCount: 0, numberToCount: 0, });
                }, 1500);
            }
            if (this.state.answerImage) {
                let audioToPlay = switchNumberTts(this.state.currentCount + 1)
                this.playAudio(audioToPlay);
            }
        }
    }

    render() {
        return (
            <IonPage
                className="matchingCardsMainContainer"
                onMouseUp={(e) => {
                    this.drop(e)
                }}
                onTouchEnd={(e) => {
                    this.drop(e);
                }}
            >
                <audio
                    id="matchingCardsAudioElement"
                    autoPlay
                />
                <div className="matchingCardsHeaderDiv">
                    <div
                        className="matchingCardsBackDiv"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >
                        <IonIcon
                            icon={chevronBack}
                        />
                    </div>

                    {
                        this.props.unmutePressed &&
                        <p className="matchingCardsCallToActionParagraph">
                            {this.props.interactionMethod === 'click' ? 'Tocca' : 'Trascina'} <b>{this.props.numbersMode === 'number' ? this.state.numberToCount : numberToLetters(this.state.numberToCount)}</b> {this.state.answerImage?.name.it_it ? this.state.numberToCount > 1 ? this.state.answerImage?.name.it_it.plur : this.state.answerImage?.name.it_it.sing : "figure"} nel cesto {/* TO LOCALIZE THIS */}, Totali: {this.props.numbersMode === 'number' ? this.state.currentCount : numberToLetters(this.state.currentCount)}
                        </p>
                    }

                    {
                        this.props.unmutePressed &&
                        <div className="matchingCardsPointsDiv">
                            <b>{this.props.points}</b>
                        </div>
                    }
                </div>

                <div
                    className="matchingCardsGameContainer"
                    onMouseMove={(e) => {
                        this.move(e)
                    }}
                    onTouchMove={(e) => {
                        this.move(e)
                    }}
                >
                    <div id='matchingCardsMovableElementsDiv' className="matchingCardsMovableElementsDiv">
                        {
                            this.state.draggableImages.map((image: CardChoice, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        id="matchingCardsMovableElement"
                                        className="countingGameMovableElement"
                                        style={{ backgroundColor: '' }}
                                        onMouseUp={(e) => {
                                            this.drop(e);
                                        }}
                                        onTouchEnd={(e) => {
                                            this.drop(e)
                                        }}
                                    >
                                        <Card
                                            key={key}
                                            gameMode={'custom'}
                                            showColors={this.props.showColors}
                                            onMouseDown={(e) => {
                                                if (this.props.interactionMethod === 'drag') {
                                                    this.pickup(e, image.card)
                                                }
                                                else {
                                                    this.handleClick(e, image.card)
                                                }
                                            }
                                            }
                                            onTouchStart={(e) => this.pickup(e, image.card)}
                                            id={image.card.full}
                                            src={this.props.history.location.state?.mode === 'custom' ? image.card.basket! : image.card.full}
                                            isCorrect={image.isCorrect}
                                        />
                                    </div>
                                )
                            })
                        }

                    </div>

                    {
                        this.state.answerImage &&
                        <div className='matchingCardsTargetCol'>


                            <img
                                id="matchingCardsAnswerImage"
                                className="countingBasket"
                                src={basket}
                                style={{
                                    //filter: this.props.showColors ? '' : 'grayscale(1)',
                                }}
                            />
                        </div>
                    }
                </div>
                <div>
                </div>

                {
                    !this.props.unmutePressed &&
                    <div
                        className="matchingCardsUnmuteButtonDiv"
                        onClick={() => {
                            const audioElement = (document.getElementById("matchingCardsAudioElement") as HTMLAudioElement);
                            audioElement.muted = false;
                            this.props.unmute(true);
                        }}
                    >
                        <p className="matchingCardsUnmuteButtonParagraph">
                            Attiva l'audio
                        </p>
                        <IonIcon
                            className="matchingCardsUnmuteButtonIcon"
                            icon={notifications}
                        />
                    </div>
                }
            </IonPage>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        points: state.matchGame.points,
        showColors: state.settings.showColors,
        categoryFilter: state.settings.categoryFilter,
        unmutePressed: state.settings.unmutePressed,
        interactionMethod: state.settings.interactionMethod,
        numbersMode: state.settings.numbersMode,

    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getScore: () => {
            dispatch(matchActions.getScore());
        },

        addPoint: () => {
            dispatch(matchActions.addPoint());
        },

        colorImages: (color: 'colors' | 'bw') => {
            dispatch(settingsActions.colorImages(color));
        },

        unmute: (pressed: boolean) => {
            dispatch(settingsActions.unmute(pressed));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Counting);
import React from 'react';
import {
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
    IonGrid, IonRow, IonCard, IonCardContent, IonCardHeader,
    IonButton, IonButtons, IonBackButton, IonToggle, IonItem,
    IonLabel, IonSelect, IonSelectOption, IonIcon, IonRadioGroup, IonRadio,
} from '@ionic/react';
import { connect } from 'react-redux';
import { matchActions } from '../../MatchingCards/store/actions';
import { settingsActions } from '../store/actions';
import { auth, database } from '../../../common/firebase';
import { ref, get, child } from "firebase/database";
import { lockClosed } from 'ionicons/icons';
import { featureManagerActions } from '../../../common/featureManager/actions';
import './Settings.css';
import { fromDbPurchasesToArrayOfPurchases, isMatchingCardsActive } from '../../Purchases/utils';

type Props = {
    history: any,
    showColors: 'colors' | 'bw',
    points: number,
    categoryFilter: string[],
    interactionMethod: 'click' | 'drag',
    numbersMode: 'number' | 'letter',

    payPopover: (show: boolean) => void,
    resetScore: () => void,
    colorImages: (color: 'colors' | 'bw') => void,
    changeCategoryFilter: (filter: string[]) => void,
    changeInteractionMethod: (interactionMethod: 'click' | 'drag') => void,
    changeNumbersMode: (numbersMode: 'number' | 'letter') => void,
}

type State = {
    stateFilter: string[],
    payedVersion: boolean,
}

class Settings extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            stateFilter: [],
            payedVersion: false,
        }
    }

    componentDidMount() {
        if (this.props.categoryFilter) {
            this.setState({ stateFilter: this.props.categoryFilter })
        }

        auth.onAuthStateChanged(userData => {
            if (userData) {
                const dbUserDataRef = ref(database);
                get(child(dbUserDataRef, `u/${userData.uid}`))
                    .then(data => {
                        if (data.exists()) {

                            if (data.val()["f"] && data.val()["f"]["y"]) {
                                this.setState({
                                    payedVersion: true,
                                })
                            }
                            else if (data.val()["p"]) {
                                const purchases = fromDbPurchasesToArrayOfPurchases(data.val()["p"]);
                                this.setState({ payedVersion: isMatchingCardsActive(purchases) });
                            }
                        }

                    })
                    .catch(err => {
                        console.log("[Settings] error getting financial data from user:", err);
                    })
            }
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        <IonTitle>Configurazioni</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid className='settingsGrid'>
                        <IonRow className='settingsRow'>
                            <IonCard className='settingsCard' mode="md">
                                <IonCardHeader>
                                    <IonTitle>
                                        Punteggio {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonItem lines='none' style={{ textAlign: 'center' }}>
                                        <IonLabel>{this.props.points} {this.props.points == 1 ? ' punto' : ' punti'}</IonLabel>
                                        <IonButton
                                            color='medium'
                                            onClick={() => {
                                                this.props.resetScore();
                                            }}
                                            fill='outline'
                                            class='resetButton'
                                        >
                                            Reset Punti
                                        </IonButton>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonRow>
                        <IonRow className='settingsRow' onClick={() => {
                            if (!this.state.payedVersion) {
                                this.props.history.push(`/purchase`);
                            }
                        }}>
                            <IonCard disabled={!this.state.payedVersion} className='settingsCard' mode="md">
                                <div className='settingsLockDiv' hidden={this.state.payedVersion} >
                                    <IonIcon className='settingsLock' icon={lockClosed} />
                                </div>
                                <IonCardHeader>
                                    <IonTitle>
                                        Mostra le immagini {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRadioGroup
                                        value={this.props.showColors}
                                        onIonChange={e => this.props.colorImages(this.props.showColors === 'colors' ? 'bw' : 'colors')}
                                    >
                                        <IonItem>
                                            <IonLabel>A colori</IonLabel>
                                            <IonRadio slot="start" value={'colors'} />
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>In bianco e nero</IonLabel>
                                            <IonRadio slot="start" value={'bw'} />
                                        </IonItem>
                                    </IonRadioGroup>

                                </IonCardContent>
                            </IonCard>

                        </IonRow>
                        <IonRow className='settingsRow' onClick={() => {
                            if (!this.state.payedVersion) {
                                this.props.history.push(`/purchase`);
                            }
                        }}>
                            <IonCard disabled={!this.state.payedVersion} className='settingsCard' mode="md">
                                <div className='settingsLockDiv' hidden={this.state.payedVersion} >
                                    <IonIcon className='settingsLock' icon={lockClosed} />
                                </div>
                                <IonCardHeader>
                                    <IonTitle>
                                        Testo in stampatello: {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRadioGroup
                                        value={document.body.style.textTransform}
                                        onIonChange={e => document.body.style.textTransform = e.detail.value}
                                    >
                                        <IonItem>
                                            <IonLabel>Maiuscolo</IonLabel>
                                            <IonRadio slot="start" value={'uppercase'} />
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel>Minuscolo</IonLabel>
                                            <IonRadio slot="start" value={''} />
                                        </IonItem>
                                    </IonRadioGroup>

                                </IonCardContent>
                            </IonCard>
                        </IonRow>
                        <IonRow
                            className='settingsRow'
                            onClick={() => {
                                if (!this.state.payedVersion) {
                                    this.props.history.push(`/purchase`);
                                }
                            }}
                        >
                            <IonCard disabled={!this.state.payedVersion} className='settingsCard' mode="md">
                                <div className='settingsLockDiv' hidden={this.state.payedVersion} >
                                    <IonIcon className='settingsLock' icon={lockClosed} />
                                </div>
                                <IonCardHeader>
                                    <IonTitle>
                                        Filtra le immagini: {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <div className='settingsDocumentViewingItemDiv'>
                                        <p className='settingsDocumentViewingParagraph'>
                                            Filtro
                                        </p>

                                        <IonItem lines='none'>
                                            <IonSelect
                                                disabled={!this.state.payedVersion}
                                                className='settingsFilterSelect'
                                                value={this.state.stateFilter}
                                                multiple={true}
                                                interface='popover'
                                                onIonChange={e => {
                                                    this.setState({ stateFilter: e.detail.value }, () => {
                                                        if (this.state.stateFilter.length === 0) {
                                                            this.props.changeCategoryFilter(['food', 'animals', 'objects'])
                                                            this.setState({ stateFilter: ['food', 'animals', 'objects'] })
                                                        }
                                                        else
                                                            this.props.changeCategoryFilter(this.state.stateFilter)
                                                    })
                                                }}
                                            >
                                                <IonSelectOption value="food">Cibo</IonSelectOption>
                                                <IonSelectOption value="animals">Animali</IonSelectOption>
                                                <IonSelectOption value="objects">Oggetti</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonRow>
                        <IonRow
                            className='settingsRow'
                            onClick={() => {
                                if (!this.state.payedVersion) {
                                    this.props.history.push(`/purchase`);
                                }
                            }}
                        >
                            <IonCard disabled={!this.state.payedVersion} className='settingsCard' mode="md">
                                <div className='settingsLockDiv' hidden={this.state.payedVersion} >
                                    <IonIcon className='settingsLock' icon={lockClosed} />
                                </div>
                                <IonCardHeader>
                                    <IonTitle>
                                        Modalità di interazione: {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <div className='settingsDocumentViewingItemDiv'>
                                        <p className='settingsDocumentViewingParagraph'>
                                            Scegli come preferisci interagire con le attività di GEDU
                                        </p>
                                        <IonRadioGroup
                                            value={this.props.interactionMethod}
                                            onIonChange={e => this.props.changeInteractionMethod(this.props.interactionMethod === 'click' ? 'drag' : 'click')}
                                        >
                                            <IonItem>
                                                <IonLabel>Trascinamento</IonLabel>
                                                <IonRadio slot="start" value="drag" />
                                            </IonItem>

                                            <IonItem>
                                                <IonLabel>Clic</IonLabel>
                                                <IonRadio slot="start" value="click" />
                                            </IonItem>
                                        </IonRadioGroup>

                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonRow>
                        <IonRow
                            className='settingsRow'
                            onClick={() => {
                                if (!this.state.payedVersion) {
                                    this.props.history.push(`/purchase`);
                                }
                            }}
                        >
                            <IonCard disabled={!this.state.payedVersion} className='settingsCard' mode="md">
                                <div className='settingsLockDiv' hidden={this.state.payedVersion} >
                                    <IonIcon className='settingsLock' icon={lockClosed} />
                                </div>
                                <IonCardHeader>
                                    <IonTitle>
                                        Numeri: {/* TO LOCALIZE THIS */}
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <div className='settingsDocumentViewingItemDiv'>
                                        <p className='settingsDocumentViewingParagraph'>
                                            Scegli come visualizzare i numeri
                                        </p>
                                        <IonRadioGroup
                                            value={this.props.numbersMode}
                                            onIonChange={e => this.props.changeNumbersMode(this.props.numbersMode === 'number' ? 'letter' : 'number')}
                                        >
                                            <IonItem>
                                                <IonLabel>Simboli (1,2,3 ...)</IonLabel>
                                                <IonRadio slot="start" value="number" />
                                            </IonItem>

                                            <IonItem>
                                                <IonLabel>Lettere (uno, due, tre ...)</IonLabel>
                                                <IonRadio slot="start" value="letter" />
                                            </IonItem>
                                        </IonRadioGroup>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonRow>
                        <IonRow className='settingsRow' style={{ justifyContent: 'center' }}>
                            <IonButton
                                className="settingsLicensesButton"
                                fill='clear'
                                onClick={() => {
                                    window.open("https://estro.notion.site/Licenses-4f6a2030003746d0be6ace272fd29912", "_blank");
                                }}
                            >
                                Licenze
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        points: state.matchGame.points,
        showColors: state.settings.showColors,
        categoryFilter: state.settings.categoryFilter,
        interactionMethod: state.settings.interactionMethod,
        numbersMode: state.settings.numbersMode,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        resetScore: () => {
            dispatch(matchActions.resetScore());
        },
        colorImages: (color: 'colors' | 'bw') => {
            dispatch(settingsActions.colorImages(color));
        },
        changeCategoryFilter: (filter: string[]) => {
            dispatch(settingsActions.changeCategoryFilter(filter));
        },
        payPopover: (show: boolean) => {
            dispatch(featureManagerActions.payPopover(show));
        },
        changeInteractionMethod: (interactionMethod: 'click' | 'drag') => {
            dispatch(settingsActions.changeInteractionMethod(interactionMethod));
        },
        changeNumbersMode: (numbersMode: 'number' | 'letter') => {
            dispatch(settingsActions.changeNumbersMode(numbersMode));
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
export function numberToLetters(number: number) {
    switch (number) {
        case 0:
            return 'zero'
            break;
        case 1:
            return 'uno'
            break;
        case 2:
            return 'due'
            break;
        case 3:
            return 'tre'
            break;
        case 4:
            return 'quattro'
            break;
        case 5:
            return 'cinque'
            break;
        case 6:
            return 'sei'
            break;
        case 7:
            return 'sette'
            break;
        case 8:
            return 'otto'
            break;
        case 9:
            return 'nove'
            break;
        case 10:
            return 'dieci'
            break;
        default:
            break;
    }
}
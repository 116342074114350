import { Session } from "./reducers";

export function fromDbGamesLogsToSessions(data: any): Session[] {
    const sessionsToReturn: Session[] = [];
    if(data) {
        const sessionsUuids = Object.keys(data);
        for (let i = 0; i < sessionsUuids.length; i++) {
            const session = fromDbGameLogToSession(sessionsUuids[i], data[sessionsUuids[i]]);
            if(session) {
                sessionsToReturn.push(session);
            }
            
        }
    }
    return sessionsToReturn;
}

export function fromDbGameLogToSession(uuid: string, data: any): Session | null {
    if(data) {
        return {
            uuid: uuid,
            game: fromGameUuidToGame(data["g"]),
            startedAt: data["s"],
        };
    }
    return null;
}

export function fromGameUuidToGame(gameUuid: number): "matching-cards" | "counting-game" {
    switch (gameUuid) {
        case 1:
            return "matching-cards";
            
        default:
            return "counting-game"
    }
}

export function fromGameToGameUuid(game: "matching-cards" | "counting-game"): number {
    switch (game) {
        case "matching-cards":
            return 1;

        case "counting-game":
            return 2;

        default:
            return -1;
    }
}

export function fromGameToGameName(game: "matching-cards" | "counting-game"): string {
    switch (game) {
        case "matching-cards":
            return "Completa la figura"; /* TO BE LOCALIZED */

        case "counting-game":
            return "Conta la frutta"; /* TO BE LOCALIZED */

        default:
            return "";
    }
}
import {
    IonContent, IonPage, IonToolbar, IonHeader, IonGrid,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from "../../../assets/animations/waitingDots.json";
import loadingShapes from "../../../assets/animations/loadingShapes.json";
import { purchasesActions } from '../store/actions';
import "./PaymentProcessing.css";

type Props = {
    history: any,

    isPaymentLoading: boolean,
    isPaymentProcessing: boolean,
    isPaymentCanceled: boolean,
    isPaymentSuccessful: boolean,

    clear: () => void,
};

type State = {
};

class PaymentProcessing extends React.Component<Props, State> {

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        {/* <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons> */}
                        
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="paymentProcessingMainGrid">

                        {
                            this.props.isPaymentLoading &&
                            <div className="paymentProcessingLoadingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingAnimation}
                                    />
                                </div>

                                <p className="paymentProcessingLoadingParagraph">
                                    Pagamento in corso {/* TO BE LOCALIZED */}
                                </p>

                            </div>
                        }

                        {
                            this.props.isPaymentProcessing &&
                            <div className="paymentProcessingProcessingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingShapes}
                                    />
                                </div>
                                <p className="paymentProcessingProcessingParagraph">
                                    Stiamo processando il pagamento
                                </p>
                            </div>
                        }

                        {
                            this.props.isPaymentCanceled &&
                            <div className="paymentProcessingCanceledDiv">
                                <p className="paymentProcessingCanceledEmoji">
                                    😕
                                </p>

                                <p className="paymentProcessingCanceledParagraph">
                                    Hai annullato il pagamento
                                </p>
                                
                                <p
                                    className="paymentProcessingCanceledBackToPaymentButton"
                                    onClick={() => {
                                        this.props.clear();
                                        this.props.history.replace(`/purchase`);
                                    }}
                                >
                                    Se vuoi tornare indietro, clicca qui
                                </p>

                                <p
                                    className="paymentProcessingCanceledSurveyButton"
                                    onClick={() => {
                                        window.open("https://tally.so/r/mOD87Y", "_system");
                                    }}
                                >
                                    Avresti voglia di dirci come mai hai cambiato idea?
                                </p>

                                
                            </div>
                        }


                    </IonGrid>                    

                    {
                        this.props.isPaymentSuccessful &&
                        <Redirect to="/payment-completed" />
                    }
                    
                </IonContent>

            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentLoading: state.purchases.isLoadingPayment,
        isPaymentProcessing: state.purchases.isProcessingPayment,
        isPaymentCanceled: state.purchases.paymentCanceled,
        isPaymentSuccessful: state.purchases.paymentSuccess,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clear: () => {
            dispatch(purchasesActions.clear());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessing);
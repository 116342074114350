import { purchasesConstants } from "./constants";

export interface PurchasesStore  {
	isLoadingPayment: boolean,
	isProcessingPayment: boolean,
	paymentSuccess: boolean,
	paymentCanceled: boolean,
	paymentError: boolean,
}

const initialState: PurchasesStore = {
	isLoadingPayment: false,
	isProcessingPayment: false,
	paymentSuccess: false,
	paymentCanceled: false,
	paymentError: false,
}

const Purchases = (state = initialState, action: any) : PurchasesStore => {

	switch (action.type) {

		case purchasesConstants.PURCHASE_LOADING:
			return {
				...state,
				isLoadingPayment: action.loading,
				isProcessingPayment: false,
				paymentCanceled: false,
			}

		case purchasesConstants.PURCHASE_PROCESSING:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: action.processing,
			}

		case purchasesConstants.PURCHASE_SUCCESS:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentCanceled: false,
				paymentError: false,
				paymentSuccess: true,
			}

		case purchasesConstants.PURCHASE_CANCELED:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentCanceled: true,
			}

		case purchasesConstants.PURCHASE_ERROR:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentError: true,
			}
		
		case purchasesConstants.CLEAR:
			return initialState;

		default:
			return state
	}
}

export default Purchases;
